import * as React from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  NativeSelect,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as yup from "yup";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";


const validationFilterSchema = yup.object().shape({
    // name : yup.string().required('*Supplier is Required'),
    // status: yup.string().required('*Deviation range is Required'),
    // selectedDate:yup.string().required('*From date is Required'),
    // selectedDate1:yup.string().required('*To date is Required'),
   })

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  dialogCustomizedWidth: {
    "max-width": "40%",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

let filterInitial = {
  status: "",
  name: "",
  selectedDate: "",
  selectedDate1: "",
  supplierName:"",
  offset: 0 ,
  limit:""
};

function MetricInvoiceRoundedFilter(props) {
  const styleclasses = useStyles();
  const {
    open,
    handleClose,
    nameData,

    handleFilterSubmit,
    onResetFilter,
    dataParams,
    filterHandler,
    supplierData
  } = props;
  console.log(dataParams, "dataParams");
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    resetForm,
    setFieldError,
    setValues,
  } = useFormik({
    validationSchema:validationFilterSchema,
    initialValues: {
      ...filterInitial,
      ...dataParams,
    },
    // validationSchema,
    onSubmit: (values) => {
      onSubmitFilter(values);

      // handleFilterSubmit(values);
    },
  });
  const resetField = () => {
    setValues({ ...filterInitial });
    resetForm({});
    // handleFilterSubmit(filterInitial);
    // onResetFilter();
  };

  const {
    selectedDate = " ",
    selectedDate1 = " ",
  } = values;

  const onSubmitFilter = (values) => {
    
    if (values.selectedDate != "" && values.selectedDate != "Invalid date" && values.selectedDate != null) {
      if (
        values.selectedDate1 === "" ||
        values.selectedDate1 === null ||
        values.selectedDate1 == "Invalid date"
      ) {
        setFieldError("selectedDate1", "End date is required");
        return null;
      }
    }
    if (values.selectedDate1 != "" && values.selectedDate1 != "Invalid date" && values.selectedDate1 != null) {
      if (
        values.fromDate === "" ||
        values.fromDate === null ||
        values.fromDate === "Invalid date"
      ) {
        setFieldError("fromDate", "Start date is required");
        return null;
      }
    }

    let refiendValues = {
      ...values,
      selectedDate: ["", "Invalid date", null].includes(selectedDate)
        ? ""
        : moment(selectedDate).utc().format("YYYY-MM-DD"),
      selectedDate1: ["", "Invalid date", null].includes(selectedDate1)
        ? ""
        : moment(selectedDate1).format("YYYY-MM-DD"),
      // status: status === "all" ? "" : status,
    };

    handleFilterSubmit(values);
    filterHandler(refiendValues);
  };


  const handleFromDate = (e) => {
    setValues((val) => ({
      ...val,
      selectedDate:e
      // selectedDate: moment(e).format("YYYY-MM-DD"),
    }));
  };

  const handleToDate = (e) => {
    setValues((val) => ({
      ...val,
      selectedDate1:e
      // selectedDate1: moment(e).format("YYYY-MM-DD"),
    }));
  };

  return (
    <BootstrapDialog
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{ paperFullWidth: styleclasses.dialogCustomizedWidth }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A0A3BD",
            outline: "1px solid #A0A3BD",
            borderRadius: "50px",
          },
        },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        className={styleclasses.BootstrapDialog}
        sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
      >
        Filters
      </BootstrapDialogTitle>
      <Box component={"form"} onSubmit={handleSubmit}>
        <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Supplier
            </Typography>
            <FormControl sx={{ width: "100%" }} variant="standard">
              <NativeSelect
                required
                id="demo-customized-select-native"
                value={values.name}
                onChange={handleChange}
                name="name"
                input={<BootstrapInput />}
              >
                <option aria-label="None" value="">
                  Show all
                </option>

                {
                  supplierData && supplierData.map((item)=> {
                    return <option value={item.supplierCompanyName}>{item.supplierCompanyName}</option>
})
                  }
                
                {/* <option value="jeyabaskaranm@apptomate.co">
                  Jeya Baskaran
                </option>
                {nameData &&
                  nameData.length !== 0 &&
                  nameData.map((i, index) => {
                    const { firstName, lastName, email } = i;
                    return (
                      <option value={email} key={index}>
                        {firstName} {lastName}
                      </option>
                    );
                  })} */}
              </NativeSelect>
            </FormControl>
         {/* <Typography style={{ color: "red" }}>{errors?.name && touched?.name && errors?.name}</Typography> */}

          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              From
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack sx={{ display: "flex", flexDirection: "row" }} spacing={3}>
                {/* <DatePicker */}
                <DesktopDatePicker
                  required
                  inputFormat="MM/dd/yyyy"
                  name="selectedDate"
                  allowSameDateSelection
                  value={
                    values.selectedDate ? new Date(values.selectedDate) : null
                  }
                  onChange={handleFromDate}
                  maxDate={
                    values.selectedDate1
                      ? new Date(values.selectedDate1)
                      : new Date()
                  }
                  // closeOnSelect={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "MM/DD/YYYY",
                      }}
                      sx={{ width: "100% !important" }}
                      // helperText={
                      //   errors.selectedDate ? errors.selectedDate : ""
                      // }
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
       <Typography style={{ color: "red" }}>{errors?.fromDate && touched?.fromDate && errors?.fromDate}</Typography> 

          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              To
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack sx={{ display: "flex", flexDirection: "row" }} spacing={3}>
                <DesktopDatePicker
                  required
                  inputFormat="MM/dd/yyyy"
                  name="selectedDate1"
                  value={
                    values.selectedDate1 ? new Date(values.selectedDate1) : null
                  }
                  onChange={handleToDate}
                  allowSameDateSelection
                  minDate={new Date(values.selectedDate)}
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "MM/DD/YYYY",
                      }}
                      sx={{ width: "100% !important" }}
                      // helperText={
                      //   errors.selectedDate1 ? errors.selectedDate1 : ""
                      // }
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
     <Typography style={{ color: "red" }}>{errors?.toDate && touched?.toDate && errors?.toDate}</Typography> 

          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button onClick={resetField} className={styleclasses.canceldialog}>
              Reset
            </Button>
            <Button
              type="submit"
              className={styleclasses.submitdialog}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogActions>
      </Box>
    </BootstrapDialog>
  );
}

export default MetricInvoiceRoundedFilter;
