import React, { useState } from "react";
import {
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Mainlogo from "../images/Logo_Color1.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { FORGET_PASSWORD, UPDATE_PASSWORD } from "../comman/url";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DASHBOARD_ROUTE,
  FORGOTPASSWORD_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_ROUTE,
} from "../constants/RoutePaths";
import { authHeader } from "../comman/mainfunction";
import { deCryptFun, enCryptFun, getEmail } from "../comman/function";
import API from "../constants/API";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { CustomMessage } from "../comman/helperfunction";
import { PASSWORD_REGEX } from "../comman/constants";
import { useDispatch } from "react-redux";
import {
  UPDATE_COMPANY_LOGO,
  UPDATE_USER_PROFILE,
} from "../redux/action/ActionType";
const useStyles = makeStyles((theme) => ({
  loginbox: {
    backgroundColor: "#fff",
    padding: "30px 50px",
    borderRadius: "16px",
    textAlign: "left",
    boxShadow: "3px 3px 20px #00000017",
  },
}));
function Updatepassword() {
  const styleclasses = useStyles();
  const [NewPasswordToggle, setNewPasswordToggle] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [OldPasswordToggle, setOldPasswordToggle] = useState(false);
  const [ConfirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
  const [loading, setloading] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state = {} } = location;
  const {
    data = {},
    accesstoken = "",
    invoiceCount = 0,
    email = "",
    url = "",
    exceptionRoleId=""
  } = state || {};
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Required"),
    newPassword: Yup.string()
      .test(
        "passwords-match",
        "Password should not be old password!",
        function (value) {
          return this.parent.oldPassword !== value;
        }
      )
      .required("Required")
      .matches(
        PASSWORD_REGEX,
        "Invalid password. New password must be 8 characters minimum and contain at least one upper/lowercase letter and one special character." ),
      // .min("8", "Invalid Password,Password must be longer than 8 characters"),
    confirmnewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Required"),
  });

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        oldPassword: "",
        newPassword: "",
        confirmnewPassword: "",
      },
      validationSchema,
      onSubmit: (values) => UpdatePassword(values),
    });

  let UpdatePassword = async (values) => {
    setloading(true);

    // values.email = getEmail();

    values.email = data.email;

    const config = {
      method: "PUT",
      url: UPDATE_PASSWORD,
      headers: {
        Authorization: accesstoken,
      },

      //values,
      data: {
        webString: enCryptFun(JSON.stringify(values)),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        CustomMessage(
          "Password updated",
          "success",
          enqueueSnackbar
        ); //call toster

        history(DASHBOARD_ROUTE);
        setloading(false);

        localStorage.setItem("User_ID", data.userId ? enCryptFun((data.userId).toString()) : "");
        localStorage.setItem("AUTH_TOKEN",enCryptFun(accesstoken.toString()));
        localStorage.setItem("Team_ID", data.teamId ? enCryptFun((data.teamId).toString()) : "");
        localStorage.setItem("EMAIL", data.email ? enCryptFun(data.email) : "");
        localStorage.setItem("SENDER_EMAIL", data.invoiceSenderEmail ? enCryptFun(data.invoiceSenderEmail) : "");
        localStorage.setItem("USER_ROLE", data.userRole ? enCryptFun(data.userRole) : "");
        localStorage.setItem("PROFILE_PIC", data.profileLogo ? enCryptFun((data.profileLogo).toString()) : "");
        localStorage.setItem("APPROVAL_AMOUNT",enCryptFun((data.approvalAmountTo).toString()));
        localStorage.setItem("INVOICE_COUNT", enCryptFun(invoiceCount.toString()));
        localStorage.setItem("BRAND_LOGO", data.companyLogo ? enCryptFun((data.companyLogo).toString()) : "" );
        localStorage.setItem("AUTH_USER_DETAILS", enCryptFun(JSON.stringify(data)));
        localStorage.setItem("AUTH_2F",enCryptFun((0).toString()));
        localStorage.setItem("isException", enCryptFun(exceptionRoleId.toString()));

        localStorage.setItem(
          "LOGIN_NAME",
          data.firstName + " " + data.lastName ? enCryptFun((data.firstName + " " + data.lastName).toString()) : ""
        );
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: data.profileLogo,
          userName: data.firstName + " " + data.lastName,
        });
        dispatch({
          type: UPDATE_COMPANY_LOGO,
          payload: data.companyLogo,
        });
      }
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(message);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setloading(false);
    }
  };
  let NewToogle = () => {
    setNewPasswordToggle(!NewPasswordToggle);
  };
  let ConfirmToogle = () => {
    setConfirmPasswordToggle(!ConfirmPasswordToggle);
  };
  let OldToogle = () => {
    setOldPasswordToggle(!OldPasswordToggle);
  };
  const forGotpassword = () => {
    localStorage.clear();

    history(FORGOTPASSWORD_ROUTE);
  };
  const goTosiginup = () => {
    localStorage.clear();

    history(SIGNUP_ROUTE);
  };
  // const handleFieldChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "newPassword") {
  //     setNewPasswordToggle(false);
  //   }
  //   if (name === "oldPassword") {
  //     setOldPasswordToggle(false);
  //   }
  //   if (name === "confirmnewPassword") {
  //     setConfirmPasswordToggle(false);
  //   }

  //   handleChange(e);
  // };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid
          sx={{ height: "100vh" }}
          container
          rowSpacing={1}
          justifyContent="center"
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid sx={{ margin: "auto" }} xs={12} md={4}>
            <Box>
              <img
                src={Mainlogo}
                style={{ marginTop: "30px", marginBottom: "20px" }}
              ></img>
            </Box>
            <Box className={styleclasses.loginbox}>
              <Typography
                sx={{ mt: 1, mb: 3, fontWeight: "600" }}
                align="center"
                variant="h4"
              >
                LOGIN
              </Typography>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Old password
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-password oldPassword"
                  type={OldPasswordToggle ? "text" : "password"}
                  value={values.oldPassword}
                  // onChange={(e) => handleFieldChange(e)}
                  onChange={(e) => handleChange(e)}
                  name="oldPassword"
                  error={errors.oldPassword && touched.oldPassword ? true : false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={OldToogle}
                        edge="end"
                      >
                        {!OldPasswordToggle ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error={true}>
                  {errors.oldPassword && touched.oldPassword
                    ? errors.oldPassword
                    : ""}
                </FormHelperText>
              </FormControl>
              <Typography
                gutterBottom
                sx={{ mt: 2, fontWeight: 600, color: "#4E4B66" }}
              >
                New Password
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type={NewPasswordToggle ? "text" : "password"}
                  value={values.newPassword}
                  onChange={(e) => handleChange(e)}
                  name="newPassword"
                  error={errors.newPassword && touched.newPassword ? true : false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={NewToogle}
                        edge="end"
                      >
                        {!NewPasswordToggle ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText  error={true}>
                  {errors.newPassword && touched.newPassword
                    ? errors.newPassword
                    : ""}
                </FormHelperText>
              </FormControl>
              <Typography
                gutterBottom
                sx={{ mt: 2, fontWeight: 600, color: "#4E4B66" }}
              >
                Confirm Password
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type={ConfirmPasswordToggle ? "text" : "password"}
                  value={values.confirmnewPassword}
                  // onChange={(e) => handleFieldChange(e)}
                  onChange={(e) => handleChange(e)}
                  name="confirmnewPassword"
                  error={errors.confirmnewPassword && touched.confirmnewPassword ? true : false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={ConfirmToogle}
                        edge="end"
                      >
                        {!ConfirmPasswordToggle ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText error={true}>
                  {errors.confirmnewPassword && touched.confirmnewPassword
                    ? errors.confirmnewPassword
                    : ""}
                </FormHelperText>
              </FormControl>
              <Box sx={{ textAlign: "right" }}>
                <Link
                  sx={{
                    mt: 2,
                    mb: 3,
                    fontWeight: "600",
                    textDecoration: "none",
                  }}
                  color="#ec7c37"
                  component="button"
                  variant="body2"
                  onClick={forGotpassword}
                >
                  Forgot Password?
                </Link>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Button onClick={handleSubmit} variant="contained">
                  Update
                </Button>
              </Box>
            </Box>
            <Typography sx={{ mt: 2 }}>
              Don't have an account?
              <Link
                sx={{ ml: 1, fontWeight: "600", textDecoration: "none" }}
                color="#ec7c37"
                component="button"
                variant="body2"
                onClick={goTosiginup}
              >
                Signup
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Updatepassword;
