import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import profileimage from "../../images/Group 2026.png";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import * as Yup from "yup";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
  getUserId,
} from "../../comman/function";
import { makeStyles } from "@mui/styles";
import {
  GET_TEAMDETAILS_BY_ID,
  UPLOAD_FILE,
  UPDATE_TEAM,
} from "../../comman/url";
import { useDispatch } from "react-redux";
import { authHeader } from "../../comman/mainfunction";
import API from "../../constants/API";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import {
  UPDATE_COMPANY_LOGO,
  UPDATE_USER_PROFILE,
  ISUPDATED
} from "../../redux/action/ActionType";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../../comman/helperfunction";
import { selectUnstyledClasses } from "@mui/base";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles((theme) => ({
  verifybutton: {
    textTransform: "none !important",
    padding: "0px 0px 0px 16px !important",
    color: "#EC7C37 !important",
    fontWeight: "500px !important",
  },
  uploadbtn: {
    backgroundColor: "unset !important",
    border: "1px solid #3779EC !important",
    color: "#3779EC !important",
    boxShadow: "none !important",
  },
  profiledescription: {
    fontSize: "18px",
    letterSpacing: "0px",
    color: "#A0A3BD !important",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative", marginRight: "5px" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={15}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          top: "10px",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={15}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const Companyinfo = (props) => {
  const { classes } = props;
  const styleclasses = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [uplLoading, setUplLoading] = useState(false);
  const [teamData, setTeamData] = useState({
    companyName: "",
    autoApproval: "",
  });
  const [imageCount, setImageCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false)
  const [companyname1,setCompanyname1] =useState()
  console.log("compajny",companyname1);

  const validationSchema = Yup.object({
    companyName: Yup.string().required("Required"),
    autoApproval: Yup.string().required("Required"),
    invoiceSenderEmail: Yup.string()
      .email("Invalid email")
      .required("Required"),
  });

  useEffect(() => {
    GetTeamById();
  }, []);

  const GetTeamById = async () => {
    const config = {
      method: "GET",
      url: GET_TEAMDETAILS_BY_ID,
      headers: authHeader(),
      params: {
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        setTeamData(data[0]);
        setCompanyname1(data[0].companyName)
      }
    } catch (error) {
      //Swal.fire("Error", error);

      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);

        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      //  setLoading(false);
    }
  };
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: teamData,
      enableReinitialize: true,
      validationSchema,
      onSubmit: (values) => UpdateTeam(values),
    });

    useEffect(()=>{
      if(values.companyName != companyname1 ){
        setIsDisabled(true)
      }else{
        setIsDisabled(false)
      }
      console.log("companyname", values.companyName);
    }, [values.companyName])
  
    useEffect(()=>{
      if(isDisabled){
        dispatch({ type: ISUPDATED, payload: true })
      } else{
        dispatch({ type: ISUPDATED, payload: false })
      }
    },[isDisabled])

  let UpdateTeam = async (values) => {
    let { teamId, companyName, autoApproval, invoiceSenderEmail, companyLogo } =
      values;
    const config = {
      method: "PUT",
      url: UPDATE_TEAM,
      data: {
        webString: enCryptFun(
          JSON.stringify({
            teamId,
            companyName,
            autoApproval,
            invoiceSenderEmail,
            companyLogo,
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      setLoading(true);
      const response = await API(config);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
        // Swal.fire(getAlertToast("Success", "Updated Successfully"));
        CustomMessage("Changes Saved: Your updates have been saved successfully.", "success", enqueueSnackbar); //call toster

        localStorage.setItem("BRAND_LOGO", enCryptFun(companyLogo.toString()) );
        dispatch({
          type: UPDATE_COMPANY_LOGO,
          payload: companyLogo,
        });
      }
    } catch (error) {
      // Swal.fire("Error", error);

      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
      setIsDisabled(false)
    }
  };
  const handleFileChange = async (e) => {
    e.persist();
    setImageCount(imageCount + 1);
    let file = e;
    if (!file.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
      // Swal.fire("Alert !", );
      CustomMessage(
        "Invalid file format - Only JPEG, JPG, and PNG are supported.",
        "error",
        enqueueSnackbar
      ); //call toster
    } else {
      let formdata = new FormData();
      var reader = new FileReader();
      reader.readAsDataURL(file.target.files[0]);
      reader.onload = async function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;
        image.onload = async function () {
          var height = this.height;
          var width = this.width;

          // if (!file.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
          //   CustomMessage(
          //     "Please upload jpeg,jpg,png file format only.",
          //     "error",
          //     enqueueSnackbar
          //   ); //call toster
          // } else
          if (height > 60 || width > 200) {
            CustomMessage(
              "Invalid file size - Must be PNG or JPG no larger than 200 px tall and 60 px wide",
              "error",
              enqueueSnackbar
            );
            // return false;
          } 
          else if(height < 50 || width < 200)
          {
            CustomMessage(
              "Invalid file size - Must be PNG or JPG no lesser than 200 px tall and 50 px wide.",
              "error",
              enqueueSnackbar
            );
          }else {
            formdata.append("file", file.target.files[0]);
            try {
              setUplLoading(true);
              const option = {
                method: "POST",
                url: UPLOAD_FILE,
                data: formdata,

                headers: authHeader(),
                "content-type": "multipart/form-data",
              };
              let response = await API(option);
              const { status, filePath, message } = response.data;
              // let l = deCryptFun(response.data);
              // const { status, filePath, message } = JSON.parse(l);
              if (status === "Success") {
                setTeamData({ ...values, companyLogo: filePath });
                // Swal.fire(getAlertToast("Success", message));
                CustomMessage(message, "success", enqueueSnackbar); //call toster
              }
            } catch (error) {
              //Swal.fire("error", "Error");
              if (error.response) {
                let { data } = error.response;

                let p = deCryptFun(data);
                let v = JSON.parse(p);
                // Swal.fire("Error", v.message);
                CustomMessage(v.message, "error", enqueueSnackbar); //call toster
              }
            } finally {
              setUplLoading(false);
            }
          }
        };
      };
    }
  };
  // const handleFileChange = async (e) => {
  //   let formdata = new FormData();
  // if (!e.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
  //   CustomMessage(
  //     "Please upload jpeg,jpg,png file format only.",
  //     "error",
  //     enqueueSnackbar
  //   ); //call toster
  //   }
  // else {
  //     formdata.append("file", e.target.files[0]);
  //     try {
  //       setUplLoading(true);
  //       const option = {
  //         method: "POST",
  //         url: UPLOAD_FILE,
  //         data: formdata,

  //         headers: authHeader(),
  //         "content-type": "multipart/form-data",
  //       };
  //       let response = await API(option);
  //       const { status, filePath, message } = response.data;
  //       // let l = deCryptFun(response.data);
  //       // const { status, filePath, message } = JSON.parse(l);
  //       if (status === "Success") {
  //         setTeamData({ ...values, companyLogo: filePath });
  //         // Swal.fire(getAlertToast("Success", message));
  //         CustomMessage(message, "success", enqueueSnackbar); //call toster
  //       }
  //     } catch (error) {
  //       //Swal.fire("error", "Error");
  //       if (error.response) {
  //         let { data } = error.response;

  //         let p = deCryptFun(data);
  //         let v = JSON.parse(p);
  //         // Swal.fire("Error", v.message);
  //         CustomMessage(v.message, "error", enqueueSnackbar); //call toster
  //       }
  //     } finally {
  //       setUplLoading(false);
  //     }
  //   }
  // };
  return (
    <>
      <Box
        sx={{ maxWidth: 600, margin: "auto", mt: 4 }}
        component={"form"}
        onSubmit={handleSubmit}
      >
   <img
          style={{
            objectFit:"cover",
            maxWidth:"200px",
            width:"100%",
            maxHeight:"60px",
            height:"100%",
            marginBottom:"15px"
          }}
          src={values.companyLogo || profileimage}
        ></img>
        <Typography
          sx={{ fontWeight: 700, fontSize: "20px", mb: 1 }}
          variant="h5"
        >
          Add Your Company Logo
        </Typography>
        <Typography paragraph className={styleclasses.profiledescription}>
        Choose a PNG or JPG image file of your company logo to upload. The recommended dimensions are between 200 x 50px and 200 x 60px, and the file size should not exceed 2MB.
        </Typography>
        <label htmlFor="contained-button-file" key={imageCount || ""}>
          <Input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
          <Button
            className={styleclasses.uploadbtn}
            variant="contained"
            component="span"
            startIcon={
              <span style={{ display: "flex", alignItems: "center" }}>
                {uplLoading && <FacebookCircularProgress />} <FileUploadIcon />
              </span>
            }
          >
            Upload
          </Button>
        </label>
        <Box sx={{ mt: 4, textAlign: "left", display: "grid" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Company Name
            </Typography>
            <CssTextField
              fullWidth
              required
              id="outlined-required"
              onChange={handleChange}
              placeholder="Enter Company Name"
              value={values.companyName}
              onBlur={handleBlur}
              name="companyName"
            />
          </Grid>
        </Box>
        <Button
          sx={{
            mt: 3,
            backgroundColor: "#EC7C37",
            textTransform: "capitalize",
          }}
          variant="contained"
          onClick={handleSubmit}
          type="submit"
          startIcon={
            <span style={{ display: "flex", alignItems: "center" }}>
              {loading && <FacebookCircularProgress />}
            </span>
          }
        >
          Save Changes
        </Button>
      </Box>
    </>
  );
};

export default Companyinfo;
