import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import Apptomate from "../images/apptomate.png";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import InvoiceTable from "../Table/InvoiceTable";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MyteamTable from "../Table/MyteamTable";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsTable from "../Table/SettingsTable";
import Settingsdatatable from "../Table/Settingsdatatable";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddNewFieldSttings from "../components/Modal/AddNewFieldSttings";
import {
  Badge,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CalendarPickerSkeleton, PickersDay } from "@mui/lab";
import {
  FORMAT_SETTING_URL,
  GET_EXPORTED_LIST,
  GET_FORMAT_SETTING_URL,
  GET_INVOICE_FIELDS,
  GET_SUPPLIER_LIST_URL,
  GET_TEAMDETAILS_BY_ID,
  UPDATE_INVOICE_FIELDS,
  UPDATE_TEAM,
} from "../comman/url";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getTeamID,
} from "../comman/function";
import { authHeader, getAlertToast } from "../comman/mainfunction";
import API from "../constants/API";
import {
  REQUIRED_INVOICE,
  REQUIRED_INVOICE_LINE_ITEM,
  ISUPDATED
} from "../redux/action/ActionType";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TearmsandapprovalTable from "../Table/ExportInvoiceData";
import Settingsglcode from "./Settingsglcode";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { SafetyCheck } from "@mui/icons-material";
import { useFormik } from "formik";
import Switch from "@mui/material/Switch";
const useStyles = makeStyles((theme) => ({
  tablerow: {
    background: "black !important",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  antfont: {
    fontSize: "18px !important",
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  pendingbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#EC7C3730 !important",
    color: "#EC7C37 !important",
    boxShadow: "none",
  },
  approvedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#793ED630 !important",
    color: "#793ED6 !important",
    boxShadow: "none",
  },
  autoapprovedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#4A9EF130 !important",
    color: "#4A9EF1 !important",
    boxShadow: "none",
  },
  advancedbutton: {
    boxShadow: "none",
    color: "#14142a50 !important",
    textTransform: "none !important",
    "&:hover": {
      color: "#14142a !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  filterby: {
    backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "16px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
  cancelBtn: {
    margin: "16px 0px 0px 8px !important",
    fontSize: "14px !important",
    color: "#EC7C37 !important",
  },
  preferreddate: {
    color: "#14142A !important",
    textAlign: "left",
    padding: "14px",
  },
  dateformat: {
    width: "100%",
    padding: "8px 14px !important",
  },
  notifysupplierbtn: {
    margin: "0px 8px !important",
    fontSize: "13px !important",
    color: "#EC7C37 !important",
    textTransform: "none !important",
  },
  submitloadbtn: {
    margin: "1rem",
    color: "white !important",
    backgroundColor: "#EC7C37 !important",
    fontSize: "13px !important",
    textTransform: "none !important",
  },
  exportgrid: {
    top: "106px",
    right: "26px",
    zIndex: "9",
    position: "absolute",
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#4A9EF1",
  },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#A0A3BD",

    fontSize: "14px !important",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#14142A",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


export default function Settings(props) {
  const { classes } = props;
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  const [editFormState, seteditFormState] = useState(false);
  const [value, setValue] = React.useState("1");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [mailError, setmailError] = useState("");
  const [thresholdError, setthresholdError] = useState("");
  const [levelError, setlevelError] = useState("");

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [supplierList, setSupplierList] = useState([]);
  const [invoiceFieldCount, setInvoiceFieldCount] = useState("");
const[settingdata,setSettingData]=useState("")
  const handleChangeFun = (event) => {
    setType(event.target.value);
  };
  const [exceptionToEmail, setExceptionToEmail] = useState("");
  const [autoapprovToggle, setautoapprovToggle] = useState(false);
  const[threshhold,setthreshold]=useState(0)
  const[confiLevel,setConfiLevel]=useState(0)
  const [addUserFlag,setAddUserFlag] =useState(false)

  const Requiredinvoicelineitem = useSelector((s) => s.Requiredinvoicelineitem);
  const invoiceList = useSelector((state) => state.Requiredinvoice);
  const TeamReducer = useSelector((s) => s.TeamReducer);
  const [isediting,setIsEditing]= useState(false)
  const dispatch = useDispatch();


  const GetRequiredValues = async () => {
    setLoading(true);

    const config = {
      method: "GET",
      url: GET_INVOICE_FIELDS,
      params: {
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, isExportCount } = JSON.parse(l);
      if (status === "Success") {
        // data
        //   .filter((list) => list.isMandatory === 1)
        //   .map((e, i) => {
        //     setInitialValues((initialValue) => ({
        //       ...initialValue,
        //       [e.fieldListId]: true,
        //     }));
        //   });
        // setExportCheckList(data);
        // setFieldList(data);
        // setExportCount(isExportCount);
        setSettingData(data)
        const data1 = data.filter((list) => list.moduleName == "Invoice Line");

        dispatch({ type: REQUIRED_INVOICE_LINE_ITEM, payload: data1 });

        // setInvoiceLine(data1);
        const data2 = data.filter((list) => list.moduleName == "Invoice");
        // setInvoice(data2);
        dispatch({ type: REQUIRED_INVOICE, payload: data2 });
        setInvoiceFieldCount(isExportCount);
      }
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // Swal.fire("Error", data.message);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };
  const mergeResult = [].concat(invoiceList, Requiredinvoicelineitem);
  
  const validateFields= ()=>{
    const invoice_entries = [
       'invoiceNumber',
       'dueDate',
       'dueAmount',
       'orderNumber',
       'invoiceDate',
       'invoiceCurrency',
       'invoiceAmount',
       'taxTotal',
       'totalAmount'
   ];
   const lineItemEnteries = [
     'operatingUnit',
     'invoiceLineType',
     'invoiceLineNumber',
     'poNumber',
     'poLineNumber',
     'itemNumber',
     'itemDescription',
     'unitOfMeasure',
     'quantity',
     'unitPrice',
     'extendedPrice',
     'GLDate',
     'glAccount'
 ]
 
 const datainvoice= invoiceList.filter((il)=>!invoice_entries.includes(il.columnName) && il.isRequired === 1)
 const lineitem=Requiredinvoicelineitem.filter((rli)=>
 !lineItemEnteries.includes(rli.columnName) && rli.isRequired === 1)
 console.log(datainvoice.length,lineitem.length)
 if(autoapprovToggle){
   if(datainvoice.length ===0 && lineitem.length===0)
   {
     updateTeam()
   }
   else{
     Swal.fire({
       title: "Some fields you have selected as mandatory are not eligible for auto approval process.",
       icon: "warning",
       showCancelButton: true,
       cancelButtonColor: "#d33",
     })
    
   }
 }
 else{
   updateTeam()
 }
 }

 
  const saveFormValues = async (values) => {
    // console.log("test");
    // setIsEditing(false)
    dispatch({ type: ISUPDATED, payload: false })
    const mergeResult = [].concat(invoiceList, Requiredinvoicelineitem);
    setSubmitLoading(true);
    dateFormatSetting();
    const config = {
      method: "POST",
      url: UPDATE_INVOICE_FIELDS,
      data: {
        // teamId: getTeamID(),
        // fieldListId: values,
        // fieldList: mergeResult,
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            fieldList: mergeResult,
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };

    try {
      const response = await API(config);
      //const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        console.error(data);
        // Swal.fire(getAlertToast("success", "Updated Successfully"));
        CustomMessage("Updated Successfully", "success", enqueueSnackbar); //call toster
        seteditFormState((v) => !v);
        GetRequiredValues();
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setSubmitLoading(false);
    }
  };
  const getTeamDetailsById = async () => {
    const config = {
      method: "GET",
      url: GET_TEAMDETAILS_BY_ID,
      headers: authHeader(),
      params: {
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { status, data: initialResponse = [] } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);

      if (status === "Success") {
        // setTeamDetails(initialResponse[0]);
        setExceptionToEmail(data[0].supplierMaintenanceEmail);
        setautoapprovToggle(data[0].isAutoApproval === 1 ? true : false);
        setthreshold(data[0].autoApproval)
        setConfiLevel(data[0].confidentLevel) 
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        // Swal.fire("Error", parsedErr.message);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
    }
  };

  
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    setTouched,
  } = useFormik({
    initialValues: {
      chk: "",
    },
    enableReinitialize: true,
  });
  const dateFormatSetting = async () => {
    const configset = {
      method: "POST",
      url: FORMAT_SETTING_URL,
      data: {
        // teamId: getTeamID(),
        // dateFormat: type || "MM/dd/yyyy",
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            dateFormat: type || "MM/dd/yyyy",
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };

    try {
      const response = await API(configset);
      //const { status } = response.data;
      let l = deCryptFun(response.data);
      const { status } = JSON.parse(l);
      if (status === "Success") {
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  const getdateFormatSetting = async () => {
    const configget = {
      method: "GET",
      url: GET_FORMAT_SETTING_URL,
      params: {
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };

    try {
      const response = await API(configget);
      // const { message, data } = response.data;
      let l = deCryptFun(response.data);
      const { message, data, count } = JSON.parse(l);
      if (message === "Success") {
        const { dateFormat = "" } = data;
        setType(dateFormat);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const handleChangethreshhold = (event) => {
    setthresholdError("");
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    console.log(onlyNums);
    console.log(event.target.value);
    
    if (onlyNums.length < 10) {     
        setthreshold(onlyNums);
    }else{
      setthreshold(onlyNums);
    }  

  };

  const handleChangeleval = (event) => {
    setlevelError("")
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    const setValue = onlyNums <= 100 ? onlyNums : confiLevel;
    setConfiLevel(setValue);  
  };


  let updateTeamDetailsEmail = async () => {
    console.log(exceptionToEmail);
    var validRegex = /^([a-zA-Z0-9_\+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!exceptionToEmail) {
      setmailError("Supplier maintenance email required");
      return null;
    }else if(validRegex.test(exceptionToEmail)) {
      updateTeam();    
    }else{
      setmailError("Enter valid email");
    }    
  };

  let updateTeamDetails = async () => {
  if(autoapprovToggle) {
    if (!threshhold && !confiLevel) {
      setthresholdError("Required field");
      setlevelError("Required field");
      return null;
    }else if (!threshhold) {
      setthresholdError("Required field");    
      return null;
    }
    else if (threshhold == 0) {
      setthresholdError("Required field");    
      return null;
    }
    else if(!confiLevel) {
      setlevelError("Required field");
      return null;
    }
    else if(confiLevel == 0) {
      setlevelError("Required field");
      return null;
    }else{
      validateFields()
    }
  }else{
    updateTeam()
  }
}

let updateTeam = async (values) => {
  const config = {
    method: "PUT",
    url: UPDATE_TEAM,
    data: {
      webString: enCryptFun(
        JSON.stringify({
          teamId: getTeamID(),
          supplierMaintenanceEmail: exceptionToEmail,
          confidentLevel:autoapprovToggle === true ? confiLevel : 0,
          isAutoApproval:autoapprovToggle === true ? 1 : 0,
          autoApproval:autoapprovToggle === true ? threshhold : 0
        })
      ),
      flutterString: "",
    },
    headers: authHeader(),
  };
  try {
    setLoading(true);
    const response = await API(config);
    //const { status } = response.data;
    let l = deCryptFun(response.data);
    const { status } = JSON.parse(l);

    if (status === "Success") {
      // Swal.fire(getAlertToast("Success", "Updated Successfully"));
      CustomMessage("Updated Successfully", "success", enqueueSnackbar); //call toster
    }
  } catch (error) {
    // Swal.fire("Error", error);
    if (error.response) {
      let { data } = error.response;

      let p = deCryptFun(data);
      let v = JSON.parse(p);
      // console.log("error data new",  v.message)
      // Swal.fire("Error", v.message);
      CustomMessage(v.message, "error", enqueueSnackbar); //call toster
    }
  } finally {
    setLoading(false);
  }
}

  const getSupplierList = async () => {
    const config = {
      method: "GET",
      url: GET_SUPPLIER_LIST_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            supplierId: getEmail(),
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let decrpt_response = deCryptFun(response.data);
      const { data } = JSON.parse(decrpt_response);

      setSupplierList(
        data.map((el) => {
          let properties = { label: el.supplierName, value: el.supplierName };
          return properties;
        })
      );
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //Swal.fire(getAlert("Error", v.message));
        // CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const supplierMaintenace = (e) => {
    setmailError("");
    setExceptionToEmail(e.target.value);
  };
  const [chk, setChk] = useState();
  const chkchange = (e) => {
    setChk(e.target.value);
    const { target } = e;
    setTimeout(() => {
      target.focus();
    }, 5);
  };
  useEffect(() => {
    getdateFormatSetting();
  }, []);
  useEffect(() => {
    GetRequiredValues();
    getSupplierList();
  }, []);
  useEffect(() => {
    getTeamDetailsById();
  }, []);

  const tabchangesHanges = (value, tab) => {
    setIsEditing(true)
    setValue(tab);
  };
 
  const editFormValues = () => {
    seteditFormState(true);
  };

  const cancelFormEdit = () => {
    seteditFormState(false);
    GetRequiredValues();
    getdateFormatSetting();
  };

  

  const approvalToggle = () => {
    setautoapprovToggle(!autoapprovToggle);
    //setthreshold(autoapprovToggle === true ? threshhold : 0);
    //setConfiLevel(autoapprovToggle === true ? confiLevel : 0);
    
  };

  const AddNewFormValues = () => {
    setAddUserFlag(!addUserFlag)
  }

  return (
    <>
      <Grid sx={{ pt: 2 }} container spacing={2}>
        <Grid justifyContent="left" item xs={12} md={6}>
          <Typography align="left" sx={{ fontWeight: 500, fontSize: "34px" }}>
            Administration
          </Typography>
        </Grid>
        <Grid sx={{ paddingTop: "0 !important" }} item xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            <img src={TeamReducer.companyLogo} className="companyLogo"></img>
          </Stack>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} md={12}>
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            color: "#A0A3BD",
            marginTop: "8px",
          }}
        >
          {/* <LightTooltip title="Add User" placement="bottom-start"> *
          <InfoOutlinedIcon style={{ marginRight: "16px", color: "#A0A3BD" }} />
          {/* </LightTooltip> *
          Please select the following to show Invoices page
        </Typography>
      </Grid> */}
      {addUserFlag && 
        (
          <AddNewFieldSttings
          AddNewFormValues={AddNewFormValues}
          open={addUserFlag}
          />
        )
      }

      <Grid sx={{ pt: 3 }} container spacing={2}>
        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ display: "flex" }}>
            <TabContext value={value}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ bgcolor: "none" }}>
                  <Box sx={{ border: "none" }}>
                    <TabList
                      onChange={tabchangesHanges}
                      aria-label="lab API tabs example"
                    >
                      <AntTab
                        value="1"
                        label="Field Definitions"
                        className={styleclasses.antfont}
                        style={{ fontWeight: 500 }}
                      />
                      <AntTab
                        value="2"
                        label="Export Definitions"
                        className={styleclasses.antfont}
                        style={{ fontWeight: 500 }}
                      />
                      <AntTab
                        value="3"
                        label="GL Definitions"
                        className={styleclasses.antfont}
                        style={{ fontWeight: 500 }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1" sx={{ padding: "24px 0px" }}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{ textAlign: "right", alignSelf: "end", mb: 2 }}
                      className={styleclasses.exportgrid}
                    >
                      <Button
                        onClick={AddNewFormValues}
                        className={styleclasses.notifydownloadbtn}
                        variant="contained"
                      >
                       Add New Field
                      </Button> 
                      <Button
                        onClick={saveFormValues}
                        className={styleclasses.notifydownloadbtn}
                        variant="contained"
                      >
                        Update
                      </Button> 
                   </Grid>
                    <Box sx={{ width: "100%" }}>
                      <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={styleclasses.leftside}
                          sx={{ padding: "0px 8px 0px 0px " }}
                        >
                          <SettingsTable editFormState={editFormState} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{ padding: "0px 0px 0px 8px" }}
                        >
                          <Grid item xs={12} md={12}>
                            <Settingsdatatable editFormState={editFormState} />
                          </Grid>
                          <Grid item xs={12} md={12} sx={{ pt: 2 }}>
                            <Item sx={{ p: 1 }}>
                              <Typography
                                className={styleclasses.preferreddate}
                                sx={{ fontWeight: 600 }}
                              >
                                Date Format
                              </Typography>
                              <FormControl
                                fullWidth
                                sx={{ padding: "8px 16px" }}
                              >
                                <Select
                                  value={type}
                                  // disabled={!editFormState}
                                  onChange={handleChangeFun}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                  sx={{ textAlign: "left" }}
                                >
                                  <MenuItem value="MM/dd/yyyy">
                                    MM/DD/YYYY
                                  </MenuItem>
                                  <MenuItem value="dd/MM/yyyy">
                                    DD/MM/YYYY
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Item>
                            {/* <Grid
                              sx={{ display: "flex", justifyContent: "end" }}
                            >
                              <Button
                                onClick={saveFormValues}
                                className={styleclasses.notifydownloadbtn}
                                variant="contained"
                              >
                                Save
                              </Button>
                            </Grid> */}
                          </Grid>
                          <Grid sx={{ display: "flex", justifyContent: "end" }}>
                            <>
                              {" "}
                              {/* <Button
                                  onClick={cancelFormEdit}
                                  className={styleclasses.cancelBtn}
                                  variant="text"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={saveFormValues}
                                  className={styleclasses.notifydownloadbtn}
                                  variant="contained"
                                >
                                  Update
                                </Button> */}
                            </>
                          </Grid>

                          <Grid xs={12} md={12} sx={{ mt: 2 }}>
                            <Item>
                              <Typography
                                gutterBottom
                                className={styleclasses.preferreddate}
                                sx={{ fontWeight: 600 }}
                              >
                                Supplier Maintenance Notification Email
                              </Typography>
                              <TextField
                                placeholder="Enter Email Address"
                                sx={{ padding: "8px 16px" }}
                                error={mailError}
                                onChange={supplierMaintenace}
                                value={exceptionToEmail}
                                helperText={mailError}
                                fullWidth
                                // autoFocus
                                id="custom-css-outlined-input"
                              />

                              <Button
                                onClick={updateTeamDetailsEmail}
                                className={styleclasses.submitloadbtn}
                                variant="contained"
                                style={{ margin: "1rem" }}
                              >
                                Submit
                              </Button>
                            </Item>
                          </Grid>
                          <Grid xs={12} md={12} sx={{ mt: 2 }}>    
                          
                          <Item>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Typography
                                  gutterBottom
                                  className={styleclasses.preferreddate}
                                  sx={{ fontWeight: 600 }}
                                >
                                  Touchless Approval
                                </Typography>
                                <AntSwitch
                                  onChange={approvalToggle}
                                  checked={autoapprovToggle}
                                  inputProps={{
                                    "aria-label": "ant design",
                                    "data-name": "autoApproval",
                                  }}
                                />
                              </Stack>
                              {autoapprovToggle && (
                                <>
                                  <Typography
                                    gutterBottom
                                    className={styleclasses.preferreddate}
                                    sx={{ fontWeight: 600 }}
                                  >
                                     Threshold Amount ($)
                                  </Typography>
                                  <TextField
                                    placeholder="Threshold Amount"
                                    sx={{ padding: "8px 16px" }}
                                    error={thresholdError}
                                    type="text"
                                    onChange={handleChangethreshhold}
                                    value={threshhold}
                                    helperText={thresholdError}
                                    name="threshold"
                                    fullWidth
                                  />
                                  <Typography
                                    gutterBottom
                                    className={styleclasses.preferreddate}
                                    sx={{ fontWeight: 600 }}
                                  >
                                    Confidence Level (%)
                                  </Typography>
                                  <TextField
                                    placeholder="Confidence Level"
                                    sx={{ padding: "8px 16px" }}
                                    error={levelError}
                                    onChange={handleChangeleval}
                                    value={confiLevel}
                                    helperText={levelError} 
                                    type="text"
                                    name="confiLevel"
                                    fullWidth
                                  />
                                </>
                              )}
                       
                              <Button
                                onClick={updateTeamDetails}
                                className={styleclasses.submitloadbtn}
                                variant="contained"
                                style={{ margin: "1rem" }}                          
                              >
                                Submit
                              </Button>
                              </Item>
                              </Grid>    
                        </Grid>
                      </Grid>
                    </Box>
                  </TabPanel>
                  <TabPanel value="2" sx={{ padding: "24px 0px 24px 24px" }}>
                    <TearmsandapprovalTable
                      setActiveTab={setValue}
                      invoiceFieldCount={invoiceFieldCount}
                    />
                  </TabPanel>
                  <TabPanel value="3" sx={{ padding: "24px 0px 24px 24px" }}>
                    <Settingsglcode supplierList={supplierList} />
                  </TabPanel>
                </Box>
              </Box>
            </TabContext>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
