import React, { useEffect, useState, useCallback } from "react";
import { styled, alpha } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import Apptomate from "../images/apptomate.png";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ReactApexChart from "react-apexcharts";
import InvoiceTable from "../Table/DashboardInvoiceTable";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Divider from "@mui/material/Divider";
import Uploadinvoice from "./Modal/Uploadinvoice";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Invoice from "./Invoice";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import { makeStyles } from "@mui/styles";
import {
  DASHBOARD,
  GET_USER_BY_ID,
  DASHBOARD_OVERVIEW,
  DASHBOARD_DAILYREPORT,
  DASHBOARD_SUPPLIERSTATICS,
  DASHBOARD_WEEKLY_REPORT,
  DASHBOARD_INVOICE_STATISTICS,
  DASHBOARD_TEAM_STATISTICS,
  DASHBOARD_INVOICE_DISTRIBUTION,
  DASHBOARD_APPROVAL_DISTRIBUTION,
} from "../comman/url";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import html2canvas from "html2canvas";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getFullYear,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../comman/function";
import API from "../constants/API";
import { authHeader } from "../comman/mainfunction";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { INVOICE_ROUTE } from "../constants/RoutePaths";
import Loader from "../comman/Loader";
import { useSelector } from "react-redux";
import UploadInvoiceNew from "./Modal/UploadInvoiceNew";
import {
  TIME_MMDDYYYY_REGEX,
  VALID_DATE_DDMMYYYY_REGEX,
  VALID_DATE_MMDDYYYY_REGEX,
} from "../comman/constants";
import DashboardDailyReport from "./DashboardDailyReport";
import DashboardSupplierStatics from "./DashboardSupplierStatics";
import DashboardTeamStatictics from "./DashboardTeamStatictics";
import { lightFormat } from "date-fns";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import { Style } from "@mui/icons-material";
const ExcelJS = require('exceljs')

const useStyles = makeStyles((theme) => ({
  loginbox: {
    backgroundColor: "#fff",
    padding: "30px 50px",
    borderRadius: "16px",
    textAlign: "left",
    boxShadow: "3px 3px 20px #00000017",
  },
  loginbtn: {
    backgroundColor: "#ec7c37 !important",
    boxShadow: "none !important",
    textTransform: "none !important",
    fontSize: "12px !important",
    "&:hover": {
      fontSize: "12px !important",
      color: "#ec7c37 !important",
      border: "1px solid #ec7c37 !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  downbn: {
    backgroundColor: "#ec7c37 !important",
    boxShadow: "none !important",
    textTransform: "none !important",
    fontSize: "12px !important",
    border: "1px solid #ec7c37 !important",
    "&:hover": {
      color: "#ec7c37 !important",
      fontSize: "12px !important",
      border: "1px solid #ec7c37 !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "10px !important",
  backgroundColor: "white !important",
  boxShadow: "2px 2px 1px #0000001c",
  marginLeft: "25px !important",
  height: "37px",
  padding: "0px 0px",

  "&:hover": {
    backgroundColor: "white !important",
  },
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
    display: "flex",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "40px",
    [theme.breakpoints.up("sm")]: {
      width: "0ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));
const AntTabsmemo = styled(Tabs)({
  borderBottom: getRole() != "Supplier" ? "1px solid #e8e8e8" : "",
  "& .MuiTabs-indicator": {
    backgroundColor: "#4A9EF1",
  },
});
const AntTabs = React.memo(AntTabsmemo);
const AntTabMemo = styled((props) => {
  return <Tab disableRipple {...props} />;
})(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: "rgba(0, 0, 0, 0.85)",
  fontSize: "18px !important",
  fontFamily: ["Montserrat"].join(","),
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#14142A",
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const dateFormate = (date) => {
  return moment(date).utc().format("YYYY-MM-DD");
  
};
const dateFormate1 = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

const dateFormateyear = (date) => {
  return moment(date).format("YYYY");
};

export default function Dashboard() {
  const styleclasses = useStyles();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  var year = new Date().getFullYear();
  var currentYearfirstaDate = new Date(year, 0, 1);
  let previousyrdate = new Date().setFullYear(new Date().getFullYear() - 1);

  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [recentInvoice, setrecentInvoice] = useState([]);
  const [overviewCount, setoverviewCount] = useState([]);
  const [Months, setMonths] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const hct = [{ data: [2, 3] }];
  const [hoursCount, sethoursCount] = useState([]);
  const [filterState, setfilterState] = useState({
    // analysisDate: dateFormate(new Date()),
    fromDate: previousyrdate,
    toDate: new Date(),
  });
  const [error, setError] = useState({ fromDate: "", toDate: "" });
  const [amount, setamount] = useState(0);
  const [Pending, setPending] = useState(0);
  const [Approved, setApproved] = useState(0);
  const [ExceptionStatus, setExceptionStatus] = useState(0);
  const [AutoApproved, setAutoApproved] = useState(0);
  const [SpinnerToggle, setSpinnerToggle] = useState(false);

  const [value, setValue] = useState(0);
  const [inviteSuppliermodal, setInviteSuppliermodal] = React.useState(false);
  const createinvoiceopen = () => setInviteSuppliermodal((v) => !v);
  const [invoiceFilterChange, setInvoiceFilterChange] = useState("");
  const [invoiceFilterLoading, setInvoiceFilterLoading] = useState(false);
  const [analysisFilterChange, setAnalysisFilterChange] = useState("");
  const [analysisFilterLoading, setAnalysisFilterLoading] = useState(false);
  const TeamReducer = useSelector((s) => s.TeamReducer);
  const [toDate, setToDate] = useState("");
  const [invoicestatistics, setinvoicestatistics] = useState();
  const [invoicestatistics2, setinvoicestatistics2] = useState();
  const [invoicestatistics3, setinvoicestatistics3] = useState();
  const [supplierStatData, setsupplierstatData] = useState("");
  const [supplierStatToggle, setsupplierStatToggle] = useState(false);
  const [teamToggle, setTeamToggle] = useState(false);
  const [teamToggle1, setTeamToggle1] = useState(false);
  const [teamMemberstat, setTeamMemberstat] = useState();
  const [invDistribution, setInvDistribution] = useState();
  const [aprvDistribution, setAprvDistribution] = useState();
  const [dailyLoading, setdailyLoading] = useState(false);
  const isUploaded = useSelector((s) => s.DashboardReducer);

  const timeslot = [
    {
      name: "12.00AM-3.00AM",
    },
    { name: "3.00AM-6.00AM" },
    { name: "6.00AM-9.00AM" },
    { name: "9.00AM-12.00PM" },
    { name: "12.00PM-3.00PM" },
    { name: "3.00PM-6.00PM" },
    { name: "6.00PM-9.00PM" },
    { name: "9.00PM-12.00AM" },
  ];
  const ts = timeslot
    .filter((x) => x.name !== "N/A" && x.name !== "null")
    .map((acc) => acc.name.split(/[ ,]+/));
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  var baroptions = {
    chart: {
      type: "bar",
      height: 250,
      stacked: true,

      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: { title: { show: false, formatter: () => "" } },
    },
    yaxis: {
      // title: false,

      labels: {
        formatter: function (value) {
          return value.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          });
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return "";
      },
    },
    plotOptions: {
      // bar: {
      //   horizontal: false,
      //   borderRadius: 10,
      //   columnWidth: "20%",
      // },
    },
    xaxis: {
      type: "text",
      categories: Months,
      title: {
        text: "Month",
        style: {
          fontWeight: 600,
        },
      },
    },
    yaxis: {
      title: {
        text: "Invoice Count",
        floating: false,

        style: {
          fontWeight: 600,
        },
      },
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
    noData: {
      text: "No invoices Received",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
    },
  };


  //analysis chart

  let analysisseries = [Pending, ExceptionStatus];
  var analysisoptions = {
    colors: ["#f4b940", "#FF7519"],
    labels: ["Pending Invoices", "Exception Invoices"],

    tooltip: { enabled: false },
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          labels: {
            show: true,
            name: { show: true },
            value: { show: true, fontSize: "30px", fontWeight: "700" },
            total: { show: true, label: "Total Invoices" },
          },
        },
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },

    legend: {
      show: false,
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
  };
  let analysisseries1 = [Approved, AutoApproved];
  var analysisoptions1 = {
    colors: ["#793ed6", "#4a9ef1"],
    labels: ["Approved Invoices", "Auto Approved Invoices"],

    tooltip: { enabled: false },
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          labels: {
            show: true,
            name: { show: true },
            value: { show: true, fontSize: "30px", fontWeight: "700" },
            total: { show: true, label: "Total Invoices" },
          },
        },
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },

    legend: {
      show: false,
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
  };

  const apexLineChartWithLables = {
    tooltip: {
      enabled: false,
      x: {
        show: false,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
    },
    chart: {
      height: 500,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#727cf5", "#0acf97"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      // text: "Pending in Queue through out a day",
      // align: "left",
      style: {
        fontSize: "15px",
      },
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f3fa",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      type: "text",
      categories: ts,

      // "9:00AM-12.00PM",
      // "12:00PM-3.00PM",
      // "3:00PM-6.00PM",
      // "6:00PM-9:00PM",
      // "9:00PM-12:00PM",
      // "12:00PM-3:00AM",
      // "3:00AM-6:00AM",
      // "6:00AM",
      labels: {
        enabled: true,
        style: {
          fontSize: '10px',
          fontWeight: 600
        },
      },
      title: {
        text: "Time",
        style: {
          fontWeight: 600,
        },
      },
    },
    yaxis: {
      title: {
         text: "Number of Invoices",
         style: {
          fontWeight: 600,
        },
      },
      // min: 5,
      // max: 40

      categories: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      labels: {
        formatter: function (val) {
          return parseInt(val);
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          legend: {
            style: {
              fontSize: "140px !important",
         
            }
          },
        },
      },
    ],
  };

  // chart data

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  const getAmountValue = async () => {
    let amount;
    const configuser = {
      method: "GET",
      url: GET_USER_BY_ID,
      headers: authHeader(),
      params: {
        //userId: getUserId(),
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(configuser);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "Success") {
        const { approvalAmountTo } = data[0];

        setamount(approvalAmountTo);
      }
    } catch (error) {
      //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);

        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  //overview dashboard
  const dashboardOverview = async (filterValues) => {
    const config = {
      method: "GET",
      url: DASHBOARD_OVERVIEW,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId:
              ["Admin", "Team Member", "Exception Handler"].includes(
                getRole()
              ) || amount === -1
                ? getTeamID()
                : 0,

            senderEmail: ["Supplier", "Sub Supplier"].includes(getRole())
              ? getSenderEmail()
              : "",
            // userId:
            //   getRole() === "Team Member" && amount !== -1 ? getUserId() : 0,
            ...filterValues,
            fromDate: dateFormate(filterValues.fromDate),
            toDate: dateFormate(filterValues.toDate),
            // analysisDate:dateFormate(filterValues.analysisDate)
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);

      let l = deCryptFun(response.data);
      console.log(l,"l");
      const { status, invoiceAnalysis, receivedInvoices, hourByPending ,AutoApprovedPerDay ,ApprovedPerDay } =
        JSON.parse(l);
        console.log(AutoApprovedPerDay[0],ApprovedPerDay[0],"hiii");
      if (status === "Success") {
        const analysisValu = {
          Pending: 0,
          Approved: ApprovedPerDay[0].invoiceCount,
          Rejected: 0,
          Exception: 0,
          AutoApproved:AutoApprovedPerDay[0].invoiceCount,
        };

        invoiceAnalysis.forEach((val) => {
          analysisValu[val.status] = val.invoiceCount;
        });

        setPending(analysisValu.Pending);
        setApproved(analysisValu.Approved);
        setExceptionStatus(analysisValu.Exception);
        setAutoApproved(analysisValu.AutoApproved); //by default
        let Total = [];
        receivedInvoices.forEach((val) => {
          let TotalInvoice = val.invoiceCount;

          // Total.push(parseFloat(TotalAmounyt).toFixed(1));
          Total.push(TotalInvoice);
        });
        setMonths(receivedInvoices.map((val) => val.Month));
        setoverviewCount([
          {
            name: "Total",
            data: Total,
          },
        ]);

        //hoursBypending
        let invoiceCountByhrs = [];
        let hours = [1, 2, 3, 4, 5, 6, 7, 8];
        const d = new Date().getHours();
        hours.forEach((val_hours, index) => {
          if (val_hours <= d) {
            if (
              !hourByPending.find((vallist) => vallist.hourgroup == val_hours)
            ) {
              hourByPending.splice(index, 0, {
                hourGroup: index + 1,
                invoiceCount: 0,
              });
            }
          }
        });
        if (hourByPending.length == 8) {
          hourByPending.forEach((val) => {
            invoiceCountByhrs.push(val.invoiceCount);
          });
        }
        sethoursCount([
          {
            name: "invoicecount",
            data: invoiceCountByhrs,
          },
        ]);
      }
    } catch (error) {}
  };
  const getdailyreports = async () => {
    setdailyLoading(true);
    const config = {
      method: "GET",
      url: DASHBOARD_DAILYREPORT,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId:
              ["Admin", "Team Member", "Exception Handler"].includes(
                getRole()
              ) || amount === -1
                ? getTeamID()
                : 0,

            senderEmail: getRole() === "Supplier" ? getEmail() : "",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const {
        status,
        receivedInvoices,
        approvedCount,
        PendingCount,
        approvedVsPending,
        supplierByPending,
        numberOfException,
      } = JSON.parse(l);
      if (status === "Success") {
        setinvoicestatistics({
          receivedInvoices: receivedInvoices,
          approvedVsPending: approvedVsPending,
          supplierByPending: supplierByPending,
          numberOfException: numberOfException,
          approvedCount: approvedCount,
        });
        setdailyLoading(false);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setdailyLoading(false);
    }
  };

  const getStatiticsOfInvoice = async () => {
    const config = {
      method: "GET",
      url: DASHBOARD_INVOICE_STATISTICS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId:
              ["Admin", "Team Member"].includes(getRole()) || amount === -1
                ? getTeamID()
                : 0,

            senderEmail: getRole() === "Supplier" ? getEmail() : "",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const {
        status,
        totalInvoices,
        approvedOnTime,
        unApprovedInvoices,
        nonPo,
        invoiceSubmission,
      } = JSON.parse(l);
      if (status === "Success") {
        setinvoicestatistics3({
          // totalInvoice: totalInvoices.totalCount,
          // currentDate:totalInvoices.currentDate,
          approvedOnTime: approvedOnTime,
          unApprovedInvoices: unApprovedInvoices,
          nonPo: nonPo,
          invoiceSubmission: invoiceSubmission,
        });
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  const getWeeklyReports = async () => {
    const config = {
      method: "GET",
      url: DASHBOARD_WEEKLY_REPORT,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId:
              ["Admin", "Team Member", "Exception Handler"].includes(
                getRole()
              ) || amount === -1
                ? getTeamID()
                : 0,

            senderEmail: getRole() === "Supplier" ? getEmail() : "",
            fromDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
            toDate: moment(new Date()).format("YYYY-MM-DD"),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, receivedVsPending, receivedVsException,approvedCount } = JSON.parse(l);
      console.log(JSON.parse(l),"1234");
      if (status === "Success") {
        setinvoicestatistics2({
          receivedVsPending: receivedVsPending,
          receivedVsException: receivedVsException,
          approvedCount:approvedCount,
        });
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const getsupplierStatics = async () => {
    setsupplierStatToggle(true);
    const config = {
      method: "GET",
      url: DASHBOARD_SUPPLIERSTATICS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId:
              ["Admin", "Team Member", "Exception Handler"].includes(
                getRole()
              ) || amount === -1
                ? getTeamID()
                : 0,

            senderEmail: getRole() === "Supplier" ? getEmail() : "",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const {
        status,
        pendingBySupplier,
        CurrentInvExceptionBySupplier,
        AvgInvProcessingTimeBySupplier,
        InvWithExceptionsBySupplier,
      } = JSON.parse(l);
      if (status === "Success") {
        setsupplierstatData({
          InvPendingCountBySupplier: pendingBySupplier,
          CurrentInvExceptionBySupplier: CurrentInvExceptionBySupplier,
          AvgInvProcessingTimeBySupplier: AvgInvProcessingTimeBySupplier,
          InvWithExceptionsBySupplier: InvWithExceptionsBySupplier,
        });
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setsupplierStatToggle(false);
    }
  };

  const getTeamMemberStatictics = async () => {
    let date = new Date();
    var fromActionDate =
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    var toActionDate =
      date.getDate() + date.getDay() + (date.getDay() === 0 ? -6 : 1);
    let fromac = new Date(date.setDate(fromActionDate));
    let toacc = new Date(date.setDate(toActionDate));
    setTeamToggle(true);
    const config = {
      method: "GET",
      url: DASHBOARD_TEAM_STATISTICS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId:
              ["Admin", "Team Member", "Exception Handler"].includes(
                getRole()
              ) || amount === -1
                ? getTeamID()
                : 0,
            fromDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
            toDate: moment(new Date()).format("YYYY-MM-DD"),
            userActionFromDate: fromac.toISOString().slice(0, 10),
            userActionToDate: toacc.toISOString().slice(0, 10),
            // senderEmail: getRole() === "Supplier" ? getEmail() : "",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const {
        status,
        UserActionsPerDay,
        TeamMemberApprovals,
        TeamRoleDistribution,
        InvoiceApprovalWorkloaddistribution,
        AvgDataEntryTimePerTeamMember,
        TeamRoleDistributioWithException,
      } = JSON.parse(l);
      let temp = TeamRoleDistribution;
      if (
        TeamRoleDistributioWithException &&
        TeamRoleDistributioWithException[0].totalCount > 0
      ) {
        temp.push({
          totalCount: TeamRoleDistributioWithException[0].totalCount,
          UserRole: "Team member with Exception",
        });
      }
      if (status === "Success") {
        setTeamMemberstat({
          UserActionsPerDay: UserActionsPerDay,
          TeamMemberApprovals: TeamMemberApprovals,
          TeamRoleDistribution: temp,
          InvoiceApprovalWorkload: InvoiceApprovalWorkloaddistribution,
          AvgDataEntryTimePerTeamMember: AvgDataEntryTimePerTeamMember,
        });
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setTeamToggle(false);
    }
  };

  const getInvDistribution = async () => {
    const config = {
      method: "GET",
      url: DASHBOARD_INVOICE_DISTRIBUTION,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId:
              ["Admin", "Team Member", "Exception Handler"].includes(
                getRole()
              ) || amount === -1
                ? getTeamID()
                : 0,

            // senderEmail: getRole() === "Supplier" ? getEmail() : "",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "success") {
        setInvDistribution(data);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //call toster
      }
    } finally {
    }
  };

  const getApprovalDistribution = async () => {
    setTeamToggle1(true);
    const config = {
      method: "GET",
      url: DASHBOARD_APPROVAL_DISTRIBUTION,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId:
              ["Admin", "Team Member", "Exception Handler"].includes(
                getRole()
              ) || amount === -1
                ? getTeamID()
                : 0,

            senderEmail: getRole() === "Supplier" ? getEmail() : "",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      if (status === "success") {
        setAprvDistribution(data);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setTeamToggle1(false);
    }
  };
  useEffect(() => {
    if (getRole() === "Admin") {
      getTeamMemberStatictics();
    }
  }, []);

  useEffect(() => {
    if (getRole() === "Admin") {
      getApprovalDistribution();
    }
  }, []);
  useEffect(() => {
    if (getRole() === "Admin") {
      getsupplierStatics();
    }
  }, []);
  // useEffect(() => {
  //   getTeamMemberStatictics();
  // }, []);
  useEffect(() => {
    if (getRole() === "Admin") {
      getInvDistribution();
    }
  }, []);

  useEffect(() => {
    if (getRole() === "Admin") {
      getdailyreports();
      getWeeklyReports();
      getStatiticsOfInvoice();
    }
  }, []);
  useEffect(() => {
    dashboardOverview(filterState);
  }, [filterState.fromDate, filterState.toDate]);

  useEffect(() => {
    getAmountValue();
  }, []);
  const [fromFlag, setFlag] = useState(false);
  const dateHandlechange = (name, value) => {
    // setfilterState((v) => ({ ...v, [name]: value }));

    // let lfin = dateFormate1.toString("dddd, MMMM ,yyyy");
    let lfin = dateFormate1(new Date(value));
    if (lfin.length == 10 && dateFormate1(new Date(value) != "Invalid Date")) {
      if (
        name == "fromDate" &&
        dateFormate1(new Date(value)) !=
          dateFormate(new Date().setFullYear(new Date().getFullYear() - 1))
      ) {
        if (
          (name == "fromDate" &&
            dateFormate1(new Date(value)) <
              dateFormate(
                new Date().setFullYear(new Date().getFullYear() - 1)
              )) ||
          dateFormate1(new Date(value) > dateFormate1(new Date()))
        ) {

          if (
            dateFormateyear(value) > "0001" ||
            dateFormateyear(value) < "0999"
            // ||
            // dateFormate1(new Date(value)) === dateFormate1(new Date())
          ) {

            return null;
          } else {
            setFlag(true);
            CustomMessage("Invalid Date", "error", enqueueSnackbar);
            return null;
          }
        }
      } else {
        CustomMessage("Invalid Date", "error", enqueueSnackbar);
      }
      //to date
      if (
        (name == "toDate" && dateFormate1(new Date(value)) < previousyrdate) ||
        dateFormate1(new Date(value)) > dateFormate1(new Date())
      ) {
        if (
          (dateFormateyear(value) > "0001" &&
            dateFormateyear(value) < "0999") ||
          dateFormate1(new Date(value)) === dateFormate1(new Date())
        ) {
          return null;
        } else {
          CustomMessage("Invalid Date", "error", enqueueSnackbar);
          return null;
        }
      }
      if (value == "Invalid Date") {
        CustomMessage("Invalid Date", "error", enqueueSnackbar);
        return null;
      }
      let d1 = new Date(filterState.fromDate);
      let d2 = new Date(value);
      let sf =
        moment(new Date(value)).format("YYYY-MM-DD") ==
        moment(new Date(filterState.fromDate)).format("YYYY-MM-DD");
      if (
        (name == "toDate" &&
          new Date(value) < new Date(filterState.fromDate)) ||
        new Date(value) == new Date(filterState.fromDate)
      ) {
        
        if (dateFormateyear(value) < "0999") {
          return null;
        }

        if (
          dateFormateyear(value) < "0999" ||
          sf ||
          dateFormate1(new Date(value)) === dateFormate1(new Date())
        ) {
          return null;
        } else {
          CustomMessage(
            "To Date is Invalid/Should be greater than From Date ",
            "error",
            enqueueSnackbar
          );
          return null;
        }
      }
     }
    if (name == "analysisDate") {
      setAnalysisFilterChange("Invoice Analysis Filter is changed");
    } else {
      setInvoiceFilterChange("Invoice Filter is changed");
    }
    setfilterState((v) => ({ ...v, [name]: value }));
  };
  const handleChange1 = (key, value) => {
    setfilterState({ ...filterState, [key]: value });
  }
  // const dateHandlechange2 = (name, value) => {
  //   let lfin = dateFormate1(new Date(value));

  //   if (lfin.length == 10 && new Date(value) !== "Invalid Date") {
  //     if (
  //       (name == "toDate" &&
  //         dateFormate1(new Date(value)) >=
  //           dateFormate(new Date().setFullYear(new Date().getFullYear() - 1)) &&
  //         dateFormate1(new Date(value)) <= dateFormate1(new Date())) ||
  //       new Date(value) == new Date(filterState.fromDate)
  //     ) {
  //       setfilterState((v) => ({ ...v, [name]: value }));

  //       return null;
  //     } else if (
  //       dateFormateyear(value) > "0001" &&
  //       dateFormateyear(value) < "0999"
  //     ) {
  //       return null;
  //       // CustomMessage("Invalid year", "error", enqueueSnackbar);
  //     } else {
  //       CustomMessage("Invalid Date", "error", enqueueSnackbar);
  //       setfilterState((v) => ({ ...v, [name]: new Date() }));

  //       return null;
  //     }
  //   }
  //   //  else {
  //   //   return null;
  //   // }
  //   if (name == "analysisDate") {
  //     setAnalysisFilterChange("Invoice Analysis Filter is changed");
  //   } else {
  //     setInvoiceFilterChange("Invoice Filter is changed");
  //   }
  //   setfilterState((v) => ({ ...v, [name]: value }));
  // };
  const handleError = (key, value) => {
    if (key === "fromDate") {
      if (value === "invalidDate") setError({ ...error, fromDate: "Invalid date format" });
      else if (value === "minDate") setError({ ...error, fromDate: "Invalid date - Dates input cannot exceed the current date (today's date)" });
      else if (value === "maxDate") setError({ ...error, fromDate: "Invalid date range" });
      else setError({ ...error, fromDate: "" });
    }
    if (key === "toDate") {
      if (value === "invalidDate") setError({ ...error, toDate: "Invalid date format" });
      else if (value === "minDate") setError({ ...error, toDate: "Invalid date range" });
      else if (value === "maxDate") setError({ ...error, toDate: "Invalid date - Dates input cannot exceed the current date (today's date)" });
      else setError({ ...error, toDate: "" });
    }
  }
  // const dateHandlechange1 = (name, value) => {
  //   let lfin = dateFormate1(new Date(value));
  //   if (lfin.length == 10 && new Date(value) !== "Invalid Date") {
  //     if (name == "fromDate") {
  //       if (
  //         // name == "fromDate" &&
  //         dateFormate1(new Date(value)) >=
  //           dateFormate(new Date().setFullYear(new Date().getFullYear() - 1)) &&
  //         dateFormate1(new Date(value)) <= dateFormate1(new Date())
  //       ) {
  //         setfilterState((v) => ({ ...v, [name]: value }));

  //         return null;
  //       } else if (
  //         dateFormateyear(value) > "0001" &&
  //         dateFormateyear(value) < "0999"
  //       ) {
  //         return null;
  //          //CustomMessage("Invalid yearhh", "error", enqueueSnackbar);
  //       } else if (dateFormateyear(value) == "000") {
  //         CustomMessage(
  //           "Invalid date. Please select the date with in past one year.",
  //           "error",
  //           enqueueSnackbar
  //         );
  //        //  setfilterState((v) => ({ ...v, [name]: previousyrdate }));
  //       }
  //       else if (dateFormate1(value) == "00"){
  //         CustomMessage(
  //           "Invalid date. Please select.",
  //           "error",
  //           enqueueSnackbar
  //         );
  //       }
  //        else {
  //         CustomMessage(
  //           "Invalid date. Please select the date with in past one year.",
  //           "error",
  //           enqueueSnackbar
  //         );
  //         setfilterState((v) => ({ ...v, [name]: previousyrdate }));

  //         return null;
  //       }
  //     }
  //    }
  //   //else if(lfin.length == 10 && new Date(value) == "Invalid Date"){
  //   //   CustomMessage("Invalid C.",   "error", enqueueSnackbar);
  //   // } 
  //   else {
  //       "chk fin" ,
  //       dateFormateyear(value),value,
  //       dateFormateyear(value) == "0000"
  //     );
  //    //CustomMessage("Invalid month.Please enter valid month.",   "error", enqueueSnackbar);
  //     return null;
  //   }

  //   if (name == "analysisDate") {
  //     setAnalysisFilterChange("Invoice Analysis Filter is changed");
  //   } else {
  //     setInvoiceFilterChange("Invoice Filter is changed");
  //   }
  //   setfilterState((v) => ({ ...v, [name]: value }));
  // };
  const toDataURL = (url) => {
    const promise = new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          resolve({ base64Url: reader.result });
        };
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  
    return promise;
  };
  const generateDocument = async () => {
    await html2canvas(document.getElementById("main_editor_preview"), {
      useCORS: true,
      height: document.body.offsetHeight,
      width: document.body.offsetWidth,
    }).then((canvas) => {    
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("My Sheet");
      sheet.properties.defaultRowHeight = 100;

          const rowNumber = 0;
          const result =  canvas.toDataURL("image/png")
          // const splitted = product?.thumbnail.split(".");
          // const extName = splitted[splitted.length - 1];
  
          const imageId2 = workbook.addImage({
            base64: result,
            extension: 'jpeg',
          });
  
          sheet.addImage(imageId2, {
            tl: { col: 0, row: rowNumber },
            ext: { width: 1000, height: 2000 },
          });

    
        const priceCol = sheet.getColumn(1);
  
        // iterate over all current cells in this column
        priceCol.eachCell((cell) => {
          const cellValue = sheet.getCell(cell?.address).value;
          // add a condition to set styling
          if (cellValue > 50 && cellValue < 1000) {
            sheet.getCell(cell?.address).fill = {
              type: "none",
              pattern: "solid",
              fgColor: { argb: "FF0000" },
            };
          }
        });
  
        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "download.xlsx";
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
   


    // console.log(imgData,"qwerghjm,")
    //   var link = document.createElement("a");
    //   link.href = imgData;
    //   link.download = "filename";
    //   console.log(link.download,"asdfghjkl;");
    //   const pdf = new jsPDF({ orientation: "landscape" });
    //   var width = pdf.internal.pageSize.getWidth();
    //   var height = pdf.internal.pageSize.getHeight();
    //   pdf.addImage(imgData, "JPEG", 30, 0, width - 40, height + 20);
    //   // pdf.output('dataurlnewwindow');
    //   pdf.save("download.pdf");
    });
  };
  const invalidate = (e) => {
    // return null
  };
  const navigateTOinvoice = (e) => {
    let stva = e.currentTarget.dataset.status;
    navigation(INVOICE_ROUTE, {
      state: {
        date: "",
        status: e.currentTarget.dataset.status,
        tab: stva === "ExceptionStatus" ? "2" : "1",
        invCreatedDate:
          stva === "Approved" ? new Date().toISOString().slice(0, 10) : "",
      },
    });
  };
  const maxDateFunction = (e) => {
    if (toDate != null && toDate != "Invalid Date") {
      return new Date(filterState.toDate);
    } else {
      return new Date();
    }
  };

  const ptag = { margin: "7px", "font-weight": "bolder" };

  const handleChangeTab = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);
  useEffect(() => {
    if (hoursCount) {
      let d = new Date().hours % 12;
      let temp1 = [];
      hoursCount.map((rec, i) => temp1.push(rec));
    }
  }, [hoursCount]);
  let tempdata = [
    {
      name: "faa",
      data: [2, 0, null, null, 0, 4, 0],
    },
  ];
  return (
    <>
      <Grid container sx={{ zIndex: "0" }} spacing={2}>
        <Grid
          sx={{ display: "flex", flexGrow: 1 }}
          justifyContent="left"
          item
          xs={12}
          md={6}
        >
          <Typography
            align="left"
            sx={{
              fontWeight: "500",
              fontSize: "26px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            Dashboard
          </Typography>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            {/* <Button
              sx={{ backgroundColor: "#EC7C37", boxShadow: "none" }}
              className={styleclasses.loginbtn}
              variant="contained"
              onClick={createinvoiceopen}
            >
              New Invoice
            </Button> */}
            {!["Supplier", "Sub Supplier"].includes(getRole()) ? (
              <UploadInvoiceNew />
            ) : (
              <Button
                onClick={createinvoiceopen}
                className={styleclasses.downbn}
                variant="contained"
              >
                New Invoice
              </Button>
            )}
            <img src={TeamReducer.companyLogo} className="companyLogo"></img>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%" }}>
        <Box  className="dashboard-export" sx={{ borderBottom: 1, borderColor: "divider" }}>
          <AntTabs
            className={styleclasses.tabsmemo}
            sx={{ borderBottom: "none" }}
            aria-label="ant example"
            value={value}
            onChange={handleChangeTab}
          >
            <AntTabMemo className={styleclasses.tamemo} label="Overview" />
            {getRole() === "Admin" && (
              <AntTabMemo
                className={styleclasses.tamemo}
                label="Invoice Statistics"
                disabled={dailyLoading || isUploaded}
              />
            )}
            {getRole() === "Admin" && (
              <AntTabMemo
                className={styleclasses.tamemo}
                label="Supplier Statistics"
                disabled={isUploaded}
              />
            )}
            {getRole() === "Admin" && (
              <AntTabMemo
                className={styleclasses.tamemo}
                label="Team Statistics"
                disabled={teamToggle1 || isUploaded}
              />
            )}
            {getRole() === "Admin" && (
            <Button
              className={styleclasses.downbn}
              sx={{ backgroundColor: "#EC7C37", boxShadow: "none",position:"absolute",right:"0"}}
              variant="contained"
              disabled={SpinnerToggle}
              onClick={generateDocument}
            >
              Export
            </Button>)}
          </AntTabs>
        </Box>
      </Box>

      {value == 0 && (
        <>
          <Grid id="main_editor_preview" container sx={{ mb: 2 }}>
            <Grid item xs={12} md={12} justifyContent="left">
              <Typography
                align="left"
                variant="h5"
                sx={{ fontWeight: "600", mt: 3.7 }}
              >
                Invoices Received
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={12}
              sx={{
                p: 3,
                mt: 2,
                backgroundColor: "#fff",
                borderRadius: "16px",
                boxShadow: "2px 2px 9px #0000001c",
                zIndex: "0 !important",
                width: "100%",
              }}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={12}
                sx={{ zIndex: "0", display: "flex" }}
                justifyContent="right"
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack
                    sx={{ display: "flex", flexDirection: "row" }}
                    spacing={3}
                  >
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={filterState.fromDate}
                      minDate={previousyrdate}
                      maxDate={maxDateFunction()}
                      allowSameDateSelection
                      onChange={(e) => { handleChange1("fromDate", e) }}
                      onError={(e) => handleError("fromDate", e)}
                      error={error.fromDate !== ""}
                      helperText={error.fromDate}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          sx={{ width: "300px !important", mr: 2 }}
                          error={error.fromDate !== ""}
                          helperText={error.fromDate}
                        />
                      )}
                    />

                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={filterState.toDate}
                      allowSameDateSelection
                      // onChange={(e) => {
                      //   setToDate(e);
                      //   dateHandlechange2("toDate", e);
                      // }}
                      onChange={(e) => { handleChange1("toDate", e) }}

                      minDate={new Date(filterState.fromDate)}
                      maxDate={new Date()}
                      // onError={invalidate()}
                      onError={(e) => handleError("toDate", e)}
                      error={error.toDate !== ""}
                      helperText={error.toDate}
                      renderInput={(params) => (
                        <TextField
                          size='small'
                          {...params}
                          sx={{
                            marginTop: "0px !important",
                            width: "300px !important",
                            marginRight: "16px !important",
                            // height: "0.5375em",
                          }}
                          style={{fontSize: 1}}
                          error={error.toDate !== ""}
                          helperText={error.toDate}
                        />
                      )}
                    />
                     <Tooltip sx={{ mt: "0px !important" }} title="Track the Flow of Invoices: This dashboard displays the monthly count of invoices received.">
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </LocalizationProvider>
              </Grid>
              {SpinnerToggle || invoiceFilterLoading ? (
                <Box
                  sx={{
                    height: 250,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader />
                </Box>
              ) : overviewCount && overviewCount.length > 0 ? (
                <ReactApexChart
                  options={baroptions}
                  series={overviewCount}
                  type="bar"
                  height={250}
                />
              ) : (
                "No invoices yet"
              )}
            </Grid>

            <Grid sx={{ mt: 5, mb: 5 }} container>
              <Grid item sx={{ pr: 3 }} xs={12} md={12}>
                <Typography
                  sx={{ mb: 2, fontWeight: "600" }}
                  align="left"
                  variant="h5"
                >
                  Unprocessed Invoices
                </Typography>
                <Box
                  sx={{
                    p: 3,
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    boxShadow: "2px 2px 20px #00000012",
                    height: "570px",
                    textAlign:"right"
                    
                  }}
                >
                 <Tooltip sx={{ mt: "0px !important" }} title="This dashboard displays the number of invoices received at various times throughout the current day.">
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  {hoursCount.length > 0 ? (
                    <ReactApexChart
                      options={apexLineChartWithLables}
                      series={hoursCount}
                      type="line"
                      height="500px"
                    />
                  ) : (
                    "No Pending invoices today"
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 5 }} container>
              <Grid item sx={{ pr: 3 }} xs={12} md={6}>
                <Typography
                  align="left"
                  sx={{ mb: 2, fontWeight: "600" }}
                  variant="h5"
                >
                  Invoices Awaiting Processing
                </Typography>
                <Box
                  sx={{
                    p: 3,
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    boxShadow: "2px 2px 20px #00000012",
                    height: "600px",
                    height: "620px",
                    textAlign:"right"
                  }}
                >
                   <Tooltip sx={{ mt: "0px !important" }} title="Invoices Awaiting Processing: Keep track of the number of invoices in your processing queue with this dashboard, allowing you to prioritize your workload and reduce processing times.">
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack
                      sx={{ display: "flex", flexDirection: "row" }}
                      spacing={3}
                    >
                      <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        value={new Date(filterState.analysisDate)}
                        onChange={(e) => dateHandlechange("analysisDate", e)}
                        allowSameDateSelection
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            sx={{ width: "160px !important" }}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider> */}
                  <Box sx={{ mt: 3 }}>
                    {SpinnerToggle || analysisFilterLoading ? (
                      <Box
                        sx={{
                          height: 350,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader />
                      </Box>
                    ) : (
                      <ReactApexChart
                        options={analysisoptions}
                        series={analysisseries}
                        type="donut"
                        height={350}
                      />
                    )}
                  </Box>
                  <Box>
                    <List>
                      <ListItem
                        data-status="Pending"
                        onClick={navigateTOinvoice}
                        secondaryAction={
                          <ArrowForwardIosIcon
                            sx={{
                              border: "1px solid #ec7c37",
                              color: "#ec7c37",
                              width: "30px",
                              height: "30px",
                              padding: "4px",
                              borderRadius: "4px",
                            }}
                          />
                        }
                      >
                        <ListItemAvatar>
                          <AccessTimeIcon
                            sx={{
                              backgroundColor: "#F4B94014 ",
                              color: "#F4B940",
                              width: "35px",
                              height: "35px",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          />
                        </ListItemAvatar>
                        {/* <ListItemText primary={`Pending invoice `} /> */}
                        Pending Invoices:
                        <p style={ptag}>{Pending}</p>
                      </ListItem>
                      <Divider sx={{ mt: 2, mb: 2 }} />
                      <ListItem
                        data-status="ExceptionStatus"
                        onClick={navigateTOinvoice}
                        secondaryAction={
                          <ArrowForwardIosIcon
                            sx={{
                              border: "1px solid #ec7c37",
                              color: "#ec7c37",
                              width: "30px",
                              height: "30px",
                              padding: "4px",
                              borderRadius: "4px",
                            }}
                          />
                        }
                      >
                        <ListItemAvatar>
                          <ReportGmailerrorredIcon
                            sx={{
                              backgroundColor: "#FF751914 ",
                              color: "#FF7519",
                              width: "35px",
                              height: "35px",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          />
                        </ListItemAvatar>
                        Exception Invoices:
                        <p style={ptag}>{ExceptionStatus}</p>
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  align="left"
                  sx={{ mb: 2, fontWeight: "600" }}
                  variant="h5"
                >
                  Approved Invoices
                </Typography>
                <Box
                  sx={{
                    p: 3,
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    boxShadow: "2px 2px 20px #00000012",
                    height: "620px",
                    textAlign:"right"
                  }}
                >
                   <Tooltip sx={{ mt: "0px !important" }} title="Approved Invoices: Monitor the approval status of your invoices with this dashboard, which shows the number of invoices that have been approved or auto approved.">
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack
                      sx={{ display: "flex", flexDirection: "row" }}
                      spacing={3}
                    >
                      <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        value={new Date(filterState.analysisDate)}
                        onChange={(e) => dateHandlechange("analysisDate", e)}
                        allowSameDateSelection
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            sx={{ width: "160px !important" }}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider> */}
                  <Box sx={{ mt: 3 }}>
                    {SpinnerToggle || analysisFilterLoading ? (
                      <Box
                        sx={{
                          height: 350,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader />
                      </Box>
                    ) : (
                      <ReactApexChart
                        options={analysisoptions1}
                        series={analysisseries1}
                        type="donut"
                        height={350}
                      />
                    )}
                  </Box>
                  <Box>
                    <List>
                      <ListItem
                        data-status="Approved"
                        onClick={navigateTOinvoice}
                        secondaryAction={
                          <ArrowForwardIosIcon
                            sx={{
                              border: "1px solid #ec7c37",
                              color: "#ec7c37",
                              width: "30px",
                              height: "30px",
                              padding: "4px",
                              borderRadius: "4px",
                            }}
                          />
                        }
                      >
                        <ListItemAvatar>
                          <CheckIcon
                            sx={{
                              backgroundColor: "#793ed621",
                              color: "#4a9ef1",
                              width: "35px",
                              height: "35px",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          />
                        </ListItemAvatar>
                        {/* <ListItemText primary={`Approved invoice ${Approved}`} /> */}
                        Approved Invoices: <p style={ptag}>{Approved}</p>
                      </ListItem>
                      <Divider sx={{ mt: 2, mb: 2 }} />
                      <ListItem
                        id="AutoApprovel"
                        data-status="Auto Approved"
                        onClick={navigateTOinvoice}
                        secondaryAction={
                          <ArrowForwardIosIcon
                            sx={{
                              border: "1px solid #ec7c37",
                              color: "#ec7c37",
                              width: "30px",
                              height: "30px",
                              padding: "4px",
                              borderRadius: "4px",
                            }}
                          />
                        }
                      >
                        <ListItemAvatar>
                          <AutorenewIcon
                            sx={{
                              background: "#4A9EF114",
                              color: "#4A9EF1",
                              width: "35px",
                              height: "35px",
                              padding: "5px",
                              borderRadius: "4px",
                            }}
                          />
                        </ListItemAvatar>
                        {/* <ListItemText
                      primary={`Autoapproved invoice ${AutoApproved}`}
                    /> */}
                        Auto Approved Invoices:
                        <p style={ptag}>{AutoApproved}</p>
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12} md={12}>
              <Uploadinvoice
                open={inviteSuppliermodal}
                successCall={() => {}}
                handleToggle={createinvoiceopen}
              />
            </Grid>
          </Grid>
        </>
      )}
      {value == 1 &&
        (invoicestatistics &&
          invoicestatistics2 &&
          invoicestatistics3 &&
          invDistribution) && (
          <DashboardDailyReport
            invoicestatistics={invoicestatistics}
            weeklyReport={invoicestatistics2}
            statisticsOfInvoice={invoicestatistics3}
            invDistribution={invDistribution}
          />
        )}
      {value == 2 && (
        <DashboardSupplierStatics
          supplierStatData={supplierStatData}
          supplierStatToggle={supplierStatToggle}
        />
      )}
      {value == 3 && (
        <DashboardTeamStatictics
          teamMemberstat={teamMemberstat}
          invDistribution={invDistribution}
          aprvDistribution={aprvDistribution}
          amount={amount}
          setTeamMemberstat={setTeamMemberstat}
        />
      )}
    </>
  );
}
