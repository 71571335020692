import React, { useState } from "react";
import { Box, Paper, Grid, Button, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import Loader from "../comman/Loader";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";

function DashboardSupplierStatics(props) {
  const { supplierStatData, supplierStatToggle } = props;
  const {
    InvPendingCountBySupplier,
    CurrentInvExceptionBySupplier,
    InvWithExceptionsBySupplier,
    AvgInvProcessingTimeBySupplier,
  } = supplierStatData;
  const [g14name, setg14name] = useState();
  const [g14invcount, setg14invcount] = useState();
  const [g15invcount, setg15invcount] = useState([]);
  const [g15suppliername, setg15suppliername] = useState([]);
  const [g16avgsuppliername, setg16avgsuppliername] = useState([]);
  const [g16invcount, setg16invcount] = useState([]);
  const [g17percentage, setg17percentage] = useState([]);
  const [g17supplierName, setg17supplierName] = useState([]);

  const dashboardSupplier = () => {
    setg14name(
      InvPendingCountBySupplier.filter(
        (x) => x.name !== "N/A" && x.name !== "null"
      ).map((acc) => acc.name.split(/[ ,]+/))
    );
    setg14invcount(
      InvPendingCountBySupplier.filter(
        (x) => x.name !== "N/A" && x.name !== "null"
      ).map((iv) => iv.totalCount)
    );
    let g15invcount_cons =
      CurrentInvExceptionBySupplier &&
      CurrentInvExceptionBySupplier.map((es) => es.invoiceCount);
    setg15invcount(
      CurrentInvExceptionBySupplier &&
        CurrentInvExceptionBySupplier.map((es) => es.totalCount)
    );
    setg15suppliername(
      CurrentInvExceptionBySupplier &&
        CurrentInvExceptionBySupplier.filter(
          (x) => x.name !== "N/A" && x.name !== "null"
        ).map((es) => es.name.split(/[ ,]+/))
    );
    setg16avgsuppliername(
      AvgInvProcessingTimeBySupplier &&
        AvgInvProcessingTimeBySupplier.filter(
          (x) =>
            // x.avgInvProcessingTime !== 0 &&
            x.name !== "N/A" && x.name !== "null" && x.name !== ""
        ).map((avg) => avg.name.split(/[ ,]+/))
    );
    setg16invcount(
      AvgInvProcessingTimeBySupplier &&
        AvgInvProcessingTimeBySupplier.filter(
          (x) =>
            // x.avgInvProcessingTime !== 0 &&
            x.name !== "N/A" && x.name !== null && x.name !== ""
        ).map(
          (avg) => parseFloat(avg.avgInvProcessingTime).toFixed(2)
          // avg.avgInvProcessingTime
        )
    );
    setg17percentage(
      InvWithExceptionsBySupplier.filter(
        (x) => x.name !== "N/A" && x.name !== null
      ).map((exp) => ((exp.ExceptionCount / exp.totalCount) * 100).toFixed(2))
    );

  
    setg17supplierName(
      InvWithExceptionsBySupplier.filter(
        (x) => x.name !== "N/A" && x.name !== null
      ).map((exps) => exps.name.split(/[ ,]+/))
    );
  };
  useEffect(() => {
    if (supplierStatData) {
      dashboardSupplier();
    }
  }, [supplierStatData]);

  var baroptions14 = {
    chart: {
      type: "bar",

      stacked: true,

      toolbar: {
        show: false,
      },
      selection: {
        enabled: false,
      },
    },
    tooltip: {
      // y: { title: { show: false, formatter: () => "" } },
      y: {
        formatter: function (val) {
          return parseInt(val);
        },
        title: {
          show: false,
          formatter: function (val) {
            return parseInt(val);
          },
        },
      },
    },

    dataLabels: {
      enabled: false,
      // textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return val;
      },
      // offsetX: 0,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 5,
      },
    },
    yaxis: {
      // title: {
      //   text: "Supplier Name",
      //   floating: false,
      //   style: {
      //     fontWeight: 600,
      //   },
      // },
      formatter: function (val) {
        return parseInt(val);
      },
    // max: (max) => {
    //   max = Math.max(...series14[0]["data"]);
    // },
    },
    xaxis: {
      tickAmount: 1,
      tickPlacement: "on",
      labels: {
        rotate: 0,
        formatter: function (val) {
            return parseInt(val);
           },
      },
      type: "text",
      categories: g14name,
      title: {
        text: "Number of Invoices",
        style: {
          fontWeight: 600,
        },
        
      },
      // decimalsInFloat: 0,
      // formatter: function (val) {
      //   return parseInt(val);
      // },
      // max: (max) => {
      //   max = Math.max(...series14[0]["data"]);
      //   if (max < 4) {
      //     return "4";
      //   } else {
      //     return max;
      //   }
      //},
      
      // tickAmount: 1,
      forceNiceScale: true,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    fill: {
      opacity: 1,
    },
  };
  var series14 = [
    {
      data: g14invcount,

    },
  ];
  var baroptionsG15 = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,

      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y : {
        formatter: function (val) {
          return parseInt(val);
        },
        title: {
          show: false,
          formatter: function (val) {
            return parseInt(val);
          },
        },
      },
    },

    dataLabels: {
      enabled: true,
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return "";
      },
      offset: 0,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 5,
      },
    },
    xaxis: {
      showDuplicates: false,
      labels: {
        rotate: 0,
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      title: {
        text: "Number of Invoices",
        style: {
          fontWeight: 600,
        },
      },
      type: "category",
      categories: g15suppliername,
    },
    yaxis: {},
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    fill: {
      opacity: 1,
    },
    noData: {
      text: "Currently no invoices with Exception",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
    },
  };
  var seriesG15 = [
    {
      data: g15invcount,
    },
  ];

  var baroptionsG16 = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,

      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: { title: { show: false, formatter: () => "" } },
    },

    dataLabels: {
      enabled: false,
      // textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return val;
      },
      // offsetX: 0,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 5,
      },
    },
    yaxis: {},
    xaxis: {
      labels: {
        rotate: 0,
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      title: {
        text: "Number of Minutes",
        style: {
          fontWeight: 600,
        },
      },

      type: "category",
      categories: g16avgsuppliername,
    },

    fill: {
      opacity: 1,
    },
    stroke: {
      colors: ["transparent"],
      width: 2,
    },
    noData: {
      text: "No invoices",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
    },
  };
  var seriesG16 = [
    {
      data: g16invcount,
    },
  ];
  var baroptionsG17 = {
    chart: {
      type: "bar",
      stacked: true,

      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return val ;
      },
      offsetX: 0,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    tooltip: {
 
     
      y: {
        formatter: function (val) {
          return (val) + "%"
        },
        title: {
          show: false
        }
    },
  },
    //  tooltip: {
    //    y: { title: { show: false, formatter:  function (val) {
    //           return val
    //         },
    //      }
    //     }
    //   },

    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 5,
      },
    },
    stroke: {
      colors: ["transparent"],
      width: 2,
    },
    yaxis: {},
    xaxis: {
      labels: {
        rotate: 0,
      },
      
      
      title: {
        text: "Percentage",
        style: {
          fontWeight: 600,
        },
      },
      max: 100,
      tickAmount: 5,
      type: "category",
      categories: g17supplierName,
    },

    fill: {
      opacity: 1,
    },
    noData: {
      text: "No invoices with exception",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
    },
  };
  var seriesG17 = [
    {
      name: "",
      data: g17percentage,
    },
  ];
  return (
    <Grid id="main_editor_preview" container sx={{ mb: 2 }}>
      <Grid item xs={12} md={12} justifyContent="left">
        <Typography
          align="left"
          variant="h5"
          sx={{ fontWeight: "600", mt: 3.7 }}
        >
          Pending Invoices by Supplier
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={12}
        sx={{
          p: 3,
          mt: 2,
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "2px 2px 9px #0000001c",
          zIndex: "0 !important",
          width: "100%",
          minHeight: "350px",
          maxHeight: "350px",
          overflowY: "auto",
          overflowX: "hidden",
        
        }}
        spacing={2}
      >
        <Box
          sx={{
            height: 300,
          }}
        >
          <Box sx={{
            textAlign: "right",
          }}>

          
          <Tooltip
            sx={{ mt: "0px !important" }}
            title="Pending Invoices by Supplier: This dashboard displays the number of invoices pending for each supplier, allowing you to identify and prioritize your accounts payable workload."
          >
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
          </Box>
          {supplierStatToggle ? (
            <Loader />
          ) : g14name && g14name.length > 0 ? (
            <ReactApexChart
              options={baroptions14}
              series={series14}
              type="bar"
              height={g14name.length <= 4 ? "300" : g14name.length * 60}
            />
          ) : (
            <Box
              sx={{
                height: 300,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>No Pending invoices</div>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={12} justifyContent="left">
        <Typography
          align="left"
          variant="h5"
          sx={{ fontWeight: "600", mt: 3.7 }}
        >
          Exception Invoices by Supplier
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={12}
        sx={{
          p: 3,
          mt: 2,
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "2px 2px 9px #0000001c",
          zIndex: "0 !important",
          width: "100%",
          minHeight: "350px",
          maxHeight: "350px",
          overflowY: "auto",
          overflowX: "hidden",
        
        }}
        spacing={2}
      >
        <Box
          sx={{
            height: 300,
          }}
        >
          <Box sx={{textAlign:"right"}}>
          <Tooltip
            sx={{ mt: "0px !important" }}
            title=" Exception Invoices by Supplier: This dashboard displays the number of current invoices with exceptions by supplier, allowing you to identify and resolve issues in your accounts payable process."
          >
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
          </Box>
          {supplierStatToggle ? (
            <Loader />
          ) : g15suppliername && g15suppliername.length > 0 ? (
            <ReactApexChart
              options={baroptionsG15}
              series={seriesG15}
              type="bar"
              height={
                g15suppliername.length <= 4
                  ? "300"
                  : g15suppliername.length * 60
              }
            />
          ) : (
            <Box
              sx={{
                height: 300,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>Currently no invoices with exception</div>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={12} justifyContent="left">
        <Typography
          align="left"
          variant="h5"
          sx={{ fontWeight: "600", mt: 3.7 }}
        >
          Invoice Processing Time by Supplier

        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={12}
        sx={{
          p: 3,
          mt: 2,
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "2px 2px 9px #0000001c",
          zIndex: "0 !important",
          width: "100%",
          minHeight: "350px",
          maxHeight: "350px",
          overflowY: "auto",
          overflowX: "hidden",
        
        }}
        spacing={2}
      >
        <Box
          sx={{
            height: 300,
          }}
        >
          <Box sx={{textAlign:"right"}}>
          <Tooltip
            sx={{ mt: "0px !important" }}
            title=" Invoice Processing Time by Supplier: This dashboard displays the average amount of time it takes to process invoices by supplier, providing insight into your accounts payable process and highlighting areas for improvement."
          >
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
          </Box>
          {supplierStatToggle ? (
            <Loader />
          ) : g16avgsuppliername && g16avgsuppliername.length > 0 ? (
            <ReactApexChart
              options={baroptionsG16}
              series={seriesG16}
              type="bar"
              height={
                g16avgsuppliername.length <= 4
                  ? "300"
                  : g16avgsuppliername.length * 60
              }
            />
          ) : (
            <Box
              sx={{
                height: 300,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>No invoices yet</div>
            </Box>
          )}
        </Box>
      </Grid>

      <Grid item xs={12} md={12} justifyContent="left">
        <Typography
          align="left"
          variant="h5"
          sx={{ fontWeight: "600", mt: 3.7 }}
        >
         Percentage of Invoices with Exceptions
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={12}
        sx={{
          p: 3,
          mt: 2,
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "2px 2px 9px #0000001c",
          zIndex: "0 !important",
          minHeight: "350px",
          maxHeight: "350px",
          overflowY: "auto",
          overflowX: "hidden",
         
        }}
        spacing={2}
      >
        <Box
          sx={{
            height: 300,
          }}
        >
          <Box sx={{textAlign:"right"}}>
          <Tooltip
            sx={{ mt: "0px !important" }}
            title="Assists organizations in finding consistently problematic suppliers by displaying the total percentage of invoices from each of your vendors that have an exception raised."
          >
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
          </Box>
          {
            supplierStatToggle ? (
              <Loader />
            ) : g17supplierName && g17supplierName.length > 0 ? (
              <ReactApexChart
                options={baroptionsG17}
                series={seriesG17}
                type="bar"
                height={
                  g17supplierName.length <= 4
                    ? "300"
                    : g17supplierName.length * 60
                }
              />
            ) : (
              <Box
                sx={{
                  height: 300,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div>No invoices with exception</div>
              </Box>
            )
            // "test"
          }
        </Box>
      </Grid>
    </Grid>
  );
}

export default DashboardSupplierStatics;
