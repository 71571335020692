import React from "react";
import {
  Route,
  useNavigate,
  Redirect,
  Navigate,
  useLocation,
} from "react-router-dom";
import {
  AUTHENTIFICATION_ROUTE,
  DASHBOARD_ROUTE,
  SIGNIN_ROUTE,
} from "../constants/RoutePaths";
import { getAuthToken, twoFAuth } from "./function";

export function PublicRoute({ children }) {
  let navigate = useNavigate();
  const token = getAuthToken();

  const location = useLocation();
  return !token ? children : <Navigate to={DASHBOARD_ROUTE} />;
}

export function PrivateRoute({ children }) {
  let navigate = useNavigate();
  const token = getAuthToken();
  const location = window.location.href;
  console.log(token,"accesstoken11");
  return token ? children : <Navigate to={SIGNIN_ROUTE} state={location} />;
}
export function authHeader() {
  const token = getAuthToken();
  return {
    Authorization: token,
  };
}
export const getAlertToast = (type = "success", text = "", timer = 5000) => ({
  toast: true,
  position: "bottom",
  titleText: text,
  type: type,
  showConfirmButton: false,
  timer: timer,
});

export const getAlert = (type = "success", text = "") => ({
  position: "center",
  type: type,
  text: text,
  customClass: {
    confirmButton: "btn-shadow btn btn-primary btn-lg",
  },
  buttonsStyling: false,
  confirmButtonColor: "#000",
});
