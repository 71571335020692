import React, { useEffect, useState } from "react";
import {
  Box,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Mainlogo from "../images/Logo_Color1.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { deCryptFun, enCryptFun } from "../comman/function";
import { CREATE_CUSTOMER, IS_NEW_USER_URL } from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import { PASSWORD_REGEX } from "../comman/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  REGISTERED_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_ROUTE,
} from "../constants/RoutePaths";
import App from "../App.css";
import API from "../constants/API";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import PhoneInput from "react-phone-number-input";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const useStyles = makeStyles((theme) => ({
  loginbox: {
    backgroundColor: "#fff",
    padding: "30px 50px",
    borderRadius: "16px",
    textAlign: "left",
    boxShadow: "3px 3px 20px #00000017",
    maxWidth: "600px",
    margin: "auto",
  },
  // signuplabel: {
  //   marginTop: '15px !important',
  // },
}));

const Input = styled("input")({
  display: "none",
});

// const styles={
//   tooltip ={
//     position: relative;
//     display: inline-block;
//     border-bottom: 1px dotted black;
//   }

//   tooltip .tooltiptext= {
//     visibility: hidden;
//     width: 120px;
//     background-color: black;
//     color: #fff;
//     text-align: center;
//     border-radius: 6px;
//     padding: 5px 0;

//     /* Position the tooltip */
//     position: absolute;
//     z-index: 1;
//   }

//   tooltip:hover .tooltiptext= {
//     visibility: visible;
//   }
// }
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

function Signup() {
  const styleclasses = useStyles();
  let history = useNavigate();
  let params = useParams();
  const location = useLocation();
  let mail = "";
  mail = location.search.split("=");
  const { enqueueSnackbar } = useSnackbar();

  var initialValues = {
    firstName: "",
    lastName: "",
    email: mail[1] ? mail[1] : "",
    phoneNumber: "",
    password: "",
    confirmpassword: "",
    companyName: "",
    approvalAmountFrom: 0,
    approvalAmountTo: 0,
    planId: 1,
    userType: mail[1] ? "Supplier" : "",
    twoFactorAuthenticationCode: "",
  };
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [PasswordToggle, setPasswordToggle] = useState(true);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [code, setCode] = useState("");
  const [snum, setSnum] = useState();
  const [disableSignUp, setDisableSignUp] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const NUMBER_REGEX = /^\d+$/;
  const isNewUser = async (mail) => {
    const userConfig = {
      method: "GET",
      url: IS_NEW_USER_URL,
      headers: authHeader(),
      params: {
        // email: mail,
        webString: enCryptFun(
          JSON.stringify({
            email: mail,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(userConfig);
      // const { status, isNewUser = "" } = response.data;
      let l = deCryptFun(response.data);

      const { status, isNewUser = "" } = JSON.parse(l);
      if (status == "Success") {
        if (isNewUser == false && !isMobile) {
          history(SIGNIN_ROUTE);
        }
      }
    } catch (error) {
      // console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        Swal.fire("Error", v.message);
      }
    }
  };
  useEffect(() => {
    if (mail[1]) {
      isNewUser(mail[1]);
    }
  }, []);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required").max("20", "Only 20 characters maximum is allowed in this field!"),
    lastName: Yup.string().required("Required").max("20", "Only 20 characters maximum is allowed in this field!"),
    email: Yup.string().email("Invalid email").required("Required"),
    // phoneNumber: Yup.string().required("Required"),
    // .matches(NUMBER_REGEX, "Phone number is not valid"),
    password: Yup.string()
      .required("Required")
      .matches(
        PASSWORD_REGEX,
        "Invalid password. New password must be 8 characters minimum and contain at least one upper/lowercase letter and one special character."      ),
      // .min("8", "Invalid Password,Password must be longer than 8 characters"),
    companyName: Yup.string().trim().required("Required"),
    userType: Yup.string().required("Required"),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
    // twoFactorAuthenticationCode: Yup.string()
    //   .required("Required")
    //   .matches(NUMBER_REGEX, "2FA Authentication code must be in number")
    //   .min("6", "Please enter the six digit code")
    //   .max("6", "Please enter the six digit code"),
  });
  const {
    handleSubmit,
    setValues,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      ...formValues,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      SuccessRoute(values);
    },
  });
  const errorFun = async (message) => {
    Swal.fire({
      title: "",
      text: message,
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  };
  let SuccessRoute = async (values) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      confirmpassword,
      companyName,
      approvalAmountFrom,
      approvalAmountTo,
      planId,
      userType,
      twoFactorAuthenticationCode,
    } = values;
    let l, coden, number;
    if (phoneNumber) {
      l = formatPhoneNumberIntl(phoneNumber);
      coden = l.split(" ")[0];
      setCode(coden);
      number = phoneNumber.split(coden)[1];
    }

    if (code && snum) {
      if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
        const config = {
          method: "POST",
          url: CREATE_CUSTOMER,
          headers: authHeader(),
          data: {
            // firstName: firstName.trim(),
            // lastName: lastName.trim(),
            // email,
            // phoneNumber: number,
            // password,
            // confirmpassword,
            // companyName,
            // approvalAmountFrom,
            // approvalAmountTo,
            // planId,
            // countryCode: code,
            // invoiceSenderEmail: email,
            // userType,
            webString: enCryptFun(
              JSON.stringify({
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                email,
                phoneNumber: number,
                password,
                confirmpassword,
                companyName,
                approvalAmountFrom,
                approvalAmountTo,
                planId,
                countryCode: code,
                invoiceSenderEmail: email,
                userType,
                twoFactorAuthenticationCode: twoFactorAuthenticationCode,
                registrationType: mail[1] ? "invite" : "open",
              })
            ),
            flutterString: "",
          },
        };
        try {
          setLoading(true);

          const response = await API(config);

          // const { status, message } = response.data;
          let l = deCryptFun(response.data);

          const { status, message } = JSON.parse(l);
          if (status === "Success") {
            setDisableSignUp(true);          //call toster

            mail = "";

            if(userType !== "Supplier"){
              Swal.fire({
                title: "Your account has been created successfully. Please wait for an admin to verify and approve your account.",
                text: "",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  history(SIGNIN_ROUTE);
                }
              });
            }else{
              CustomMessage(
                "Registration completed",
                "success",
                enqueueSnackbar
              ); //call toster       
              history(REGISTERED_ROUTE);
            }
          


            


            //history(REGISTERED_ROUTE);
            // history(SUCCESS_ROUTE);
          } else {
            CustomMessage(
              message,
              // "Registration Completed Successfully",
              "error",
              enqueueSnackbar
            );
          }
        } catch (error) {
          // let errorObj = Object.assign({}, error);
          // let { data } = errorObj.response;
          // errorFun(data.message);
          if (error.response) {
            let { data } = error.response;
            let p = deCryptFun(data);
            let v = JSON.parse(p);
            CustomMessage(v.message, "error", enqueueSnackbar);
          }
          // Swal.fire("Error", data.message);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        CustomMessage(
          "Please fill valid Phone number",
          "error",
          enqueueSnackbar
        );
      }
    } else {
      // CustomMessage(
      //   "Please enter a valid phone number",
      //   "error",
      //   enqueueSnackbar
      // ); //call toster
      const config = {
        method: "POST",
        url: CREATE_CUSTOMER,
        headers: authHeader(),
        data: {
          // firstName: firstName.trim(),
          // lastName: lastName.trim(),
          // email,
          // phoneNumber: code ? number : "",
          // password,
          // confirmpassword,
          // companyName,
          // approvalAmountFrom,
          // approvalAmountTo,
          // planId,
          // countryCode: code ? code : "",
          // invoiceSenderEmail: email,
          // userType,
          webString: enCryptFun(
            JSON.stringify({
              firstName: firstName.trim(),
              lastName: lastName.trim(),
              email,
              phoneNumber: code ? number : "",
              password,
              confirmpassword,
              companyName,
              approvalAmountFrom,
              approvalAmountTo,
              planId,
              countryCode: code ? code : "",
              invoiceSenderEmail: email,
              userType,
              twoFactorAuthenticationCode: twoFactorAuthenticationCode,
              registrationType: mail[1] ? "invite" : "open",
            })
          ),
          flutterString: "",
        },
      };
      try {
        const response = await API(config);
        // const { status, message } = response.data;
        let l = deCryptFun(response.data);
        const { status, message } = JSON.parse(l);
        if (status === "Success") {
          setDisableSignUp(true);
          // CustomMessage(
          //   "Registration Completed Successfully",
          //   "success",
          //   enqueueSnackbar
          // ); //call toster

          mail = "";

          if(userType !== "Supplier"){
              Swal.fire({
                title: "Your account has been created successfully. Please wait for an admin to verify and approve your account.",
                text: "",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  history(SIGNIN_ROUTE);
                }
              });
            }else{
              CustomMessage(
                "Registration completed",
                "success",
                enqueueSnackbar
              ); //call toster       
              history(REGISTERED_ROUTE);
            }

          // history(REGISTERED_ROUTE);
          // history(SIGNUP_ROUTE);
          // history.push(SUCCESS_ROUTE);
        } else {
          Swal.fire("Error", message);
        }
      } catch (error) {
        // let errorObj = Object.assign({}, error);
        // let { data } = errorObj.response;
        // errorFun(data.message);
        if (error.response) {
          let { data } = error.response;

          let p = deCryptFun(data);
          let v = JSON.parse(p);
          Swal.fire("Error", v.message);
        }
        // Swal.fire("Error", data.message);
      } finally {
        setLoading(false);
      }
    }
  };
  // const phoneNumberChange = (value, e) => {
  //   const { dialCode } = e;
  //   setCode(dialCode);
  //   e.target = { name: "", value: "" };
  //   e.target.name = "phoneNumber";
  //   e.target.value = value;
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };
  const phoneNumberChange = (value) => {
    setValues({ ...values, phoneNumber: value });
    if (value) {
      const l = formatPhoneNumberIntl(value);
      const coden = l.split(" ")[0];
      setCode(coden);
      const number = value.split(code)[1];
      setSnum(number);
    }
  };
  let Toogle = () => {
    setPasswordToggle(!PasswordToggle);
  };
  const confirmToggle = () => {
    setConfirmPasswordToggle(!confirmPasswordToggle);
  };
  const handleClickShowPassword = () => {};
  const handleMouseDownPassword = () => {};

  const gotoSigin = () => {
    history(SIGNIN_ROUTE);
  };
  return (
    <>
      <Box
        sx={{ width: "100%", height: "100vh" }}
        container
        justifyContent="center"
        component={"form"}
        onSubmit={handleSubmit}
      >
        <Box>
          <img
            src={Mainlogo}
            style={{ marginTop: "30px", marginBottom: "20px" }}
          ></img>
        </Box>
        <Box className={styleclasses.loginbox}>
          <Typography
            sx={{ mt: 1, mb: 3, fontWeight: "600" }}
            align="center"
            variant="h5"
          >
            Sign up
          </Typography>
          {/* <Typography align="center" paragraph color="gray">
            Please enter 2FA authentication code that you remember
          </Typography> */}
          <Grid container sx={{ mt: 3 }}>
            <Grid sx={{ padding: "10px" }} xs={12} md={6}>
              <Typography
                className={styleclasses.signuplabel}
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                First Name*
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type={"text"}
                  value={values.firstName}
                  error={
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : ""
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="firstName"
                  placeholder="Enter First Name"
                />
                <FormHelperText error={true} data-testid="firstNameRequired">
                  {errors.firstName && touched.firstName
                    ? errors.firstName
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid sx={{ padding: "10px" }} xs={12} md={6}>
              <Typography
                className={styleclasses.signuplabel}
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Last Name*
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName && touched.lastName ? true : false}
                  placeholder="Enter Last Name"
                  name="lastName"
                />
                <FormHelperText error={true} data-testid="lastNameRequired">
                  {errors.lastName && touched.lastName ? errors.lastName : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid sx={{ padding: "10px" }} xs={12} md={6}>
              <Typography
                className={styleclasses.signuplabel}
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Email*
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                disabled={mail[1] ? true : false}
                  type={"text"}
                  value={values.email}
                  error={errors.email && touched.email ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                  placeholder="Enter Email"
                />
                <FormHelperText error={true} data-testid="emailRequired">
                  {errors.email && touched.email ? errors.email : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid sx={{ padding: "10px" }} xs={12} md={6}>
              <Typography
                className={styleclasses.signuplabel}
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Role*
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                {" "}
                <Select
                  disabled={mail[1] ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="userType"
                  error={errors.userType && touched.userType ? true : false}
                  value={mail[1] ? "Supplier" : values.userType}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">----Select Role----</MenuItem>
                  <MenuItem value="Supplier">Supplier / Vendor</MenuItem>
                  <MenuItem value="Customer">Subscriber</MenuItem>
                </Select>
                <FormHelperText error={true} data-testid="roleRequired">
                  {errors.userType && touched.userType ? errors.userType : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid sx={{ padding: "10px" }} xs={12} md={6}>
              <Typography
                className={styleclasses.signuplabel}
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Phone Number
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <PhoneInput
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  value={values.phoneNumber}
                  defaultCountry="US"
                  onChange={phoneNumberChange}
                  international="false"
                  error={
                    values.phoneNumber
                      ? isValidPhoneNumber(values.phoneNumber)
                        ? "correct"
                        : "Invalid phone number"
                      : "Phone number required"
                  }
                />
                <FormHelperText error={true} data-testid="phoneNumberRequired">
                  {errors.phoneNumber && touched.phoneNumber
                    ? errors.phoneNumber
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid sx={{ padding: "10px" }} xs={12} md={6}>
              <Typography
                className={styleclasses.signuplabel}
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Company Name*
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type={"text"}
                  value={values.companyName}
                  onChange={handleChange}
                  name="companyName"
                  onBlur={handleBlur}
                  placeholder="Enter Company Name"
                  error={
                    errors.companyName && touched.companyName ? true : false
                  }
                />
                <FormHelperText error={true} data-testid="companyNameRequired">
                  {errors.companyName && touched.companyName
                    ? errors.companyName
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid sx={{ padding: "10px" }} xs={12} md={6}>
              <Typography
                className={styleclasses.signuplabel}
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Password*
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type={!PasswordToggle ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  onBlur={handleBlur}
                  placeholder="Enter Password"
                  error={errors.password && touched.password ? true : false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={Toogle}
                        edge="end"
                      >
                        {PasswordToggle ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormHelperText error={true} data-testid="passwordRequired">
                {errors.password && touched.password ? errors.password : ""}
              </FormHelperText>
            </Grid>
            <Grid sx={{ padding: "10px" }} xs={12} md={6}>
              <Typography
                className={styleclasses.signuplabel}
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Confirm Password*
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type={!confirmPasswordToggle ? "text" : "password"}
                  value={values.confirmpassword}
                  onChange={handleChange}
                  name="confirmpassword"
                  placeholder="Enter Confirm Password"
                  onBlur={handleBlur}
                  error={
                    errors.confirmpassword && touched.confirmpassword
                      ? true
                      : false
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={confirmToggle}
                        edge="end"
                      >
                        {confirmPasswordToggle ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  error={true}
                  data-testid="confirmPasswordRequired"
                >
                  {errors.confirmpassword && touched.confirmpassword
                    ? errors.confirmpassword
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          {/* <Typography
            gutterBottom
            sx={{ fontWeight: 600, color: "#4E4B66", mt: 3 }}
            // onMouseOver={handleMouseOver}
            // onMouseOut={handleMouseOut}
            className="tooltip"
            style={{ marginBottom: "10px" }}
          >
            2FA Authentification
          </Typography>
          <IconButton
            aria-label="toggle password visibility"
            edge="end"
          ></IconButton>
          <Tooltip
            title="  Please enter 2FA authentication code that you remember"
            placement="bottom-start"
            arrow
            classes={{
              arrow: styleclasses.arrow,
              tooltip: styleclasses.tooltip,
            }}
          >
            <InfoOutlinedIcon
              style={{
                color: "#A0A3BD",
                width: "25px",
                marginBottom: "-8px",
              }}
            />
          </Tooltip>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <OutlinedInput
              type={"text"}
              value={values.twoFactorAuthenticationCode}
              onChange={handleChange}
              name="twoFactorAuthenticationCode"
              onBlur={handleBlur}
              error={
                errors.twoFactorAuthenticationCode &&
                touched.twoFactorAuthenticationCode
                  ? true
                  : false
              }
            />
            <FormHelperText error={true}>
              {errors.twoFactorAuthenticationCode &&
              touched.twoFactorAuthenticationCode
                ? errors.twoFactorAuthenticationCode
                : ""}
            </FormHelperText>
          </FormControl> */}
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Button
              type="submit"
              variant="contained"
              disabled={loading || disableSignUp}
              sx={{ textTransform: "capitalize" }}
              data-testid="SignUp"
            >
              Sign Up
            </Button>
          </Box>
        </Box>
        <Typography sx={{ mt: 2 }}>
          Already have an account?
          <Link
            sx={{ ml: 1, fontWeight: "600", textDecoration: "none" }}
            color="#ec7c37"
            component="button"
            type="button"
            variant="body2"
            onClick={gotoSigin}
            data-testid="SignIn"
          >
            Sign In
          </Link>
        </Typography>
      </Box>
    </>
  );
}

export default Signup;
