import * as React from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  NativeSelect,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import * as yup from "yup";
import { useDateValidation } from "@mui/x-date-pickers/internals/hooks/validation/useDateValidation";

const validationFilterSchema = yup.object().shape({
  name : yup.string().required('*Supplier is Required'),
  value: yup.string().required('*Deviation range is Required'),
  startDateYear: yup.string().required('*Year is Required')
})

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

const optionList = [
  {
    label: "Approved",
    value: "Accepted",
  },
  {
    label: "Invitation Sent",
    value: "Pending",
  },
  {
    label: "Deactivated",
    value: "Deactivated",
  },
];

const deviation = [
  {
    value: 0,
    label: "Show all",
  },
  {
    value: 1,
    label: "Level 1",
  },
  {
    value: 2,
    label: "Level 2",
  },
  {
    value: 3,
    label: "Level 3",
  },
];
function MetricAdvanceInvoiceReceived(props) {
  const styleclasses = useStyles();
  const {
    open,
    handleClose,
    handleFilterSubmit,
    dataParams,
    onResetFilter,
    supplierData,
    setDataParams
  } = props;
   console.log("supplierData",supplierData);
   
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      ...dataParams,
    },
    validationSchema: validationFilterSchema,
    onSubmit: (values) => {
      handleFilterSubmit(values)
  }  });

  const resetField = () => {
    resetForm({});
    onResetFilter();
  };
  const handleToYear = (value) => {
    setValues((val) => ({
      ...val,
      startDateYear: value,
    }));
  };
 

  return (
    <BootstrapDialog
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "75%",
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A0A3BD",
            outline: "1px solid #A0A3BD",
            borderRadius: "50px",
          },
        },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        className={styleclasses.BootstrapDialog}
        sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
      >
        Filters
      </BootstrapDialogTitle>
      <Box component={"form"} onSubmit={handleSubmit}>
        <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Supplier
            </Typography>
            <FormControl sx={{ width: "100%" }} variant="standard" > 
              <NativeSelect
                id="demo-customized-select-native"
                value={values.name}
                onChange={handleChange}
                name="name"
                input={<BootstrapInput />}
              >
                <option aria-label="None" value="">
                  Select
                </option>
                {/* <option value="jeyabaskaranm@apptomate.co">
                  Jeya Baskaran
                </option> */}
                 {/* {optionList.map((i) => {
                    return <option value={i.value}>{i.label}</option>;
                  })} */}
                  {
                  supplierData && supplierData.map((item)=> {
                    return <option value={item.supplierCompanyName}>{item.supplierCompanyName}</option>
})
                  }

                    {/* // const { firstName, lastName, email } = i;
                    // return (
                    //   <option value={email} key={index}>
                    //     {firstName} {lastName}
                    //   </option>
                    // ); */}
                  
              </NativeSelect>
            </FormControl>
            <Typography style={{ color: "red" }}>{errors?.name  && errors?.name}</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Deviation range
            </Typography>
            <FormControl sx={{ width: "100%" }} variant="standard" >
              <NativeSelect
                id="demo-customized-select-native"
                value={values.value}
                onChange={(event)=>{
                  handleChange(event);
                  setDataParams(prev=>({...prev,value:event.target.value}))
                }}
                name="value"
                input={<BootstrapInput />}
              >
                <option aria-label="None" value="">
                  Select
                </option>
                {deviation &&
                  deviation.map((i, index) => {
                    return (
                      <option value={i.value} key={index}>
                        {i.label}
                      </option>
                    );
                  })}
              </NativeSelect>
              <Typography style={{ color: "red",marginTop:"0px " }}>
                {errors.value && touched.value
                  ? errors.value
                  : ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
            <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
              Year
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DatePicker
                  views={["year"]}
                  // label="Year only"
                  // value={value}
                  name="startDateYear"
                  // error={
                  //   errors.startDateYear && touched.startDateYear
                  //     ? errors.startDateYear
                  //     : ""
                  // }
                  value={values.startDateYear ? values.startDateYear : null}
                  // inputFormat="yyyy"
                  onChange={(newValue) => handleToYear(newValue)}
                  renderInput={(params) => <TextField {...params}/>}
                />
             <Typography style={{ color: "red",marginTop:"0px " }}>
                {errors.startDateYear && touched.startDateYear
                  ? errors.startDateYear
                  : ""}
              </Typography>

             {/* <FormHelperText error={true} data-testid="YearRequired"> */}
                  {/* {errors.handleToYear && touched.handleToYear
                    ? errors.handleToYear
                    : ""} */}
                    {/* {touched.startDateYear && errors.startDateYear && <div>{errors.startDateYear}</div>}
                </FormHelperText> */}
              </Stack>
            </LocalizationProvider>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button onClick={resetField} className={styleclasses.canceldialog}>
              Reset
            </Button>
            <Button
              type="submit"
              className={styleclasses.submitdialog}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogActions>
      </Box>
    </BootstrapDialog>
  );
}

export default MetricAdvanceInvoiceReceived;
