import React, { useEffect, useState, useRef } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as LineChartTooltip,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { debounce, indexOf, initial } from "underscore";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../comman/function";
import { CSVLink } from "react-csv";
import { Badge } from "reactstrap";

import { makeStyles } from "@mui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

import { styled } from "@mui/material/styles";
import Advancemetrixfilter from "../components/Modal/Advancemetrixfilter";
import MetricAdvanceInvoiceReceived from "../components/Modal/MetricAdvanceInvoiceReceived";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {
  STANDARD_DEVIATION_URL,SUPPLIER_LISTING
} from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import API from "../constants/API";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "../components/NoRecordFound";
import PagenationPages from "../components/PagenationPages";
import Loader from "../comman/Loader";
import { height } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  bluebox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#3779EC !important",
    borderLeftWidth: "8px  !important",
  },
  greenbox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#0E9F85 !important",
    borderLeftWidth: "8px !important",
  },
  filterby: {
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    padding: "10px 20px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    marginRight: "20px !important",
    width: "110px !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
    "&:disabled": {
      backgroundColor: "#A0A3BD !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  tableContainerbg: {
    background: "white !important",
    overflowX: "auto",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
    borderRadius: "12px !important",
    overflow: "hidden",
  },
  exportcsvmenu: {
    fontSize: "12px !important",
    letterSpacing: "0px !important",
    color: "#A0A3BD !important",
    padding: "5px 14px !important",
  },
  exportgrid: {
    top: "224px",
    right: "20px",
    zIndex: "9",
    position: "absolute",
  },
}));
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),

  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "supplierName",
    numeric: true,
    disablePadding: false,
    label: "Supplier Name",
  },
  // {
  //   id: "totalInvoiceCount",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Invoices Received",
  // },
  {
    id: "invoiceAmount",
    numeric: true,
    disablePadding: false,
    label: "Invoice Amount",
  },
  // {
  //   id: "totalInvoiceCount",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Invoices Received",
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={
              headCell.label !== "Invoice Received" ? "custom-border" : ""
            }
            sx={{ fontWeight: 550 }}
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export const AdvanceInvoiceAmountTable = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  const { invoiceDataList = [], setSupplierData } = props;
  const [loading, setLoading] = useState(false);
  const [filterModal, setfilterModal] = React.useState(false);
  const filterToggle = () => setfilterModal((v) => !v);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  console.log(rowsPerPage,"rowsPerPage");
  const [totalData, setTotalData] = useState([]);
  console.log(totalData,"totalData");
  const [graphData, setGraphData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [otherMeanValues, setOtherMeanValues] = useState([]);
  const [intialPage,setIntialPage] = useState(false)


  const handleRequestSort = (event, property) => {
    console.log(property, "property");
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [showexport, setShowExport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [recordsCsvData, setRecordsCsvData] = useState([]);
  // const [supp, setSupp] = useState("");
  // const [value, setValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openExport = Boolean(anchorEl);
  const [dataParams, setDataParams] = useState({
    count: 10,
    offset: 0,
    name: "",
    supplierName: "",
    deviationRange: "",
    value: "",
    limit: 10,
  });
  // useEffect(()=>{
  //   setDataParams(totalData)
  // },[totalData])
  const [totalrecords, setTotalRecords] = useState(0);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const getDeviationAmount = async (dataParams) => {  
    console.log(dataParams,"dataParams11111");
    const config = {
      method: "GET",
      url: STANDARD_DEVIATION_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            isRange: "",
            ...dataParams,
            supplierName: dataParams.supplierName,
            deviationRange: dataParams.deviationRange,
            count: dataParams.count,
            offset: dataParams.offset,
            limit: dataParams.limit,
          })
        ),
        flutterString: "",
      },
    };

    try {
      setLoading(true);
      const response = await API(config);
      // const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, totalCount } = JSON.parse(l);
      console.log(l,"CHECKDATA");
      console.log(data,"data+++++");
      // setTotalData(
      //   data.map((item) => {
      //     console.log(item, "item323");
      //     return {
      //       ...item,
      //       supplierName: item.supplierName || "N/A",
      //       deviationRange: item.deviationRange,
      //     };
      //   })
      // );
      if (status == "Success") {
        setTotalData(
          data.map((item) => {
            console.log(item, "item323");
            return {
              ...item,
              supplierName: item.supplierName || "N/A",
              deviationRange: item.deviationRange,
            };
          })
        );
          // setTotalRecords(totalData[0]?.invoiceList?.length);
        console.log("invoic", data[0]?.invoiceList?.length);
        let level_1 = data[0].l1+"";
        let level_2 = data[0].l2;
        let level_3 = data[0].l3;
        // const check = level_1.substring(0, level_1.indexOf(","))
        // console.log(check, "CHECKDATAGRAPH");
        console.log(data[0], "level_2");
        const check1 = level_1.substring(
          level_1.indexOf(",") + 1,
          level_1.length
        )
        console.log(check1, "CHECKDATAGRAPH");
        console.log("data[0]",data[0])
        if (Object.values(data)) {
          console.log(level_1, level_2, level_3, "FLOWDATA");
          if (level_1 && level_2 && level_3 ) {
            // setSdev(0);
            setOtherMeanValues([level_1, level_2, level_3])
            console.log(data, "data>>>");
            setGraphData([
              {
                supplier: "",
                mean: "",
              },
              {
                supplier: "-3õ",
                mean: (data && data[0].avarage/100)*2.3,
              },
              {
                supplier: "-2õ",
                // mean:level_2.substring(0, level_2.indexOf(",")),
                mean: (data && data[0].avarage/100)*13.6,
              },
              {
                supplier: "-1õ",
                // mean: level_1.substring(0, level_1.indexOf(",")),
                mean: (data && data[0].avarage/100)*34.1,
              },

              {
                // supplier: "mean",
                supplier: "Mean",
                mean: data && data[0].avarage,
                
              },

              {
                supplier: "1õ",
                // mean: level_1.substring(level_1.indexOf(",")+1,level_1.length),
                mean: (data && data[0].avarage/100)*34.1,
              },
              {
                supplier: "2õ",
                // mean: level_2.substring(level_2.indexOf(",")+1,level_2.length),
                mean: (data && data[0].avarage/100)*13.6,
              },
              {
                supplier: "3õ",
                // mean: level_3.substring(level_3.indexOf(",")+1,level_3.length),
                mean: (data && data[0].avarage/100)*2.3,
              },
            ]);        
           } else {
            if (data[0].l1) {
              const splitedValues = data[0]?.l1?.split(',')
              setOtherMeanValues(splitedValues)
              console.log("inti");
              setGraphData([
                {
                  supplier: "",
                  mean: "",
                },
                {
                  supplier: "-3õ",
                  mean: "",
                },
                {
                  supplier: "-2õ",
                  mean: "",
                },
                {
                  supplier: "-1õ",
                  mean: (data && data[0].avarage/100)*34.1,
                  // label:"Level:-1"
                },

                {
                  supplier: "Mean",
                  mean: data && data[0].avarage,
                  // label:"mean"
                },

                {
                  supplier: "1õ",
                  mean: (data && data[0].avarage/100)*34.1,
                  // label:"Level:1"
                },
                {
                  supplier: "2õ",
                  mean: "",
                },
                {
                  supplier: "3õ",
                  mean: "",
                },
                {
                  supplier: "",
                  mean: "",
                },
              ]);
            }else if (data[0].l2) {
              const splitedValues = data[0]?.l2?.split(',')
              setOtherMeanValues(splitedValues)
              console.log("inti1");
              setGraphData([
                {
                  supplier: "",
                  mean: "",
                },
                {
                  supplier: "-3õ",
                  mean: "",
                },
                {
                  // supplier: level_2.substring(0, level_2.indexOf(",")),
                  supplier: "-2õ",
                  mean: (data && data[0].avarage/100)*13.6,
                  // label:"Level:-2"
                },

                {
                  supplier: "Mean",
                  mean: data && data[0].avarage,
                  // label:'mean:'
                },

                {
                  // supplier: level_2.substring(
                  //   level_2.indexOf(",") + 1,
                  //   level_2.length
                  // ),
                  supplier: "2õ",
                  mean: (data && data[0].avarage/100)*13.6,
                  // label:"Level:2"
                },
                {
                  supplier: "3õ",
                  mean: "",
                },
                {
                  supplier: "",
                  mean: "",
                },
              ]);
            } else if (level_3) {
              const splitedValues = data[0]?.l3?.split(',')
              setOtherMeanValues(splitedValues)
              console.log("inti2");
              setGraphData([
                {
                  supplier: "",
                  mean: "",
                },
                {
                  supplier: "-3õ",
                  mean: (data && data[0].avarage/100)*2.3,
                  // label:"Level:3"
                },

                {
                  supplier: "Mean",
                  mean: data && data[0].avarage,
                  // label:"mean:"
                },

                {
                  supplier: "3õ",
                  mean: (data && data[0].avarage/100)*2.3,
                  // label:"Level:-3"
                },
                {
                  supplier: "",
                  mean: "",
                },
              ]);
            } else {
              // setSdev(0);
              setGraphData([]);
            }
            // setSdev(data[0].avarage);
          }
        }
       
      }
    } catch (error) {
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };
  console.log(totalData, "TOTALDATA");
  let filterInitial = {
    count: 10,
    offset: 0,
    companyName: "",
    // requestedName: "",
    requestStatus: "",
    supplierName: "",
    // supplierCompanyName: "",
  };
  const [supplierFilter, setsupplierFilter] = useState(filterInitial);

  useEffect(()=>{
    setSupplierData(requestData)
  },[requestData])

  const requestListing = async (filtervalues) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: SUPPLIER_LISTING,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() === "Supplier" ? 0 : getTeamID(),
            offset: filtervalues.offset,
            count: filtervalues.count,
            // supplierCompanyName: filtervalues.supplierCompanyName,

            ...filtervalues,
            supplierName:
              getRole() === "Supplier"
                ? getEmail()
                : filtervalues.supplier?.replace("'", "\\'"),
            supplierName: filtervalues.supplierName?.replace(
              "'",
              "\\'"
            ),
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      //const { data, status, count } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, count } = JSON.parse(l);
      console.log("newData??",data);
      setRequestData(
        data.map((item) => {
          return {
            ...item,
            supplierName: item.supplierName || "N/A",
            sentInvite: item.requestStatus,
          };
        })
      );
      setTotalRecords(count);
      console.log("setTotalRecords", count);
    } catch (error) {
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  }; 
  console.log("totalData check", totalData);

  // const delaySearch = useRef(
  //   debounce((dataParams) => getDeviationAmount(dataParams)) 
  // ).current;

  const delaySearch1 = useRef(
    debounce((supplierFilter) => requestListing(supplierFilter), 500)
  ).current;

  // useEffect(() => {
  //   delaySearch(dataParams);
  //   console.log("dataParams GET",dataParams);
  // }, [dataParams]);

  useEffect(() => {
    delaySearch1(supplierFilter);
  }, [supplierFilter]);
    console.log(totalData, "CHECKDATA");
  const headers = [
    { label: "Supplier Name", key: "senderEmail" },
    { label: "Total Invoice Amount", key: "stdDeviationAmount" },
    { label: "Invoice Received", key: "invoiceCount" },
  ];

  const getDownloadFileName = () => {
    return "InvoiceAmountReport";
  };
  const handleFilterSubmit = (filterValues) => {
    const { name, value } = filterValues;
    console.log(filterValues, "filterValues");
    setDataParams((v) => ({
      ...v,
      supplierName: name,
      deviationRange: value,
      offset: dataParams.offset,
      limit:dataParams.limit,
    }));
    setfilterModal(false);
    // getDeviationAmount({supplierName: name,
    //   deviationRange: value,
    //   offset: dataParams.offset,
    //   limit:dataParams.limit})
  };
  const onResetFilter = () => {
    setDataParams((v) => ({
      ...v,
      supplierName: "",
      requestStatus: "",
      offset: 0,
    }));
    setfilterModal(false);
    getDeviationAmount(dataParams)
  };
  const exportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportClose = () => {
    setAnchorEl(null);
  };
  const rowHandle = (e) => {
    setDataParams((v) => ({ ...v, limit: e.target.value}));
    // setRowsPerPage((e) => ({}))
    console.log(e,"event");
    getDeviationAmount(dataParams)
  };
 

  const nextPage = () => {
    setDataParams((va) => ({ ...va, offset: va.offset + 1 }));
    // getDeviationAmount(dataParams)
    setIntialPage(true);

    console.log("step1");
  };
  

  const previousPage = () => {
    setDataParams((va) => ({ ...va, offset: va.offset - 1 }));
    // getDeviationAmount(dataParams)
    setIntialPage(true);
  };

  useEffect(()=>{
    console.log(dataParams);
    if(dataParams.supplierName != ""){
      getDeviationAmount(dataParams)
      console.log("step2");
     
    }
    
  },[dataParams,intialPage])
  const CustomToolTip = (props) =>{
    const {active,level,otherMeanValues,payload} = props
    console.log('props',props);
    if(!level){
      const l1 = otherMeanValues?.length && otherMeanValues[0]?.split(',')
      const l2 = otherMeanValues?.length && otherMeanValues[1]?.split(',')
      const l3 = otherMeanValues?.length && otherMeanValues[2]?.split(',')
      return active && payload?.length  ? <div style={{background:'#EDE4E3',padding:'0.5rem',textAlign:'center'}}>
      {payload?.map((p,i)=>(
        <div key={i} style={{background:'#EDE4E3',padding:'0.5rem',textAlign:'center'}}>
          <p >{p?.payload?.supplier}</p>
          <small>{p?.payload?.supplier === '-3õ' ? <>{parseFloat(l3[0])}</> :<>
          {p?.payload?.supplier === '3õ' ?<>{parseFloat(l3[1])}</> :<>
          {p?.payload?.supplier === '-2õ' ? <>{parseFloat(l2[0])}</> :<>
          {p?.payload?.supplier === '2õ' ?<>{parseFloat(l2[1])}</> : <>
          {p?.payload?.supplier === '-1õ' ? <>{parseFloat(l1[0])}</>:<>
          {p?.payload?.supplier === '1õ' ?<>{parseFloat(l1[1])}</> :<>
          {p?.payload?.mean}</>}
          </>}
          </>}
          </> }
          </>  }
          </>}</small>
        </div>
      ))}
  </div> : null
 } 
   if(level === '1'){
    return active && payload?.length  ? <div style={{background:'#EDE4E3',padding:'0.5rem',textAlign:'center'}}>
        {payload?.map((p,i)=>(
          <div key={i} style={{background:'#EDE4E3',padding:'0.5rem',textAlign:'center'}}>
            <h4 >{p?.payload?.supplier}</h4>
            <small>{p?.payload?.supplier === '-1õ' ? <>{parseFloat(otherMeanValues[0])}</> :<>
            {p?.payload?.supplier === '1õ' ?<>{parseFloat(otherMeanValues[1])}</> :<>{p?.payload?.mean}</>  }
            </>}</small>
          </div>
        ))}
    </div> : null
   } 
   if(level === '2'){
    return active && payload?.length  ? <div style={{background:'#EDE4E3',padding:'0.5rem',textAlign:'center'}}>
        {payload?.map((p,i)=>(
          <div key={i} style={{background:'#EDE4E3',padding:'0.5rem',textAlign:'center'}}>
            <p >{p?.payload?.supplier}</p>
            <p >{p?.payload?.label}</p>
            <small>{p?.payload?.supplier === '-2õ' ? <>{parseFloat(otherMeanValues[0])}</> :<>
            {p?.payload?.supplier === '2õ' ?<>{parseFloat(otherMeanValues[1])}</> :<>{p?.payload?.mean}</>  }
            </>}</small>
          </div>
        ))}
    </div> : null
   } 
   if(level === '3'){
    return active && payload?.length  ? <div style={{background:'#EDE4E3',padding:'0.5rem',textAlign:'center'}}>
      {payload?.map((p,i)=>(
          <div key={i} style={{background:'#EDE4E3',padding:'0.5rem',textAlign:'center'}}>
            <h4 >{p?.payload?.supplier}</h4>
            <small>{p?.payload?.supplier === '-3õ' ? <>{parseFloat(otherMeanValues[0])}</> :<>
            {p?.payload?.supplier === '3õ' ?<>{parseFloat(otherMeanValues[1])}</> :<>{p?.payload?.mean}</>  }
            </>}</small>
          </div>
        ))}
        
    </div> : null
   } 
    return null
  }
  console.log(totalData,"22")
  return (
    <>
      <Box>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ textAlign: "right", alignSelf: "end", mb: 2 }}
          className={styleclasses.exportgrid}
        >
          <Button
            className={styleclasses.notifydownloadbtn}
            variant="contained"
            id="basic-button"
            aria-controls={openExport ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openExport ? "true" : undefined}
            onClick={exportClick}
            disabled={exportLoading || showexport === false || loading}
          >
            Export
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openExport}
            onClose={exportClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <CSVLink
              key={recordsCsvData ? `csv_${recordsCsvData.length}` : ""}
              data={recordsCsvData}
              headers={headers}
              //ref={ref}
              filename={`${getDownloadFileName()}.csv`}
              target="_blank"
            >
              <MenuItem data-flag="csv" className={styleclasses.exportcsvmenu}>
                Export as CSV
              </MenuItem>
            </CSVLink>
          </Menu>
          <Tooltip
            title="Filter by"
            placement="top"
            arrow
            classes={{
              arrow: styleclasses.arrow,
              tooltip: styleclasses.tooltip,
            }}
          >
            <IconButton onClick={filterToggle}>
              <FilterListRoundedIcon className={styleclasses.filtercolor} />
            </IconButton>
          </Tooltip>
          {filterModal && (
            <Advancemetrixfilter
              open={filterModal}
              handleClose={filterToggle}
              tableData={requestData}
              dataParams={dataParams}
              handleFilterSubmit={handleFilterSubmit}
              onResetFilter={onResetFilter}
              setDataParams={setDataParams}
            ></Advancemetrixfilter>
          )}
        </Grid>
        <Box sx={{ width: "100%" }}>
        {/* {(dataParams.supplierName != "")&& */}
        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
        <Grid
          item
          xs={12}
          md={6}
          className={styleclasses.leftside}
          sx={{ padding: "0px 8px 0px 0px " }}
        >
          {totalData.length > 0 ? (
            <> <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography color="#A0A3BD" style={{ fontSize: "18px" }}>
                Cumulative total :
                </Typography>
                <Badge
                  style={{
                    maxWidth: "100px",
                    width: "-webkit-fill-available",
                    marginRight:"350px",

                  }}
                  color={"primary"}
                  
                >
                  {totalData[0]?.invoiceAmount
}
                </Badge>
              </Box>
            <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className={styleclasses.tableContainerbg}>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={requestData.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
             rows.slice().sort(getComparator(order, orderBy)) */}
                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <Loader />
                    </TableCell>
                  </TableRow>
                )}
                {!loading && totalData.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <NoRecordFound />
                    </TableCell>
                  </TableRow>
                )}
                {!loading &&
                          totalData[0]?.deviationRangeInvoice.length == 0 && (
                            <TableRow>
                              <TableCell align="center" colSpan={3}>
                                <NoRecordFound />
                              </TableCell>
                            </TableRow>
                          )}

                {console.log([totalData?.invoiceList], "totalDataaaa")}

                {console.log(
                  totalData.invoiceList?.map((item) => console.log(item)),
                  "mapppp"
                )}
                {!loading && 
                  totalData.map((item, i) => {
                    return (
                      <>
                        {stableSort(item.deviationRangeInvoice, getComparator(order, orderBy)).map((list, index) => {
                          return (
                            <TableRow key={index} hover>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {list.supplierName !== ""?list.supplierName:"-"}
                              </TableCell>
                              {/* <TableCell align="center">
                              {list.totalInvoiceCount !== ""?list.totalInvoiceCount:"-"}
                              </TableCell> */}
                              <TableCell
                                align="center"
                                scope="row"
                                component="th"
                              >
                                {list.invoiceAmount !== ""?list.invoiceAmount:"-"}
                              </TableCell>
                              
                            </TableRow>
                          );
                        })}
                      </>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <PagenationPages
            dataparams={dataParams}
            rowHandle={rowHandle}
            totalRecord={totalData[0]?.deviationRangeInvoiceCount}
            previorsPage={previousPage}
            nextPage={nextPage}
  
          />
            </>
          )
           : (totalData[0]?.deviationRangeInvoice.length === 0) ?(
            <Typography>No Record Found</Typography>
          )
          :
          (
            <Typography>Please select a supplier from the filter menu</Typography>
          )
          
          }
          
          {/* <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className={styleclasses.tableContainerbg}>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={requestData.length}
              />
              <TableBody>
                if you don't need to support IE11, you can replace the `stableSort` call with:
             rows.slice().sort(getComparator(order, orderBy))
                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <Loader />
                    </TableCell>
                  </TableRow>
                )}
                {!loading && totalData.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <NoRecordFound />
                    </TableCell>
                  </TableRow>
                )}

                {console.log([totalData?.invoiceList], "totalDataaaa")}

                {console.log(
                  totalData.invoiceList?.map((item) => console.log(item)),
                  "mapppp"
                )}
                {!loading && 
                  totalData.map((item, i) => {
                    return (
                      <>
                        {stableSort(item.invoiceList, getComparator(order, orderBy)).map((list, index) => {
                          return (
                            <TableRow key={index} hover>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {list.supplierName !== ""?list.supplierName:"-"}
                              </TableCell>
                              <TableCell
                                align="center"
                                scope="row"
                                component="th"
                              >
                                {list.totalInvoiceAmount !== ""?list.totalInvoiceAmount:"-"}
                              </TableCell>
                              <TableCell align="center">
                              {list.totalInvoiceCount !== ""?list.totalInvoiceCount:"-"}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <PagenationPages
            dataparams={dataParams}
            rowHandle={rowHandle}
            totalRecord={totalData[0]?.invoiceList?.length}
            previorsPage={previousPage}
            nextPage={nextPage}
          /> */}
        </Grid>
        <Grid item xs={12} md={6} sx={{ padding: "0px 0px 0px 8px" }}>
          <Grid item xs={12} md={12}>
              {console.log(graphData.length,dataParams[0]?.invoiceList[0]?.supplierName,totalData.length, "graphData??")}
            {totalData[0]?.invoiceList[0]?.supplierName ? (
              totalData.length ? (
                graphData.length ? (
                  <LineChart
                    width={700}
                    height={450}
                    // data={devData}
                    data={graphData}
                    margin={{
                      top: 35,
                      right: 30,
                      left: 10,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    {/* <XAxis dataKey="senderEmail"> */}
                    <XAxis dataKey="supplier">
                      <Label value="" offset={0} position="insideBottom" />
                    </XAxis>
                    <YAxis
                      label={{
                        //value: "Invoice Amount", 
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <LineChartTooltip
                     content={<CustomToolTip otherMeanValues={otherMeanValues} level = {dataParams.value}/>}
                      />
                    <Line
                      type="monotone"
                      // dataKey="stdDeviationAmount"
                      dataKey="mean"
                      stroke="#fd7e14"
                      fill="#fd7e14"
                    />
                  </LineChart>
                ) : (
                  <img
                    src="http://siliconangle.com/files/2013/02/no-data.png"
                    alt="nodata"
                    height="150px"
                    width="150px"
                    className="mt-3"
                  />
                )
              ) : (
                <img
                  src="http://siliconangle.com/files/2013/02/no-data.png"
                  alt="nodata"
                  height="150px"
                  width="150px"
                  className="mt-3"
                />
              )
            ) : null}

          </Grid>
        </Grid>
      </Grid>
        {/* } */}
          
        
        </Box>
      </Box>
    </>
  );
};
