import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import Apptomate from "../images/apptomate.png";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import InvoiceTable from "../Table/InvoiceTable";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PagenationPages from "./PagenationPages";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MyteamTable from "../Table/MyteamTable";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import API from "../constants/API";
import { deCryptFun, enCryptFun, getTeamID } from "../comman/function";
import Swal from "sweetalert2";
import Myteamfilter from "./Modal/Myteamfilter";
import {
  GET_EXCEPTION_HANDLER_ROLE_URL,
  GET_TEAMDETAILS_BY_ID,
  GET_USERS,
} from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import { FormControl, MenuItem, Select } from "@mui/material";
import { debounce } from "underscore";
import Approvedassignment from "./Approvedassignment";
import AddUpdateteam from "./Modal/AddUpdateteam";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A",
  },
  tablerow: {
    background: "black !important",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  antfont: {
    fontSize: "18px !important",
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  pendingbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#EC7C3730 !important",
    color: "#EC7C37 !important",
    boxShadow: "none",
  },
  approvedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#793ED630 !important",
    color: "#793ED6 !important",
    boxShadow: "none",
  },
  autoapprovedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#4A9EF130 !important",
    color: "#4A9EF1 !important",
    boxShadow: "none",
  },
  advancedbutton: {
    textTransform: "none !important",
    boxShadow: "none",
    color: "#14142a50 !important",
    "&:hover": {
      color: "#14142a !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  exportgrid: {
    top: "120px",
    right: "20px",
    zIndex: "9",
    position: "absolute",
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  filterby: {
    // backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
}));
const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#4A9EF1",
  },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#A0A3BD",

    fontSize: "14px !important",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#14142A",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));
const initialdata = {
  offset: 0,

  count: 10,
};

export default function MyTeam(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = props;
  const styleclasses = useStyles();
  const [value, setValue] = React.useState("1");
  const [teamList, setteamList] = useState([]);
  const [totalRecord, settotalRecord] = useState(0);
  const [teamDetails, setTeamDetails] = useState({});

  const [exHandlerRole, SetExHandlerRole] = useState([]);
  const [filterModal, setfilterModal] = React.useState(false);
  const filterToggle = () => setfilterModal((v) => !v);
  const [addmodalOpne, setaddmodalOpne] = useState(false);
  const addToggleClose = () => setaddmodalOpne((v) => !v);
  const [dataparams, setdataParams] = useState(initialdata);
  const TeamReducer = useSelector((s) => s.TeamReducer);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let GetMember = async (filterparams) => {
    const config = {
      method: "GET",
      url: GET_USERS,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            // userRole: "Team Member",
            ...filterparams,
            email: filterparams.email?.replace("'", "\\'"),
            firstName: filterparams.firstName?.replace("'", "\\'"),
            lastName: filterparams.lastName?.replace("'", "\\'"),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);

      let l = deCryptFun(response.data);

      const { status, data, count } = JSON.parse(l);
      // const { status, data, count } = response.data;
      if (status === "Success") {
        setteamList(
          data.map((item) => {
            return {
              ...item,
              position: item.position || "-",
            };
          })
        );
        settotalRecord(count);
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        // Swal.fire("Error", parsedErr.message);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      //  setLoading(false);
    }
  };
  const getTeamDetailsById = async () => {
    const config = {
      method: "GET",
      url: GET_TEAMDETAILS_BY_ID,
      headers: authHeader(),
      params: {
        // teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { status, data: initialResponse = [] } = response.data;
      let l = deCryptFun(response.data);
      const { status, data: initialResponse = [] } = JSON.parse(l);
      if (status === "Success") {
        setTeamDetails(initialResponse[0]);
      }
    } catch (error) {
      // Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;
        let decryptErr = deCryptFun(data);
        let parsedErr = JSON.parse(decryptErr);
        // Swal.fire("Error", parsedErr.message);
        CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
    }
  };
  const nextPage = () => {
    setdataParams((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previorsPage = () => {
    setdataParams((va) => ({ ...va, offset: va.offset - 1 }));
  };
  const rowHandle = (ve) => {
    setdataParams((v) => ({ ...v, count: ve.target.value, offset: 0 }));
  };

  const delaySearch = useRef(
    debounce((dataparams) => GetMember(dataparams))
  ).current;
  const getExceptionHandlerRoles = async () => {
    const config = {
      method: "GET",
      url: GET_EXCEPTION_HANDLER_ROLE_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            role: "role",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let response_decrypt = deCryptFun(response.data);
      const { data, status } = JSON.parse(response_decrypt);

      if (status == "Success") {
        SetExHandlerRole(data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getExceptionHandlerRoles();
    getTeamDetailsById();
  }, []);
  useEffect(() => {
    delaySearch(dataparams);
  }, [dataparams]);
  const successCallBack = () => {
    addToggleClose();
    delaySearch(dataparams);
  };
  const successCall = () => {
    delaySearch(dataparams);
  };

  const TeamfilterHandler = (filterData) => {
    setdataParams((value) => ({
      ...value,
      ...filterData,
    }));
  };

  return (
    <>
      <Grid sx={{ pt: 2 }} container spacing={2}>
        <Grid justifyContent="left" item xs={12} md={6}>
          <Typography align="left" sx={{ fontWeight: 500, fontSize: "34px" }}>
            My Team
          </Typography>
        </Grid>
        <Grid sx={{ paddingTop: "0 !important" }} item xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            <img src={TeamReducer.companyLogo} className="companyLogo"></img>
          </Stack>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12} sx={{ pt: 3 }} container spacing={2}>
        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
          <Grid item xs={12} md={12} lg={12} sx={{ display: "flex" }}>
            <TabContext value={value}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ bgcolor: "none" }}>
                  <Grid item xs={12} md={6} lg={6} sx={{ display: "flex" }}>
                    <Box sx={{ bgcolor: "none" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <AntTab
                          value="1"
                          label="Accounts Payable Team"
                          className={styleclasses.antfont}
                          style={{
                            fontWeight: 500,
                          }}
                        />
                        <AntTab
                          value="2"
                          label="Team and Approval Limits"
                          className={styleclasses.antfont}
                          style={{
                            fontWeight: 500,
                          }}
                        />
                      </TabList>
                    </Box>
                  </Grid>
                  <TabPanel value="1" sx={{ padding: "24px 0px 24px 0px" }}>
                    <Box sx={{ width: "100%" }}>
                      <Grid item xs={12} md={12}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{ textAlign: "right", "margin-left": "auto" }}
                          className={styleclasses.exportgrid}
                        >
                          <Tooltip
                            title="Add User"
                            placement="top"
                            arrow
                            classes={{
                              arrow: styleclasses.arrow,
                              tooltip: styleclasses.tooltip,
                            }}
                          >
                            <Button
                              onClick={addToggleClose}
                              className={styleclasses.notifydownloadbtn}
                              variant="contained"
                            >
                              Add User
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Filter by"
                            placement="top"
                            arrow
                            classes={{
                              arrow: styleclasses.arrow,
                              tooltip: styleclasses.tooltip,
                            }}
                          >
                            <IconButton onClick={filterToggle}>
                              <FilterListRoundedIcon
                                className={styleclasses.filtercolor}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>

                        <Grid sx={{ mt: 1 }} container spacing={2}>
                          <Grid xs={12} md={12}>
                            <MyteamTable
                              teamList={teamList}
                              successCall={successCall}
                              teamDetails={teamDetails}
                              exHandlerRole={exHandlerRole}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <PagenationPages
                        dataparams={dataparams}
                        rowHandle={rowHandle}
                        totalRecord={totalRecord}
                        previorsPage={previorsPage}
                        nextPage={nextPage}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel
                    value="2"
                    sx={{ padding: "24px 0px 24px 24px", mt: 3 }}
                  >
                    <Approvedassignment totalRecord={totalRecord} />
                  </TabPanel>
                </Box>
              </Box>
            </TabContext>
          </Grid>
        </Grid>

        <Myteamfilter
          open={filterModal}
          handleClose={filterToggle}
          filterparams={dataparams}
          TeamfilterHandler={TeamfilterHandler}
        />
        <AddUpdateteam
          addToggleClose={addToggleClose}
          open={addmodalOpne}
          exHandlerRole={exHandlerRole}
          initialData={{}}
          successCallBack={successCallBack}
          teamDetails={teamDetails}
        />
      </Grid>
    </>
  );
}

// <Grid sx={{ pt: 3 }} container spacing={2}>
//   <TabContext value={value}>
//     <Grid item xs={12} md={12} sx={{ display: "flex" }}>
//       <Grid item xs={12} md={6} lg={6} sx={{ display: "flex" }}>
//         <Box sx={{ width: "100%" }}>
//           <Box sx={{ bgcolor: "none" }}>
//             <AntTabs
//               value={value}
//               onChange={handleChange}
//               aria-label="ant example"
//             >
//               <AntTab label="Accounts Payable Team" />
//               <AntTab label="Team and Approval Limits" />
//             </AntTabs>
//           </Box>
//         </Box>
//       </Grid>

//     <TabPanel value="1" sx={{ padding: "24px 0px" }}>
//       <Box sx={{ width: "100%" }}>
//         <Grid item xs={12} md={12}>
//           <MyteamTable />
//         </Grid>
//         <Grid item xs={12} md={12} sx={{ display: "flex" }}>
//           <Grid item xs={6} md={6} className={styleclasses.paginationrow}>
//             <Typography>
//               Rows Per Page:
//               <Button
//                 className={styleclasses.rowsbutton}
//                 endIcon={<ArrowDropDownRoundedIcon />}
//               >
//                 10
//               </Button>
//             </Typography>
//           </Grid>
//           <Grid
//             item
//             xs={6}
//             md={6}
//             className={styleclasses.paginationitem}
//           >
//             <Typography className={styleclasses.paginationnum}>
//               1-10 of 203 items
//               <KeyboardArrowLeftRoundedIcon
//                 className={styleclasses.iconpaginationleft}
//               />
//               <ChevronRightRoundedIcon
//                 className={styleclasses.iconpaginationright}
//               />
//             </Typography>
//           </Grid>
//         </Grid>
//       </Box>
//     </TabPanel>
//   </TabContext>
// </Grid>
