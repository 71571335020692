import React, {
  Fragment,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";
import { Button } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import { Navigate, useNavigate } from "react-router-dom";
import {
  DASHBOARD_ROUTE,
  INVOICEPREVIEW_ROUTE,
  NOTIFICATION_ROUTE,
} from "../../constants/RoutePaths";
import { Typography } from "antd";
import { styled } from "@mui/material/styles";
import { authHeader } from "../../comman/mainfunction";
import { GET_NOTIFICATION_URL } from "../../comman/url";
import { deCryptFun, enCryptFun, getUserId } from "../../comman/function";
import API from "../../constants/API";
import moment from "moment";
import Loader from "../../comman/Loader";
import { findLastIndex } from "underscore";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 0,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",
    marginRight: "12px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  smallseperate: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  optiontext: {
    textTransform: "capitalize",
  },
  boxhov: {
    "&:hover": {
      color: "#f0f1f5",
      border: "none",
      backgroundColor: "none",
      fontWeight: "18px",
      fontSize: "large",
    },
  },
  viewbtn: {
    color: "#ec7c37",
    border: "none",
    backgroundColor: "none",
    fontWeight: "18px",
    fontSize: "large",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    "&:hover": {
      color: "#14142A !important",
      border: "#14142A !important",
      backgroundColor: "#00000021 !important",
      fontWeight: "18px",
      fontSize: "large",
      // cursor: "pointer",
    },
  },
  divCon: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flexStart",
    overflow: "hidden"
  },
  downbn: {
    // backgroundColor: "#ec7c37 !important",
    boxShadow: "none !important",
    textTransform: "none !important",
    fontSize: "12px !important",
    border: "1px solid #ec7c37 !important",
    "&:hover": {
      color: "#ec7c37 !important",
      fontSize: "12px !important",
      border: "1px solid #ec7c37 !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
      cursor: "pointer",
    },
  },
}));
const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
export default function NotifyPopup(props) {
  const { openFlag, openList, setFlag } = props;
  const [completList, setCompleteList] = useState();
  const [notcompleteList, setNotCompleteList] = useState();
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(false);
  const [concatString, setConcatString] = useState("");
  const styleclasses = useStyles();
  const history = useNavigate();

  const getNotificationDetails = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: GET_NOTIFICATION_URL,

      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            userId: getUserId(),
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      //  const { status, data } = response.data;
      let l = deCryptFun(response.data);
      const { status, messageCount, readedMessages, unreadedMessages } =
        JSON.parse(l);
      if (status === "Success") {
        setCompleteList(readedMessages);
        setNotCompleteList(unreadedMessages);
        setCount(messageCount[0].totalCount);

        const handleConcatString = (fName, lName, payload) => {
          let tempName = fName + " " + lName;
          let tempPayload = payload;
          if (tempName.length > 15) tempName = tempName.substring(0, 15).concat("....");
          if (payload.length > 20) tempPayload = tempPayload.substring(0, 20).concat("....");
          setConcatString({ name: tempName, payload: tempPayload });
        }
        handleConcatString(unreadedMessages[0].firstName, unreadedMessages[0].lastName, unreadedMessages[0].payload);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNotificationDetails();
  }, []);



  useEffect(() => {
  }, [notcompleteList]);

  const localdata = new Date();
  const dateAsString = localdata.toString();
  const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
  const handleClick = (event) => {
    const invoiceID = event.currentTarget.dataset.id;

    history(`${INVOICEPREVIEW_ROUTE}/${invoiceID}`, {
      state: { locationChange: true },
    });
    openList();
  };
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, onBlur, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  const viewMoreFun = () => {
    openList();
    history(NOTIFICATION_ROUTE);
  };

  return (
    <ClickAwayListener onClickAway={() => setFlag(false)}>
      <div
        className="notpop-screen"
        style={{
          left: "160px",
          top: "210px",
          background: { pointerEvents: "none" },
        }}
        onBlur={openList}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          aria-labelledby="customized-dialog-title"
          onClose={openList}
          className={styleclasses.BootstrapDialog}
          sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
        >
          Notifications
        </BootstrapDialogTitle>
        <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important", height: "300px" }}>
          <Box
            className="chatlist-box"
          >
            <Demo>
              {loading && <Loader />}

              <List hover sx={{ height: "290px", overflow: "scroll" }}>
                {notcompleteList &&
                  notcompleteList.map((rec, i) => {
                    const {
                      profileLogo,
                      createdDate,
                      isRead,
                      userId,
                      firstName,
                      lastName,
                      payload,
                      invoiceId,
                    } = rec;
                    return (
                      <>
                        <Box className={styleclasses.viewbtn}
                          data-id={invoiceId}
                          onClick={(event) => handleClick(event)}>
                          <div className={styleclasses.divCon}>
                            <ListItemAvatar>
                              <Avatar src={profileLogo} className="chatimg" />
                            </ListItemAvatar>
                            <ListItemText
                              style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flexStart", textAlign: "start" }}
                              primary={concatString.name} secondary={concatString.payload} />
                          </div>
                          <Box sx={{ textAlign: "center", display: "flex", alignItems: "center", width: "30%" }}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 600,
                                fontSize: "12px",
                                color: "#2A2562"
                              }} >
                              {moment.parseZone(createdDate).format("hh:mm A")}
                              {/* {moment(createdDate).tz(timezone).format("hh:mm A")} */}
                              {/* {moment
                                    .utc(new Date(createdDate))
                                    .format("HH:mm A")} */}
                            </Typography>
                            <Badge
                              sx={{ ml: 1, color: "#fff", float: "right" }}
                              badgeContent={isRead}
                              color="error"
                            ></Badge>
                          </Box>
                          {/* </ListItem> */}
                        </Box>
                      </>
                    );
                  })}
              </List>
            </Demo>
          </Box>
          <br />
          <div style={{ textAlign: "end" }}>
            <Button
              onClick={viewMoreFun}
              className={styleclasses.viewbtn}
              variant="contained"
            >
              View More
            </Button>
          </div>
        </DialogContent>
      </div>
    </ClickAwayListener >
  );
}
