import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import Apptomate from "../images/apptomate.png";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import InvoiceTable from "../Table/InvoiceTable";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MyteamTable from "../Table/MyteamTable";
import SupplierTable from "../Table/SupplierTable";
import Swal from "sweetalert2";

import PropTypes from "prop-types";

import API from "../constants/API";

import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
  getSenderEmail,
} from "../comman/function";
import { authHeader, getAlertToast, getAlert } from "../comman/mainfunction";
import { SUPPLIER_LISTING, UPDATE_SUPPLIER_STATUS } from "../comman/url";
import InviteSupplierModal from "./Modal/InviteSupplierModal";
import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { debounce } from "underscore";
import Filtersupplier from "./Modal/Filtersupplier";
import MyClientsTable from "../Table/MyClientsTable";
import MyClientsFilter from "./Modal/MyClientsFilter";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import PagenationPages from "./PagenationPages";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablerow: {
    background: "black !important",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  pendingbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#EC7C3730 !important",
    color: "#EC7C37 !important",
    boxShadow: "none",
  },
  approvedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#793ED630 !important",
    color: "#793ED6 !important",
    boxShadow: "none",
  },
  autoapprovedbutton: {
    margin: "0px 16px !important",
    backgroundColor: "#4A9EF130 !important",
    color: "#4A9EF1 !important",
    boxShadow: "none",
  },
  advancedbutton: {
    textTransform: "none !important",
    boxShadow: "none",
    color: "#14142a50 !important",
    "&:hover": {
      color: "#14142a !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  filterby: {
    // backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
}));
const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#4A9EF1",
  },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontSize: "14px !important",
    fontFamily: ["Montserrat"].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#14142A",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
const adminHeadCells = [
  {
    id: "requestedName",
    numeric: true,
    disablePadding: false,
    label: "Supplier Name",
  },
  {
    id: "supplierEmail",
    numeric: true,
    disablePadding: false,
    label: "Supplier Email",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  // {
  //   id: "invitationsent",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Invitation Sent",
  // },
  {
    id: "enable",
    numeric: true,
    disablePadding: false,
    label: "Enable",
  },
];

const supplierHeadCells = [
  {
    id: "companyName",
    numeric: true,
    disablePadding: false,
    label: "Company Name",
  },
  {
    id: "requestedName",
    numeric: true,
    disablePadding: false,
    label: "Subscriber Name",
  },
  {
    id: "supplierEmail",
    numeric: true,
    disablePadding: false,
    label: "Subscriber Email",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();

  const headCells =
    getRole() === "Supplier" ? supplierHeadCells : adminHeadCells;
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className="custom-border"
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
let filterInitial = {
  count: 10,
  offset: 0,
  companyName: "",
  requestStatus: "",
};

export default function MyClients(props) {
  const { enqueueSnackbar } = useSnackbar();
  const TeamReducer = useSelector((s) => s.TeamReducer);
  const { classes } = props;
  const styleclasses = useStyles();
  const [value, setValue] = React.useState(0);
  const [requestData, setRequestData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);
  const [clintFilter, setclintFilter] = useState(filterInitial);
  const [totalRecords, setTotalRecords] = useState(0);
  const [requestedName, setRequestedName] = useState("");

  const [supplierEmail, setSupplierEmail] = useState("");
  const [reqFilterSupplier, setReqFilterSupplier] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setRowsPerPage(event.target.value);
  };

  const requestListing = async (filtervalues) => {
    setLoading(true);

    const config = {
      method: "GET",
      url: SUPPLIER_LISTING,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: ["Supplier", "Sub Supplier"].includes(getRole())
              ? 0
              : getTeamID(),

            // supplierCompanyName: companyName,
            requestedName: requestedName,

            ...filtervalues,
            supplierEmail: ["Supplier", "Sub Supplier"].includes(getRole())
              ? getEmail()
              : supplierEmail,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      //const { data, status, count } = response.data;

      let l = deCryptFun(response.data);

      const { status, data, count } = JSON.parse(l);
      setRequestData(data);
      setTotalRecords(count);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const onHandleCompanyName = (e) => {
    setCurrentPage(0);

    const { value } = e.target;
    setclintFilter((va) => ({ ...va, companyName: value }));
  };

  const delaySearch = useRef(
    debounce((clintFilter) => requestListing(clintFilter), 500)
  ).current;

  useEffect(() => {
    delaySearch(clintFilter);
  }, [clintFilter]);

  const closeReqFilterSupplier = () => {
    setReqFilterSupplier(false);
  };
  const onResetFilterSupplier = () => {
    setclintFilter((va) => ({
      ...va,
      requestStatus: "",
      companyName: "",
      offset: 0,
    }));

    setReqFilterSupplier(false);
  };

  const onFilterSubmit = (values) => {
    const { companyName, requestStatus } = values;
    setclintFilter((va) => ({
      ...va,
      requestStatus: requestStatus,
      companyName,
      offset: 0,
    }));

    setReqFilterSupplier(false);
  };
  const nextPage = () => {
    setclintFilter((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previorsPage = () => {
    setclintFilter((va) => ({ ...va, offset: va.offset - 1 }));
  };
  const rowHandle = (ve) => {
    setclintFilter((v) => ({ ...v, count: ve.target.value, offset: 0 }));
  };

  return (
    <>
      <Grid sx={{ pt: 3 }} container spacing={2}>
        <Grid justifyContent="left" item xs={12} md={6}>
          <Typography align="left" variant="h4">
            My Clients
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            sx={{ alignItems: "center" }}
          >
            <img
              src={TeamReducer.companyLogo}
              alt=""
              className="companyLogo"
            ></img>
          </Stack>
        </Grid>
      </Grid>

      <Grid sx={{ pt: 3 }} container spacing={2}>
        <Grid item xs={12} md={12} sx={{ display: "flex" }}>
          <Grid item xs={12} md={6} lg={6} sx={{ display: "flex" }}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 500,
                borderRadius: "8px",
                boxShadow: "0px 1px 1px #00000029",
              }}
            >
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search company name"
                onChange={onHandleCompanyName}
                value={clintFilter.companyName}
                inputProps={{ "aria-label": "search google maps" }}
              />
              <Button
                className={styleclasses.advancedbutton}
                // endIcon={<ArrowDropDownRoundedIcon />}
              >
                Advanced
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ textAlign: "right" }}>
            <Tooltip
              title="Filter by"
              placement="top"
              arrow
              classes={{
                arrow: styleclasses.arrow,
                tooltip: styleclasses.tooltip,
              }}
            >
              <IconButton onClick={() => setReqFilterSupplier(true)}>
                <FilterListRoundedIcon className={styleclasses.filtercolor} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <MyClientsTable
            tableData={requestData}
            requestListing={requestListing}
            tableLoading={loading}
          />
        </Grid>
        <PagenationPages
          dataparams={clintFilter}
          rowHandle={rowHandle}
          totalRecord={totalRecords}
          previorsPage={previorsPage}
          nextPage={nextPage}
        />
      </Grid>

      {reqFilterSupplier && (
        <MyClientsFilter
          open={reqFilterSupplier}
          addToggleClose={closeReqFilterSupplier}
          onReset={onResetFilterSupplier}
          onFilterSubmit={onFilterSubmit}
          clintFilter={clintFilter}
        />
      )}
    </>
  );
}
