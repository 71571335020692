import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Checkbox,
  FormLabel,
  IconButton,
} from "@mui/material";
import {
  Link,
  useParams,
  useHistory,
  Prompt,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useState } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import {
  NOTIFY_APPROVER_URL,
  SAVE_NOTIFICATION_DETAILS,
  SAVE_AUDIT,
} from "../../comman/url";
import { enCryptFun, getUserId, deCryptFun } from "../../comman/function";
import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import { CustomMessage } from "../../comman/helperfunction";
import API from "../../constants/API";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { getNotificationDetails } from "../../redux/action";
import { useDispatch } from "react-redux";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: "-webkit-fill-available",
  },
  canceldialog: {
    border: "1px solid #2B88F0 !important",
    color: "##2B88F0 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #2B88F0 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #2B88F0 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#0c70df !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  smallseperate: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
}));

let filterInitial = { email: "", firstName: "", lastName: "" };

function NotifyApprover(props) {
  const { open, CloseToggle, teamMemberList, getAudit } = props;
  const styleclasses = useStyles();
  const [state, setState] = useState([]);
  const [userId, setuserId] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  let { invoiceID } = useParams();
  const [isNotify, setIsNotify] = useState(false)
  const dispatch = useDispatch();
  const postData = () => {};
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: {},
    //validationSchema,
    onSubmit: (values) => saveFormValues(values),
  });

  const saveFormValues = async () => {
    const keycongigure = {
      method: "POST",
      url: NOTIFY_APPROVER_URL,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            email: state.toString(),
            hyperLink: window.location.href,
          })
        ),
        flutterString: "",
      },
    };
    try {
      setIsNotify(true)
      const response = await API(keycongigure);
      CustomMessage("An email has been sent to the selected approver(s).", "success", enqueueSnackbar);
      saveNotification();
      //saveAudit();
    } catch (error) {
    } finally {
      CloseToggle();
      setIsNotify(false)
    }
  };

  const saveNotification = async () => {
    const keycongigure = {
      method: "POST",
      url: SAVE_NOTIFICATION_DETAILS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            notificationDetailsId: 0,
            userId: userId.toString(),
            tag: "Notify Approver",
            payload: "You have been assigned to approve this invoice",
            invoiceId: invoiceID,
            isRead: true,
            commentId: 0,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(keycongigure);
      saveAudit();
      dispatch(getNotificationDetails);
    } catch (error) {
    } finally {
      dispatch(getNotificationDetails());
    }
  };
  const saveAudit = async () => {
    const config = {
      method: "PUT",
      url: SAVE_AUDIT,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceID,
            comments: "Notify Approver",
            actionBy: getUserId(),
            isExceptionResolved: false,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      if (message === "Success") {
        getAudit();
      }
    } catch (error) {}
  };

  const { firstName = "", lastName = "", email = "" } = values;

  const [isChecked, setIsChecked] = React.useState(
    teamMemberList.slice().fill(false)
  );

  const toggleCheckboxValue = (index, value, user) => {
    var indexs = state.indexOf(value);
    var indexs2 = userId.indexOf(user);
    if (indexs > -1) {
      //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
      state.splice(indexs, 1);
      userId.splice(indexs2, 1);
    } else {
      setState([...state, value]);
      setuserId([...userId, user]);
    }
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
  };


  return (
    <Dialog
      open={open}
      onClose={CloseToggle}
      PaperProps={{
        sx: {
          maxWidth: "75%",
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A0A3BD",
            outline: "1px solid #A0A3BD",
            borderRadius: "50px",
          },
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">Notify Approvers</DialogTitle>

        {teamMemberList && teamMemberList.length > 0 && (
          <>
            <DialogTitle>List of Approvers</DialogTitle>
            <DialogContent className={styleclasses.dialogpp} maxWidth="xl">
              {teamMemberList.map((item, index) => (
                <>
                  <Grid sx={12} md={12} maxWidth="xl">
                    <FormControl
                      value={item.value}
                      key={index}
                      sx={{ width: "-webkit-fill-available" }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup className="labelnotify">
                        <FormControlLabel
                          sx={{
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                          className="labelnotify"
                          control={
                            <Checkbox
                              key={index}
                              checked={isChecked[index]}
                              onClick={() =>
                                toggleCheckboxValue(
                                  index,
                                  item.email,
                                  item.userId
                                )
                              }
                            />
                          }
                          label={item.userName}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {index === 2 || index === 3}
                </>
              ))}
            </DialogContent>
          </>
        )}
        {teamMemberList.length <= 0 && (
          <DialogTitle>No approvers found</DialogTitle>
        )}

        <DialogActions>
          <DialogActions>
            <Button className={styleclasses.canceldialog} onClick={CloseToggle}>
              Cancel
            </Button>
            <Button type="submit" disabled={isNotify} className={styleclasses.submitdialog}>
              Notify
            </Button>
          </DialogActions>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default NotifyApprover;
