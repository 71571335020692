import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Menu,
  Paper,
} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { debounce, initial } from "underscore";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../comman/function";
import { CSVLink } from "react-csv";
import { makeStyles } from "@mui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

import { styled } from "@mui/material/styles";
import Advancemetrixfilter from "../components/Modal/Advancemetrixfilter";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { REPORT_URL, STANDARD_DEVIATION_URL } from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import API from "../constants/API";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "../components/NoRecordFound";
import MetricInvoiceAddressFilter from "../components/Modal/MetricInvoiceAddressFilter";
import { Badge } from "reactstrap";
import { statusStyleConfig } from "../constants/HelperConstant";
import PagenationPages from "../components/PagenationPages";
import Loader from "../comman/Loader";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  bluebox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#3779EC !important",
    borderLeftWidth: "8px  !important",
  },
  greenbox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#0E9F85 !important",
    borderLeftWidth: "8px !important",
  },
  filterby: {
    // backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    padding: "10px 20px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    marginRight: "20px !important",
    width: "110px !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
    "&:disabled": {
      backgroundColor: "#A0A3BD !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  tableContainerbg: {
    background: "white !important",
    borderRadius: "12px !important",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
  },
  exportcsvmenu: {
    fontSize: "12px !important",
    letterSpacing: "0px !important",
    color: "#A0A3BD !important",
    padding: "5px 14px !important",
  },
}));
// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),

  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "invoiceNumber",
    numeric: true,
    disablePadding: false,
    label: "Invoice Number",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Supplier Name",
  },

  {
    id: "approvedByName",
    numeric: true,
    disablePadding: false,
    label: "Approved by",
  },
  {
    id: "invoiceAmount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "invoiceCurrency",
    numeric: true,
    disablePadding: false,
    label: "Currency",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "isMatchingAddress",
    numeric: true,
    disablePadding: false,
    label: "Matching Address",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className={
              headCell.label !== "Matching Address" ? "custom-border" : ""
            }
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export const InvoiceAddressTable = (props) => {
  const { nameData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  const { invoiceDataList = [] } = props;
  const [loading, setLoading] = useState(false);
  const [filterModal, setfilterModal] = React.useState(false);
  const filterToggle = () => setfilterModal((v) => !v);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const [totalData, setTotalData] = useState([]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [showexport, setShowExport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [recordsCsvData, setRecordsCsvData] = useState([]);
  const [dataParams, setDataParams] = useState({
    offset: 0,
    count: 10,
    selectedDate: "",
    selectedDate1: "",
    selectedStatus: "",
    name: "",
    matchAddress: "",
  });
  const [totalAmount, setAmount] = useState("");
  const [reportData, setReportData] = useState([]);
  const [totalrecords, setTotalRecords] = useState(0);
  const [deviationValue, setDeviaionValue] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openExport = Boolean(anchorEl);
  const [totalAmt, settotalAmt] = useState(0);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const getReport = async (paramValues) => {
    const {
      offset,
      count,
      selectedDate,
      selectedDate1,
      selectedStatus,
      name,
      matchAddress,
    } = paramValues;
    const config = {
      method: "GET",
      url: REPORT_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            count: count,
            offset: offset,
            // status:
            //   selectedStatus === "All" ||
            //   selectedStatus == "Potential Duplicate Invoices"
            //     ? ""
            //     : selectedStatus == "Within 3% of Approval Limit"
            //     ? "Approved"
            //     : selectedStatus == "Beyond due date"
            //     ? "Pending"
            //     : selectedStatus == "Standard Deviation"
            //     ? ""
            //     : selectedStatus,
            status: "Approved",
            createdFromDate:
              selectedDate && selectedStatus != "Beyond due date"
                ? moment(selectedDate).format("YYYY/MM/DD")
                : "",
            createdToDate:
              selectedDate1 && selectedStatus !== "Beyond due date"
                ? moment(selectedDate1).format("YYYY/MM/DD")
                : "",
            dueFromDate:
              selectedDate && selectedStatus === "Beyond due date"
                ? moment(selectedDate).format("YYYY/MM/DD")
                : "",
            dueToDate:
              selectedDate1 && selectedStatus === "Beyond due date"
                ? moment(selectedDate1).format("YYYY/MM/DD")
                : "",
            is3PercentageRange:
              selectedStatus === "Within 3% of Approval Limit" ? "true" : "",
            isDuplicate:
              selectedStatus === "Potential Duplicate Invoices" ? "true" : "",
            standardDeviation:
              selectedStatus === "Standard Deviation" ? "true" : "",
            senderEmail: name,
            isMatching: matchAddress,
          })
        ),
        flutterString: "",
        //isMatching: matchAddress,
      },
    };

    try {
      setLoading(true);

      const response = await API(config);
      // const { status, data, count, invoiceAmount, standardDeviationAmount } =
      //  response.data;
      let l = deCryptFun(response.data);
      const { status, data, count, invoiceAmount, standardDeviationAmount } =
        JSON.parse(l);
      if (status === "Success") {
        setReportData(data);
        setTotalRecords(count);
        setAmount(invoiceAmount);
        setDeviaionValue(standardDeviationAmount);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };
  const delaySearch = useRef(
    debounce((dataParams) => getReport(dataParams))
  ).current;

  useEffect(() => {
    delaySearch(dataParams);
  }, [dataParams]);
  const fetchCsvData = async () => {
    const {
      offset,
      count,
      selectedDate,
      selectedDate1,
      selectedStatus,
      name,
      matchAddress,
    } = dataParams;
    setExportLoading(true);
    const config = {
      method: "GET",
      url: REPORT_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            count: totalrecords,
            offset: offset,

            status: "Approved",
            createdFromDate:
              selectedDate && selectedStatus != "Beyond due date"
                ? moment(selectedDate).format("YYYY/MM/DD")
                : "",
            createdToDate:
              selectedDate1 && selectedStatus !== "Beyond due date"
                ? moment(selectedDate1).format("YYYY/MM/DD")
                : "",
            dueFromDate:
              selectedDate && selectedStatus === "Beyond due date"
                ? moment(selectedDate).format("YYYY/MM/DD")
                : "",
            dueToDate:
              selectedDate1 && selectedStatus === "Beyond due date"
                ? moment(selectedDate1).format("YYYY/MM/DD")
                : "",
            is3PercentageRange:
              selectedStatus === "Within 3% of Approval Limit" ? "true" : "",
            isDuplicate:
              selectedStatus === "Potential Duplicate Invoices" ? "true" : "",
            standardDeviation:
              selectedStatus === "Standard Deviation" ? "true" : "",
            senderEmail: name,
            isMatching: matchAddress,
          })
        ),
        flutterString: "",
        //isMatching: matchAddress,
      },
    };

    try {
      setLoading(true);
      const response = await API(config);
      // const { status, data, count, invoiceAmount, standardDeviationAmount } =
      //  response.data;
      let l = deCryptFun(response.data);
      const { status, data, count, invoiceAmount, standardDeviationAmount } =
        JSON.parse(l);
      if (status === "Success") {
        setShowExport(true);
        setRecordsCsvData(data);
        settotalAmt(invoiceAmount);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
      setExportLoading(false);
    }
  };
  useEffect(() => {
    fetchCsvData();
  }, [totalrecords, setTotalRecords]);
  const headers = [
    { label: "Invoice Number", key: "invoiceNumber" },
    { label: "Supplier Name", key: "name" },
    { label: "Approved by", key: "approvedByName" },
    { label: "Amount", key: "invoiceAmount" },
    { label: "Currency", key: "invoiceCurrency" },
    { label: "Status", key: "status" },
    { label: "Matching Address", key: "isMatchingAddress" },
  ];
  const getDownloadFileName = () => {
    return "InvoiceAddressReport";
  };
  const rowHandle = (pageCount) => {
    setDataParams((v) => ({ ...v, count: pageCount.target.value, offset: 0 }));
  };
  const nextPage = () => {
    setDataParams((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previousPage = () => {
    setDataParams((va) => ({ ...va, offset: va.offset - 1 }));
  };

  const handleFilterSubmit = (filterValues) => {
    const { selectedStatus, name, selectedDate, selectedDate1, matchAddress } =
      filterValues;
    setDataParams((val) => ({
      ...val,
      selectedDate: selectedDate,
      selectedDate1: selectedDate1,
      matchAddress: matchAddress,
      name: name,
      selectedStatus: selectedStatus,
      offset: 0,
    }));
    setfilterModal(false);
  };
  const onResetFilter = () => {
    setfilterModal(false);
  };
  const exportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box>
        <Grid item xs={12} md={12} sx={{ display: "flex", mb: 2 }}>
          <Grid item xs={12} md={5} lg={5} sx={{ display: "flex" }}>
            <Grid item xs={12} sx={{ margin: "8px 8px 16px 0px" }}>
              <Item className={styleclasses.bluebox}>
                <Typography
                  align="left"
                  sx={{
                    pb: 2,
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#14142A",
                  }}
                >
                  Total Invoices
                </Typography>
                <Typography
                  align="left"
                  sx={{ fontWeight: 700, fontSize: "17px", color: "#3779EC" }}
                >
                  {totalrecords}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sx={{ margin: "8px 8px 16px 0px" }}>
              <Item className={styleclasses.greenbox}>
                <Typography
                  align="left"
                  sx={{
                    pb: 2,
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#14142A",
                  }}
                >
                  Invoice Amount
                </Typography>
                <Typography
                  align="left"
                  sx={{ fontWeight: 700, fontSize: "17px", color: "#0E9F85" }}
                >
                  {totalAmt?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                  USD
                </Typography>
              </Item>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={7}
            sx={{ textAlign: "right", alignSelf: "center" }}
          >
            {/* <Tooltip
              title="Export as CSV"
              placement="top"
              arrow
              classes={{
                arrow: styleclasses.arrow,
                tooltip: styleclasses.tooltip,
              }}
            > */}
            <Button
              className={styleclasses.notifydownloadbtn}
              variant="contained"
              id="basic-button"
              aria-controls={openExport ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openExport ? "true" : undefined}
              onClick={exportClick}
              disabled={exportLoading || showexport === false || loading}
            >
              Export
            </Button>
            {/* </Tooltip> */}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openExport}
              onClose={exportClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <CSVLink
                key={recordsCsvData ? `csv_${recordsCsvData.length}` : ""}
                data={recordsCsvData}
                headers={headers}
                //ref={ref}
                filename={`${getDownloadFileName()}.csv`}
                target="_blank"
              >
                <MenuItem
                  data-flag="csv"
                  className={styleclasses.exportcsvmenu}
                >
                  Export as CSV
                </MenuItem>
              </CSVLink>
            </Menu>
            <Tooltip
              title="Filter by"
              placement="top"
              arrow
              classes={{
                arrow: styleclasses.arrow,
                tooltip: styleclasses.tooltip,
              }}
            >
              <IconButton onClick={filterToggle}>
                <FilterListRoundedIcon className={styleclasses.filtercolor} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Box sx={{ width: "100%" }}>
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Grid
              item
              xs={12}
              md={12}
              className={styleclasses.leftside}
              sx={{ padding: "0px 8px 0px 0px " }}
            >
              {/* <Invoiceamounttable /> */}
              <EnhancedTableToolbar numSelected={selected.length} />
              <TableContainer className={styleclasses.tableContainerbg}>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                    {loading && (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Loader />
                        </TableCell>
                      </TableRow>
                    )}
                    {!loading && reportData && reportData.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <NoRecordFound />
                        </TableCell>
                      </TableRow>
                    )}
                    {!loading &&
                      stableSort(reportData, getComparator(order, orderBy)).map(
                        (list, index) => {
                          const {
                            invoiceId,
                            invoiceNumber,
                            senderEmail,
                            supplierCompanyName,
                            name,
                            dueAmount,
                            invoiceAmount,
                            status,
                            approvedByName,
                            isMatchingAddress,
                            invoiceCurrency,
                          } = list;
                          const isItemSelected = isSelected(invoiceId);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleClick(event, senderEmail)
                              }
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={invoiceId}
                              selected={isItemSelected}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {invoiceNumber}
                              </TableCell>
                              <TableCell align="center">{name}</TableCell>
                              <TableCell align="center">
                                {approvedByName}
                              </TableCell>
                              <TableCell align="center">
                                {invoiceAmount}
                              </TableCell>
                              <TableCell align="center">
                                {invoiceCurrency}
                              </TableCell>
                              <TableCell align="center">
                                <Badge color={statusStyleConfig[status]}>
                                  {status}
                                </Badge>
                              </TableCell>
                              <TableCell align="center">
                                <Badge
                                  color={
                                    isMatchingAddress === 0
                                      ? "tertiary"
                                      : isMatchingAddress === 1 && "primary"
                                  }
                                >
                                  {isMatchingAddress === 0
                                    ? "Not Matched"
                                    : isMatchingAddress === 1 && "Matched"}
                                </Badge>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                  </TableBody>
                </Table>
              </TableContainer>{" "}
              <PagenationPages
                dataparams={dataParams}
                rowHandle={rowHandle}
                totalRecord={totalrecords}
                previorsPage={previousPage}
                nextPage={nextPage}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>{" "}
      <MetricInvoiceAddressFilter
        open={filterModal}
        handleClose={filterToggle}
        dataParams={dataParams}
        nameData={nameData}
        handleFilterSubmit={handleFilterSubmit}
        onResetFilter={onResetFilter}
      />
    </>
  );
};
