import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Box,
  Snackbar,
  Alert,
  Autocomplete,
  Stack,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
  getUserId,
} from "../../comman/function";
import API from "../../constants/API";
import { authHeader, getAlertToast, getAlert } from "../../comman/mainfunction";
import { UPDTAE_SUPPLIER_REQUEST_URL } from "../../comman/url";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../../comman/helperfunction";
import Currency from "../../commonCurrency.json";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import VisibilityIcon from "@mui/icons-material/Visibility";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddNewFormValues = ({ AddNewFormValues, open }) => {
  const styleclasses = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [visible, setVisible] = useState(0);
  const [checkbox,setCheckBox] = useState({
     requiredCheck:false,
     exportableCheckbox:false
  })

  // const saveFormValues = async (values) => {
  //   setLoading(true);
  //   const config = {
  //     method: "PUT",
  //     url: UPDTAE_SUPPLIER_REQUEST_URL,
  //     headers: authHeader(),
  //     data: {
  //       // ...values,
  //       // teamId: getTeamID(),
  //       // requestedBy: getUserId(),
  //       webString: enCryptFun(
  //         JSON.stringify({
  //           requestId:requestId,
  //           supplierName: values.supplierName,
  //           supplierCurrency: values.supplierCurrency,
  //           //supplierEmail: values.supplierEmail,
  //           //inviteToPortal: ""
  //         })
  //       ),
  //       flutterString: "",
  //     },
  //   };
  //   try {
  //     const response = await API(config);
  //     //const { status, message } = response.data;
  //     let l = deCryptFun(response.data);

  //     const { status, message } = JSON.parse(l);
  //     if (status === "Success") {
  //       // Swal.fire("Success:", message);
  //       // Swal.fire(getAlertToast("Success", "Request sent Successfully!"));
  //       if (values.supplierEmail !== "") {
  //         CustomMessage(
  //           "Supplier details updated",
  //           "success",
  //           enqueueSnackbar
  //         );
  //       } else {
  //         CustomMessage(
  //           "Supplier details updated",
  //           "success",
  //           enqueueSnackbar
  //         ); //call toster
  //       }
  //     } else {
  //       CustomMessage(message, "error", enqueueSnackbar); //call toster
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       let { data } = error.response;

  //       let p = deCryptFun(data);
  //       let v = JSON.parse(p);

  //       // Swal.fire("Error", v.message);
  //       CustomMessage(v.message, "error", enqueueSnackbar); //call toster
  //     }
  //   } finally {
  //     addToggleClose();
  //     requestListing();
  //     setLoading(false);
  //   }
  // };

  // const { handleSubmit, handleChange, values, errors, touched, setFieldError,setValues } =
  //   useFormik({
  //     initialValues: {
  //       supplierName: selected.supplierCompanyName,
  //       supplierEmail: selected.supplierEmail,
  //       supplierConfirmEmail: selected.supplierEmail,
  //       supplierCurrency: selected.supplierCurrency,
  //     },
  //     validationSchema,
  //     onSubmit: (values) => {
  //       saveFormValues(values);
  //     },
  //   });
const handleChange=(e)=>{
setCheckBox({...checkbox,[e.target.name]:e.target.checked})
}

useEffect(()=>{
    setCheckBox({
        requiredCheck:false,
        exportableCheckbox:false
    })
},[visible])

  return (
    <>
      <BootstrapDialog
        onClose={AddNewFormValues}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={AddNewFormValues}
          className={styleclasses.BootstrapDialog}
          sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
        >
          Add New Field
        </BootstrapDialogTitle>
        <Box component={"form"} /* onSubmit={handleSubmit} */>
          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                New Field Name
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                //   value={values.supplierName}
                //   onChange={handleChange}
                name="supplierName"
                placeholder="Enter New Field Name"
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Grid
                align="right"
                className={styleclasses.tableheadrow}
                sx={{
                  fontWeight: 550,
                  margin: "1rem",
                  display: "flex",
                  width: "50%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  className={styleclasses.tabletitles}
                  sx={{ fontWeight: "1rem", fontWeight: 600, color: "#4E4B66" }}
                >
                  Display
                </Typography>
                <Typography>
                  {visible == 1 ? (
                    <VisibilityIcon
                      onClick={() => setVisible(0)}
                      //   data-id={fieldListId}
                      data-isvisible={0}
                      style={{ color: "#A0A3BD" }}
                    />
                  ) : (
                    <VisibilityOffRoundedIcon
                      onClick={() => setVisible(1)}
                      //   data-id={fieldListId}
                      data-isvisible={1}
                      style={{ color: "#A0A3BD" }}
                    />
                  )}
                </Typography>
              </Grid>
              {/* {visible == 1 ? ( */}
                <>
                  <Grid
                    align="right"
                    className={styleclasses.tableheadrow}
                    sx={{ fontWeight: 550, margin: "1rem" ,display: "flex",
                    justifyContent: "space-between",
                    width: "50%"}}
                  >
                    <Typography
                      className={styleclasses.tabletitles}
                      sx={{
                        fontWeight: "1rem",
                        fontWeight: 600,
                        color: "#4E4B66",
                      }}
                    >
                      Required
                    </Typography>
                    <Typography>
                      <AntSwitch
                        checked={checkbox.requiredCheck}
                        onChange={handleChange}
                        name="requiredCheck"
                        disabled={!visible}
                        inputProps={{
                          "aria-label": "ant design",
                          "data-name": "isRequired",
                        //   "data-id": fieldListId,
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid
                    align="right"
                    className={styleclasses.tableheadrow}
                    sx={{ fontWeight: 550, margin: "1rem" ,display: "flex",
                    justifyContent: "space-between",
                    width: "50%"}}
                  >
                    <Typography
                      className={styleclasses.tabletitles}
                      
                      sx={{
                        fontWeight: "1rem",
                        fontWeight: 600,
                        color: "#4E4B66",
                      }}
                    >
                      Exportable
                    </Typography>
                    <Typography>
                      <AntSwitch
                         name="exportableCheckbox"
                        onChange={handleChange}
                        disabled={!visible}
                        checked={checkbox.exportableCheckbox}
                        inputProps={{
                          "aria-label": "ant design",
                          "data-name": "isRequired",
                        //   "data-id": fieldListId,
                        }}
                      />
                    </Typography>
                  </Grid>
                </>
              {/* ) : (
                "" */}
              {/* )} */}
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogActions>
              <Button
                onClick={AddNewFormValues}
                className={styleclasses.canceldialog}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className={styleclasses.submitdialog}
                //   onClick={handleSubmit}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </>
  );
};

export default AddNewFormValues;
