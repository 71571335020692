import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Tooltip from "@mui/material/Tooltip";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormFeedback } from "reactstrap";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 600,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",
    marginRight: "12px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  smallseperate: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  optiontext: {
    textTransform: "capitalize",
  },
}));

const CssTextField = styled(TextField)({
  color: "red",
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "green!important", // (default alpha is 0.38)
    },
    ".MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000",
      color: "#000",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const followersMarks = [
  {
    value: 0,
    scaledValue: 0,
    label: "1k",
  },
  {
    value: 25,
    scaledValue: 5000,
    label: "5k",
  },
  {
    value: 50,
    scaledValue: 10000,
    label: "10k",
  },
  {
    value: 75,
    scaledValue: 25000,
    label: "25k",
  },
  {
    value: 100,
    scaledValue: 50000,
    label: "50k",
  },
  {
    value: 125,
    scaledValue: 100000,
    label: "100k",
  },
  {
    value: 150,
    scaledValue: 250000,
    label: "250k",
  },
  {
    value: 175,
    scaledValue: 500000,
    label: "500k",
  },
  {
    value: 200,
    scaledValue: 1000000,
    label: "1M",
  },
];

const scaleValues = (valueArray) => {
  return [scale(valueArray[0]), scale(valueArray[1])];
};
const scale = (value) => {
  if (value === undefined) {
    return undefined;
  }
  const previousMarkIndex = Math.floor(value / 25);
  const previousMark = followersMarks[previousMarkIndex];
  const remainder = value % 25;
  if (remainder === 0) {
    return previousMark.scaledValue;
  }
  const nextMark = followersMarks[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
  return remainder * increment + previousMark.scaledValue;
};

function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",
    textTransform: "capitalize",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

// let filterInitial = {
//   name: "",
//   companyName: "",
//   senderEmail: "",
//   status: "",
//   offset: 0,
//   fromAmount: "",
//   toAmount: "",
//   receiverEmail: "",
// };

function valuetext(value) {
  return `${value}`;
}

function InvoiceFilter(props) {
  const {
    openModal,
    closeModal,
    filterHandler,
    dataparams,
    role,
    emailSender,
  } = props;

  let filterInitial = {
    name: "",
    companyName: "",
    senderEmail: role == "Supplier" ? emailSender : "",
    status: "",
    source: "",
    offset: 0,
    fromAmount: "",
    toAmount: "",
    receiverEmail: "",
    invCreatedDate: "",
  };
    const styleclasses = useStyles();
  const [valueamt, setValueamt] = React.useState([20, 10000]);
  const [value, setValue] = React.useState([0, 200]);

  const handleChangeAmount = (event, newValue) => {
    setValue(newValue);
  };

  const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: "#EC7C37",
    height: 3,
    padding: "13px 0",
    "& .MuiSlider-thumb": {
      height: 27,
      width: 27,
      border: "1px solid #EC7C37",
      "&:hover": {
        boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
      },
      "& .airbnb-bar": {
        height: 9,
        width: 1,
        backgroundColor: "#EC7C37",
        marginLeft: 1,
        marginRight: 1,
      },
    },
    "& .MuiSlider-track": {
      height: 3,
    },
    "& .MuiSlider-rail": {
      color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
      opacity: theme.palette.mode === "dark" ? undefined : 1,
      height: 3,
    },
  }));

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
      </SliderThumb>
    );
  }

  const postData = () => {};
  const validationSchema = Yup.object({
    toAmount: Yup.number()
      .positive()
      .when("fromAmount", {
        is: (val) => val != "",
        then: Yup.number().required().typeError("required"),
      }),
  });
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    setValues,
    setErrors,
    setFieldError,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...filterInitial,
      ...dataparams,
    },
    // validationSchema,
    onSubmit: (values) => postToHandler(values),
  });
 
  const {
    name = "",
    companyName = "",
    senderEmail = role == "Supplier" ? emailSender : "",
    status = "",
    source = "",
    offset = 0,
    fromAmount = "",
    toAmount = "",
    receiverEmail = "",
  } = values;

  var valueofamount = JSON.stringify(scaleValues(value));
  const obj = JSON.parse(valueofamount);

  const postToHandler = (values) => {
    if (values.fromAmount) {
      if (values.toAmount === "") {
        setFieldError("toAmount", "Field is required ");
        return null;

      }
      if  ((+values.fromAmount) >= (+values.toAmount) ) {
        setFieldError(
          "toAmount",
          "To amount should be greater than from amount "
        );
        return null;

      }
    }
    if (values.toAmount) {
      if (values.fromAmount === "") {
        setFieldError("fromAmount", "Field is required ");
        return null;
      }
      // if (values.fromAmount == 0) {
      //   setFieldError("fromAmount", "From Amount Invalid,Please enter valid Amount");
      //   return null;
      // }
    }

    {
      role == "Supplier" &&
        setValues((va) => ({ ...va, senderEmail: receiverEmail }));
    }

    var newValues = {
      name: values.name,
      companyName: values.companyName,
      senderEmail: role == "Supplier" ? emailSender : "",
      status: values.status,
      source: values.source,
      offset: values.offset,
      fromAmount: values.fromAmount,
      toAmount: values.toAmount,
      // "toAmount": obj[1],
      invoiceAmount: values.invoiceAmount,
      supplierCompanyName: values.supplierCompanyName,
      invoiceNumber: values.invoiceNumber,
      count: values.count,
      email: values.email,
      receiverEmail: values.receiverEmail,
      invCreatedDate: "",
    };
    filterHandler(newValues);
    closeModal();
  };

  const handleChangeFun = (e) => {
    const approvalRegex = /^\d{0,}(\.\d{0,4})?$/;

     
    if (approvalRegex.test(e.target.value)) {
      handleChange(e);
    }
  };

  const resetField = () => {
    filterHandler(filterInitial);
    closeModal();
  };
  let beforeChange = null;

  const handleChange1 = (event, newValue) => {
    if (!beforeChange) {
      beforeChange = [...valueamt];
    }

    if (beforeChange[0] !== newValue[0] && beforeChange[1] !== newValue[1]) {
      return;
    }

    setValueamt(newValue);
  };

  const handleChangeCommitted = () => {
    beforeChange = null;
  };


  return (
    <>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={openModal}
        PaperProps={{
          sx: {
            maxWidth: "75%",
            "&::-webkit-scrollbar": {
              width: "0.5em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#A0A3BD",
              outline: "1px solid #A0A3BD",
              borderRadius: "50px",
            },
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={closeModal}
            className={styleclasses.BootstrapDialog}
            sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
          >
            Filters
          </BootstrapDialogTitle>

          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            {role != "Supplier" ? (
              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Supplier
                </Typography>
                <CssTextField
                  name="name"
                  value={name}
                  onChange={handleChange}
                  fullWidth
                  id="custom-css-outlined-input"
                />
              </Grid>
            ) : (
              ""
            )}
            {/* <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Company Name
              </Typography>
              <CssTextField
                name="companyName"
                value={companyName}
                onChange={handleChange}
                fullWidth
                id="custom-css-outlined-input"
              />
            </Grid> */}
            <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Status
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <NativeSelect
                  style={{ textTransform: "capitalize" }}
                  id="demo-customized-select-native"
                  name="status"
                  value={status}
                  className={styleclasses.optiontext}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                >
                  {/* <option aria-label="None" value="" /> */}
                  <option value={""}>All</option>
                  {[
                    "Approved",
                    "Pending",
                    "Auto Approved",
                    // "Validation Failure",
                    "Rejected",
                    "Initializing",
                    "Reprocessing",
                  ].map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Invoice Source
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <NativeSelect
                  style={{ textTransform: "capitalize" }}
                  id="demo-customized-select-native"
                  name="source"
                  value={source}
                  className={styleclasses.optiontext}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                >
                  <option value={""}>All</option>
                  {[, "Web", "App"].map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid> */}
            {/*
            {role == "Supplier" ? (
              <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Email
                </Typography>
                <CssTextField
                  name="receiverEmail"
                  value={receiverEmail}
                  onChange={handleChange}
                  fullWidth
                  id="custom-css-outlined-input"
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Email
                </Typography>
                <CssTextField
                  name="senderEmail"
                  value={senderEmail}
                  onChange={handleChange}
                  fullWidth
                  id="custom-css-outlined-input"
                />
              </Grid>
            )}
            */}

            <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Amount
              </Typography>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ pt: 1, display: "flex" }}
              >
                <Grid item xs={6} md={6} lg={6}>
                  <CssTextField
                    type="text"
                    step={1}
                    name="fromAmount"
                    value={fromAmount}
                    onChange={handleChangeFun}
                    fullWidth
                    id="custom-css-outlined-input"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                  />
                  <FormFeedback style={{ color: "red" }}>{errors.fromAmount}</FormFeedback>
                </Grid>

                <Grid
                  item
                  xs={1}
                  md={1}
                  lg={1}
                  className={styleclasses.smallseperate}
                >
                  <RemoveRoundedIcon sx={{ color: "#A0A3BD", width: "15px" }} />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <CssTextField
                    style={{ color: "red !important" }}
                    type="text"
                    name="toAmount"
                    value={toAmount}
                    // disabled={fromAmount === ""}
                    onChange={handleChangeFun}
                    fullWidth
                    id="custom-css-outlined-input"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                  />
                  <FormFeedback style={{ color: "red" }}>{errors.toAmount}</FormFeedback>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
                {/* <AirbnbSlider
                  components={{ Thumb: AirbnbThumbComponent }}
                  getAriaLabel={(index) =>
                    index === 0 ? "Minimum price" : "Maximum price"
                  }
                  //       min={1}
                  // max={100}
                  // values={[1, 100]}
                  //       defaultValue={[20, 40]}
                  value={valueamt}
                  onChange={handleChange1}
                  onChangeCommitted={handleChangeCommitted}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={(index) =>
                    index === 0 ? "Minimum price" : "Maximum price"
                  }
                />*/}
                {/* <Slider
        style={{ maxWidth: "543px",color:"#EC7C37" ,left:"4px"}}
        value={value}
        min={0}
        step={1}
        max={200}
        valueLabelFormat={numFormatter}
        //marks={followersMarks}
       // getAriaValueText={valuetext}
        scale={scaleValues}
        onChange={handleChangeAmount}
        getAriaLabel={() => 'Temperature range'}
        // valueLabelDisplay="auto"
        //aria-labelledby="input-slider"
        aria-labelledby="non-linear-slider"
      /> */}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: "8px 16px 16px 16px" }}>
            <Button onClick={resetField} className={styleclasses.canceldialog}>
              Reset
            </Button>
            <Button type="submit" className={styleclasses.submitdialog}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
}

export default InvoiceFilter;
