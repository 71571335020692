import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Divider,
  MenuItem,
  Select,
  FormControl,
  Menu,
} from "@mui/material";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { debounce, initial } from "underscore";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
  getUserId,
} from "../comman/function";
import { CSVLink } from "react-csv";
import { makeStyles } from "@mui/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";

import { styled } from "@mui/material/styles";
import Advancemetrixfilter from "../components/Modal/Advancemetrixfilter";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import {
  ROUNDED_INVOICES_URL,
  STANDARD_DEVIATION_URL,
  USER_EMAIL_LIST_URL,
  SUPPLIER_LISTING,
} from "../comman/url";
import { authHeader } from "../comman/mainfunction";
import API from "../constants/API";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import { NoRecordFound } from "../components/NoRecordFound";
import moment from "moment";
import MetricInvoiceRounded from "../components/Modal/MetricInvoiceRoundedAmount";
import PagenationPages from "../components/PagenationPages";
import { Badge } from "reactstrap";
import { statusStyleConfig } from "../constants/HelperConstant";
import Loader from "../comman/Loader";

const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: 20,
    "&:before": {
      border: "1px solid #E6E8ED",
    },
    color: "white !important",
  },
  tooltip: {
    fontSize: "14px",
    boxShadow: "2px 2px 1px #0000001c",
    backgroundColor: "white !important",
    border: "1px solid #E6E8ED",
    color: "#4A4A4A !important",
  },
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  bluebox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#3779EC !important",
    borderLeftWidth: "8px  !important",
  },
  greenbox: {
    borderLeftStyle: "solid !important",
    borderLeftColor: "#0E9F85 !important",
    borderLeftWidth: "8px !important",
  },
  filterby: {
    // backgroundColor: "white !important",
    color: "#14142A !important",
    padding: "4px !important",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 8px !important",
    padding: "10px 20px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    marginRight: "20px !important",
    width: "110px !important",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
    "&:disabled": {
      backgroundColor: "#A0A3BD !important",
    },
  },
  filtercolor: {
    width: "30px !important",
    height: "30px !important",
    color: "#14142A !important",
    paddingTop: "0px !important",
  },
  rowsbutton: {
    margin: "0px 16px !important",
    backgroundColor: "White",
    color: "#14142A !important",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#EC7C37 !important",
      color: "white !important",
    },
  },
  iconpaginationleft: {
    margin: "0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  iconpaginationright: {
    margin: "0px 0px 0px 8px",
    color: "#EC7C37 !important",
    "&:hover": {
      color: "#14142A !important",
    },
  },
  tableContainerbg: {
    background: "white !important",
    borderRadius: "12px !important",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    marginBottom: "16px !important",
  },
  exportcsvmenu: {
    fontSize: "12px !important",
    letterSpacing: "0px !important",
    color: "#A0A3BD !important",
    padding: "5px 14px !important",
  },
}));
// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }));
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),

  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  //   let avalue = a[orderBy];
  //   let bvalue = b[orderBy];
  //   if (orderBy == "invoiceAmount") {
  //     avalue = parseInt(a[orderBy]);
  //     bvalue = parseInt(b[orderBy]);
  //   }
  //   if (bvalue < avalue) {
  //     return -1;
  //   }
  //   if (bvalue > avalue) {
  //     return 1;
  //   }
  //   return 0;
  // }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "invoiceNumber",
    numeric: true,
    disablePadding: false,
    label: "Invoice Number",
  },
  {
    id: "supplierName",
    numeric: true,
    disablePadding: false,
    label: "Supplier Name",
  },

  // {
  //   id: "companyName",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Company",
  // },
  {
    id: "invoiceAmount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "invoiceCurrency",
    numeric: true,
    disablePadding: false,
    label: "Currency",
  },
  {
    id: "isRoundedAmount",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    console.log(createSortHandler, "ll");
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            className={headCell.label !== "Status" ? "custom-border" : ""}
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export const InvoiceRoundedAmountTable = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const styleclasses = useStyles();
  const { invoiceDataList = [], nameData } = props;
  const [loading, setLoading] = useState(false);
  const [filterModal, setfilterModal] = React.useState(false);
  const filterToggle = () => setfilterModal((v) => !v);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("invoiceNumber");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [showexport, setShowExport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [recordsCsvData, setRecordsCsvData] = useState([]);
  const [dataParams, setDataParams] = useState({
    name: "",
    selectedDate: "",
    selectedDate1: "",
    count: 10,
    limit: 10,
    offset: 0,
    status: "",
  });
  const [requestData, setRequestData] = useState([]);
  const [totalrecords, setTotalRecords] = useState(0);
  const [notRoundedAvg, setNotRoundedAvg] = useState(0);
  const [roundedAvg, setRoundedAvg] = useState(0);
  const [showAverage, setShowAvg] = useState(false);
  const [roundedData, setRoundedData] = useState([]);
  const [shortData, setShortData] = useState([]);
  console.log(roundedData, "hhh");
  // const [paramsData, setParamsData] = useState({
  //   selectedDate: "",
  //   selectedDate1: "",
  //   limit: "10",
  //   offset: "0",
  //   supplierName: "",
  //   count: "10",
  //   status: "",
  // });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openExport = Boolean(anchorEl);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const getAllRoundedAmount = async (count,name,selectedDate,selectedDate1,offset,limit,invoiceTotalCount,status) => {
    // console.log("//paramsData",);
    // setLoading(true);
    setExportLoading(true);

    const rconfig = {
      method: "GET",
      url: ROUNDED_INVOICES_URL,
      headers: authHeader(),
      params: {
        // teamId: getTeamID(),
        // supplierEmail: name,
        // offset: currentpage,
        // count: rowperpage,
        // fromDate: selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : "",
        // toDate: selectedDate1 ? moment(selectedDate1).format("YYYY-MM-DD") : "",
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            supplierName: name,
            //offset: currentpage,
            limit: limit,
            offset: offset,
            count: count,
            fromDate: selectedDate
              ? moment(selectedDate).format("YYYY/MM/DD")
              : "",
            toDate: selectedDate1
              ? moment(selectedDate1).format("YYYY/MM/DD")
              : "",
            status: status,
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(rconfig);

      //  const { status, data, invoiceCount } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, invoiceCount } = JSON.parse(l);
      if (status == "Success") {
        // CSV Records
        setRecordsCsvData(data);
        setShortData(data[0]?.invoiceList);
        setShowExport(true);
        let notrounded = data.filter((el) => {
          return el.isRoundedAmount == 0;
        });

        let totalnotrounded = notrounded.length;
        let notroundedavg = ((totalnotrounded / invoiceCount) * 100).toFixed();

        setNotRoundedAvg(notroundedavg);
        let rounded = data.filter((el) => {
          return el.isRoundedAmount == 1;
        });
        let totalrounded = rounded.length;
        let roundedavg = ((totalrounded / invoiceCount) * 100).toFixed();
        setRoundedAvg(roundedavg);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
      setShowAvg(true);
      setExportLoading(false);
    }
  };

  const getRoundedAmount = async (paramsData) => {
    // const { name, selectedDate, selectedDate1, offset, limit, count, status } =
    //   paramValues;
    let paramStatus = paramsData.status;
    setLoading(true);
    const rconfig = {
      method: "GET",
      url: ROUNDED_INVOICES_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            supplierName: paramsData.name,
            offset: paramsData.offset,
            limit: paramsData.limit,
            count: paramsData.count,
            fromDate: paramsData.selectedDate
              ? moment(paramsData.selectedDate).format("YYYY-MM-DD")
              : "",
            toDate: paramsData.selectedDate1
              ? moment(paramsData.selectedDate1).format("YYYY-MM-DD")
              : "",
            status: "Approved",
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(rconfig);

      //  const { status, data, invoiceCount } = response.data;
      let l = deCryptFun(response.data);
      const { status, data } = JSON.parse(l);
      console.log(data[0].invoiceList, "FilterCheck");
      console.log(data[0]?.invoiceTotalCount, "DATACHECK");
      if (status == "Success") {
        setRoundedData(data);
        // setShortData(data[0]?.invoiceList);
        console.log(data[0], "totalrecord");
        setTotalRecords(data[0]?.invoiceTotalCount);
        console.log(data[0]?.invoiceTotalCount, "Record");
        if (data.length) {
          getAllRoundedAmount(
            paramsData.count,
            paramsData.name,
            paramsData.selectedDate,
            paramsData.selectedDate1,
            paramsData.offset,
            paramsData.limit,
            data[0]?.invoiceTotalCount,
            paramStatus
          );
        } else {
          setRoundedAvg(0);
          setNotRoundedAvg(0);
          setRecordsCsvData([]);
          setExportLoading(false);
          setShowExport(true);
        }
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      // setLoading(false);
    }
  };

  let filterInitial = {
    count: 10,
    offset: 0,
    companyName: "",
    // requestedName: "",
    requestStatus: "",
    supplierName: "",
    // supplierCompanyName: "",
    limit: "",
  };
  const [supplierFilter, setsupplierFilter] = useState(filterInitial);

  const requestListing = async (filtervalues) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: SUPPLIER_LISTING,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() === "Supplier" ? 0 : getTeamID(),
            offset: filtervalues.offset,
            count: filtervalues.count,
            limit: filtervalues.limit,
            supplierName: filtervalues.supplierName,
            // supplierCompanyName: filtervalues.supplierCompanyName,

            ...filtervalues,
            supplierName:
              getRole() === "Supplier"
                ? getEmail()
                : filtervalues.supplier?.replace("'", "\\'"),
            supplierName: filtervalues.supplierName?.replace("'", "\\'"),
          })
        ),
        flutterString: "",
      },
    };

    try {
      const response = await API(config);
      //const { data, status, count } = response.data;
      let l = deCryptFun(response.data);
      const { status, data, count } = JSON.parse(l);
      console.log("newData??", data);
      setRequestData(
        data.map((item) => {
          return {
            ...item,
            supplierName: item.supplierName || "N/A",
            sentInvite: item.requestStatus,
          };
        })
      );
      // setTotalRecords(count);
    } catch (error) {
      // //Swal.fire("Error", error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //  Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    } finally {
      setLoading(false);
    }
  };

  const delaySearch1 = useRef(
    debounce((supplierFilter) => requestListing(supplierFilter), 500)
  ).current;

  useEffect(() => {
    delaySearch1(supplierFilter);
  }, [supplierFilter]);
  // useEffect(() => {
  //   getRoundedAmount();
  // }, []);

  const delaySearch = useRef(
    debounce((paramsData) => getRoundedAmount(paramsData))
  ).current;

  useEffect(() => {
    delaySearch(dataParams);
  }, [dataParams]);

  const headers = [
    { label: "Invoice Number", key: "invoiceNumber" },
    { label: "Supplier Name", key: "supplierName" },
    // { label: "Company", key: "companyName" },
    { label: "Amount", key: "invoiceAmount" },
    { label: "Currency", key: "invoiceCurrency" },
    { label: "Status", key: "isRoundedAmount" },
  ];

  const getDownloadFileName = () => {
    return "InvoiceRoundedAmountReport";
  };

  const rowHandle = (pageCount) => {
    setDataParams((v) => ({ ...v, limit: pageCount.target.value,count:pageCount.target.value, offset: 0 }));
  };
  const nextPage = () => {
    setDataParams((va) => ({ ...va, offset: va.offset + 1 }));
  };

  const previousPage = () => {
    setDataParams((va) => ({ ...va, offset: va.offset - 1 }));
  };
  const handleFilterSubmit = (values) => {
    const { name, selectedDate, selectedDate1 } = values;
    // setShortData((val) => ({
    //   ...val,
    //   selectedDate: selectedDate,
    //   selectedDate1: selectedDate1,
    //   name: name,
    //   offset: 0,
    //   limit: 10,
    //   count: 10,
    //   status: status,
    // }));
    setfilterModal(false);
    getRoundedAmount({
      selectedDate: selectedDate,
      selectedDate1: selectedDate1,
      name: name,
      offset: 0,
      limit: 10,
      count: 10,
    });
  };
  const onResetFilter = () => {
    setfilterModal(false);
  };
  const exportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportClose = () => {
    setAnchorEl(null);
  };
  console.log(shortData.length, "LENGTHDATA");
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Grid
            item
            xs={12}
            md={5}
            lg={5}
            sx={{ textAlign: "left", alignSelf: "start" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography color="#A0A3BD" style={{ fontSize: "18px" }}>
                Rounded Invoice Count Average:
              </Typography>
              <Badge
                style={{ maxWidth: "80px", width: "-webkit-fill-available" }}
                color={"primary"}
              >
                {roundedData[0]?.roundedpercentage}%
              </Badge>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography color="#A0A3BD" style={{ fontSize: "18px" }}>
                Not Rounded Invoice Count Average:
              </Typography>{" "}
              <Badge
                style={{ maxWidth: "80px", width: "-webkit-fill-available" }}
                color={"warning"}
              >
                {roundedData[0]?.notRoundedpercentage}%
              </Badge>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={7}
            sx={{ textAlign: "right", alignSelf: "end", mb: 2 }}
          >
            {/* <Tooltip
              title="Export as CSV"
              placement="top"
              arrow
              classes={{
                arrow: styleclasses.arrow,
                tooltip: styleclasses.tooltip,
              }}
            > */}
            <Button
              className={styleclasses.notifydownloadbtn}
              variant="contained"
              id="basic-button"
              aria-controls={openExport ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openExport ? "true" : undefined}
              onClick={exportClick}
              disabled={exportLoading || showexport === false || loading}
            >
              Export
            </Button>
            {/* </Tooltip> */}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openExport}
              onClose={exportClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <CSVLink
                key={recordsCsvData ? `csv_${recordsCsvData.length}` : ""}
                data={recordsCsvData}
                headers={headers}
                //ref={ref}
                filename={`${getDownloadFileName()}.csv`}
                target="_blank"
              >
                <MenuItem
                  data-flag="csv"
                  className={styleclasses.exportcsvmenu}
                >
                  Export as CSV
                </MenuItem>
              </CSVLink>
            </Menu>
            <Tooltip
              title="Filter by"
              placement="top"
              arrow
              classes={{
                arrow: styleclasses.arrow,
                tooltip: styleclasses.tooltip,
              }}
            >
              <IconButton onClick={filterToggle}>
                <FilterListRoundedIcon className={styleclasses.filtercolor} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Grid item xs={12} md={12} sx={{ display: "flex" }}>
            <Grid
              item
              xs={12}
              md={12}
              className={styleclasses.leftside}
              sx={{ padding: "0px 8px 0px 0px " }}
            >
              {/* <Invoiceamounttable /> */}
              <EnhancedTableToolbar numSelected={selected.length} />
              <TableContainer className={styleclasses.tableContainerbg}>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {loading && (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>
                          <Loader />
                        </TableCell>
                      </TableRow>
                    )}
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                    {!loading &&
                      roundedData &&
                      roundedData[0]?.invoiceList?.length === 0 && (
                        <TableRow>
                          <TableCell align="center" colSpan={6}>
                            <NoRecordFound />
                          </TableCell>
                        </TableRow>
                      )}

                    <>
                    {/* {roundedData && roundedData[0]?.invoiceList.map((item)=>{
                      console.log(item, "checkData");
                      return(
                        <>
                        {stableSort(item, getComparator(order, orderBy)).map(
                        (list, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleClick(event, list.supplierName)
                              }
                              tabIndex={-1}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {list.invoiceNumber}
                              </TableCell>
                              <TableCell align="center">
                                {list.supplierName}
                              </TableCell>
                              <TableCell align="center">
                                {list.companyName}
                              </TableCell>
                              <TableCell align="center">
                                {list.invoiceAmount ? list.invoiceAmount : "HI"}
                              </TableCell>
                              <TableCell align="center">
                                {list.invoiceCurrency}
                              </TableCell>
                              <TableCell align="center">
                                <Badge
                                  color={
                                    list.isRoundedAmount === 1
                                      ? "primary"
                                      : "warning"
                                  }
                                >
                                  {list.isRoundedAmount === 1
                                    ? "Rounded"
                                    : "Not Rounded"}
                                </Badge>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                        </>
                      )
                    })} */}
                      {!loading && shortData.length > 0 && stableSort(shortData, getComparator(order, orderBy)).map(
                        (list, index) => {
                          console.log(list,"listttt");
                          // const {
                          //   invoiceNumber,
                          //   senderEmail,
                          //   invoiceId,
                          //   supplierName,
                          //   invoiceAmount,
                          //   invoiceDate,
                          //   isRoundedAmount,
                          //   companyName,
                          //   invoiceCurrency,
                          // } = list;
                          // const isItemSelected = isSelected(invoiceId);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleClick(event, list.supplierName)
                              }
                              // aria-checked={isItemSelected}
                              tabIndex={-1}
                              // key={invoiceId}
                              // selected={isItemSelected}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {list.invoiceNumber}
                              </TableCell>
                              <TableCell align="center">
                                {list.supplierName}
                              </TableCell>
                              {/* <TableCell align="center">
                                {list.companyName}
                              </TableCell> */}
                              <TableCell align="center">
                                {list.invoiceAmount ? list.invoiceAmount : "-"}
                              </TableCell>
                              <TableCell align="center">
                                {list.invoiceCurrency}
                              </TableCell>
                              <TableCell align="center">
                                <Badge
                                  color={
                                    list.isRoundedAmount === 1
                                      ? "primary"
                                      : "warning"
                                  }
                                >
                                  {list.isRoundedAmount === 1
                                    ? "Rounded"
                                    : "Not Rounded"}
                                </Badge>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
              <PagenationPages
                dataparams={dataParams}
                rowHandle={rowHandle}
                totalRecord={totalrecords}
                previorsPage={previousPage}
                nextPage={nextPage}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>{" "}
      {/* <Advancemetrixfilter open={filterModal} handleClose={filterToggle} /> */}
      <MetricInvoiceRounded
        open={filterModal}
        handleClose={filterToggle}
        nameData={nameData}
        dataParams={dataParams}
        handleFilterSubmit={handleFilterSubmit}
        onResetFilter={onResetFilter}
        supplierData={requestData}
      />
    </>
  );
};
