import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormControl,
  InputBase,
  NativeSelect,
  Grid,
  TextField,
  Box,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { makeStyles } from "@mui/styles";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  getUserId,
  stableSort,
} from "../../comman/function";
import { useFormik } from "formik";
import { PASSWORD_REGEX, NUMBER_REGEX } from "../../comman/constants";
import { CREATE_TEAM_USER, UPDATE_USER } from "../../comman/url";
import API from "../../constants/API";
import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import Swal from "sweetalert2";
import { CustomMessage } from "../../comman/helperfunction";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: 500,
  },
  canceldialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #EC7C37 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#D25D15 !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
}));

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));
var teamInit = {
  firstName: "",
  userId: "",
  profileLogo: "",
  lastName: "",
  approvalAmountFrom: "",
  approvalAmountTo: "",
  password: "",
  countryCode: "",
  phoneNumber: "",
  createdBy: "",
  email: "",
  confirmpassword: "",
  address: "",
  userRole: "",
  exceptionRoleIdList: [],
};
let roleInitial = ["Admin", "Team Member"];
let touchedInitial = {
  firstName: false,
  userId: false,
  profileLogo: false,
  lastName: false,
  approvalAmountFrom: false,
  approvalAmountTo: false,
  password: false,
  countryCode: false,
  phoneNumber: false,
  createdBy: false,
  email: false,
  confirmpassword: false,
  address: false,
  userRole: false,
  exceptionRoleIdList: false,
};
export default function AddUpdateteam({
  open,
  addToggleClose,
  initialData,
  updateflag = false,
  exHandlerRole,
  teamDetails,
  successCallBack,
}) {
  const styleclasses = useStyles();
  const [passWordFlag, setpassWordFlag] = useState(false);
  const passwordToggle = () => setpassWordFlag((v) => !v);
  const [confirmpassWordFlag, setconfirmpassWordFlag] = useState(false);
  const [loading, setloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [updateUserRole, setUpdateUserRole] = useState("");

  const confirmpasswordToggle = () => setconfirmpassWordFlag((v) => !v);
  const { autoApproval = 1 } = teamDetails;
  const validationSchema = () =>
    Yup.object().shape(
      {
        firstName: Yup.string().required("Required").max("15", "Only 15 characters maximum is allowed in this field!"),
        lastName: Yup.string().required("Required").max("15", "Only 15 characters maximum is allowed in this field!"),
        userRole: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),

        // address: Yup.string().required("Address Required"),
        password: Yup.string().when([], {
          is: () => !updateflag,
          then: Yup.string()
            .required("Required")
            .matches(
              PASSWORD_REGEX,
              "Invalid password. New password must be 8 characters minimum and contain at least one upper/lowercase letter and one special character." ),
            // .min(
            //   "8",
            //   "Invalid Password! Password must be longer than 8 characters"
            // ),
          otherwise: Yup.string().notRequired(),
        }),
        confirmpassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .when([], {
            is: () => !updateflag,
            then: Yup.string().required("Required"),
            otherwise: Yup.string().notRequired(),
          }),
        ...(values.userRole != "Admin" && {
          approvalAmountTo: Yup.string().required("Required")
          // .test(
          //   "s",
          //   "Maximum range must be equal to or greater than " + autoApproval,
          //   () => {
          //     let newValue =
          //       "" + values.approvalAmountTo?.toString().split(",").join("");
          //     if (newValue <= autoApproval) {
          //       return false;
          //     } else {
          //       return true;
          //     }
          //   }
          // ),
        }),
        // ...(values.userRole == "Exception Handler" && {
        //   exceptionRoleIdList: Yup.array().min(1, "Required"),
        // }),
        // phoneNumber: Yup.string().required("Required"),
        // .required("Required")
        // .positive()
        // .integer()

        // .when("approvalAmountTo", (approvalAmountTo, value) => {
        //   if (value !== -1)
        //     return Yup.number()
        //       .required("Required")
        //       .min(autoApproval, "Minimum amount is " + autoApproval);
        // }),
        // is: (approvalAmountTo) => {
        // function (value) {
        //   console.error("apam", value);
        // return approvalAmountTo == -1;
        // },
        // then: Yup.number().required("Required"),
        // otherwise: Yup.number()
        //   .required("Required")
        //   .min(autoApproval, "Minimum amount is " + autoApproval),
        // }),
      },
      [["approvalAmountTo", "autoApproval"]]
    );
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    setTouched,
  } = useFormik({
    initialValues: {
      ...teamInit,
      ...initialData,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => addandUpdatemenber(values),
  });
  useEffect(() => {
    setValues((v) => ({ ...v, ...initialData }));
    setUpdateUserRole(initialData.userRole);
  }, [initialData]);
  const phoneNumberChange = (value) => {
    setValues({ ...values, phoneNumber: value });
  };

  const exceptionHandleonchange = (e) => {
    const { checked, id } = e.target;
    if (checked) {
      setValues((val) => ({
        ...val,
        exceptionRoleIdList: [...val.exceptionRoleIdList, parseInt(id)],
      }));
    } else {
      setValues((val) => ({
        ...val,
        exceptionRoleIdList: [
          ...val.exceptionRoleIdList.filter((v) => v != parseInt(id)),
        ],
      }));
    }
  };

  const addandUpdatemenber = async (values) => {
    const {
      firstName,
      userId,
      profileLogo,
      lastName,
      phoneNumber,
      approvalAmountFrom,
      approvalAmountTo,
      position,
      password,
      email,
      address,
      userRole,
      exceptionRoleIdList,
    } = values;
    let splitNumber = formatPhoneNumberIntl(phoneNumber || "");
    let countryCode = splitNumber?.split(" ")[0];
    let number = phoneNumber?.split(countryCode)[1];

    // if ( number && !isValidPhoneNumber(phoneNumber)) {
    //   CustomMessage(
    //     "Please enter a valid phone number",
    //     "error",
    //     enqueueSnackbar
    //   ); //call toster

    //   return null;
    // }
    if (number && isValidPhoneNumber(phoneNumber)) {
      const config = {
        method: updateflag ? "PUT" : "POST",
        url: updateflag ? UPDATE_USER : CREATE_TEAM_USER,
        data: {
          webString: enCryptFun(
            JSON.stringify({
              firstName: firstName.trim(),
              userId,
              profileLogo,
              lastName: lastName.trim(),
              approvalAmountFrom,
              approvalAmountTo:
                userRole == "Admin"
                  ? 0
                  : approvalAmountTo?.toString().split(",").join(""),
              position,
              password,
              countryCode,
              phoneNumber: number,
              createdBy: getUserId(),
              email,
              address: address,
              userRole: userRole,
              exceptionRoleIdList:
                userRole == "Admin" ? 0 : exceptionRoleIdList.sort().join(","),
            })
          ),
          flutterString: "",
        },

        headers: authHeader(),
      };
      try {
        const response = await API(config);
        // const { status } = response.data;
        let l = deCryptFun(response.data);
        const { status } = JSON.parse(l);
        if (status === "Success") {
          CustomMessage(
            updateflag
              ? `${userRole} created`
              : "Changes saved",
            "success",
            enqueueSnackbar
          ); //call toster

          // dispatch({
          //   type: UPDATE_APROVAL_AMOUNT,
          //   payload: approvalAmountTo,
          // });

          setValues({ ...teamInit });
          setTouched(touchedInitial);
          successCallBack();
          setloading(false);
        }
      } catch (error) {
        // let errorObj = Object.assign({}, error);
        // let { data } = errorObj.response;
        // Swal.fire("Error", data.message);
        if (error.response) {
          let { data } = error.response;
          let decryptErr = deCryptFun(data);
          let parsedErr = JSON.parse(decryptErr);
          // Swal.fire("Error", parsedErr.message);
          CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
        }
      } finally {
        setloading(false);
      }
    } else {
      const config = {
        method: updateflag ? "PUT" : "POST",
        url: updateflag ? UPDATE_USER : CREATE_TEAM_USER,
        data: {
          webString: enCryptFun(
            JSON.stringify({
              firstName: firstName.trim(),
              userId,
              profileLogo,
              lastName: lastName.trim(),
              approvalAmountFrom,
              approvalAmountTo:
                userRole == "Admin"
                  ? 0
                  : approvalAmountTo?.toString().split(",").join(""),
              position,
              password,
              countryCode: "",
              phoneNumber: "",
              createdBy: getUserId(),
              email,
              address: address,
              userRole: userRole,
              exceptionRoleIdList: exceptionRoleIdList.sort().join(","),
            })
          ),
          flutterString: "",
        },

        headers: authHeader(),
      };
      try {
        const response = await API(config);
        // const { status } = response.data;
        let l = deCryptFun(response.data);
        const { status } = JSON.parse(l);
        if (status === "Success") {
          CustomMessage(
            updateflag
              ? "Changes saved"
              : `${userRole} created`,
            "success",
            enqueueSnackbar
          ); //call toster

          // dispatch({
          //   type: UPDATE_APROVAL_AMOUNT,
          //   payload: approvalAmountTo,
          // });

          setValues({ ...teamInit });
          setTouched(touchedInitial);
          successCallBack();
          setloading(false);
        }
      } catch (error) {
        // let errorObj = Object.assign({}, error);
        // let { data } = errorObj.response;
        // Swal.fire("Error", data.message);
        if (error.response) {
          let { data } = error.response;
          let decryptErr = deCryptFun(data);
          let parsedErr = JSON.parse(decryptErr);
          // Swal.fire("Error", parsedErr.message);
          CustomMessage(parsedErr.message, "error", enqueueSnackbar); //call toster
        }
      } finally {
        setloading(false);
      }
    }
  };
  const autoApprovalHandle = (e) => {
    const { value } = e.target;
    if (NUMBER_REGEX.test(value) || !value) {
      handleChange(e);
    }
  };
  const closeModal = () => {
    setValues({ ...teamInit });
    setTouched(touchedInitial);
    addToggleClose();
  };
  const handleApprovalHandle = (e) => {
    const approvalRegex = /^[0-9]+$/;
    if (approvalRegex.test(e.target.value) || !e.target.value) {
      handleChange(e);
    }
  };
  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Box component={"form"} onSubmit={handleSubmit}>
          <DialogContent maxWidth="xl" sx={{ padding: "8px 24px !important" }}>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={addToggleClose}
              className={styleclasses.BootstrapDialog}
              sx={{ fontWeight: 600, color: "#14142A", fontSize: "25px" }}
            >
              {updateflag ? `Update ${values.firstName}` : "Add team member"}
            </BootstrapDialogTitle>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
                First Name
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type={"text"}
                  value={values.firstName}
                  error={
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : ""
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="firstName"
                  placeholder="Enter First Name"
                />
                <FormHelperText error={true} data-testid="firstNameRequired">
                  {errors.firstName && touched.firstName ? errors.firstName : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography gutterBottom sx={{ fontWeight: 600, color: "#4E4B66" }}>
                Last Name
              </Typography>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName && touched.lastName ? true : false}
                  placeholder="Enter Last Name"
                  name="lastName"
                />
                <FormHelperText error={true} data-testid="lastNameRequired">
                  {errors.lastName && touched.lastName ? errors.lastName : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Email
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                value={values.email}
                disabled={updateflag}
                placeholder="Enter Email"
                error={touched.email && errors.email}
                onBlur={handleBlur}
                // helperText={touched.email && errors.email}
                name="email"
                onChange={handleChange}
              />
              <FormHelperText error={true}>
                {touched.email && errors.email}
              </FormHelperText>
            </Grid>{" "}
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Phone Number
              </Typography>
              <PhoneInput
                name="phoneNumber"
                placeholder="Enter Phone Number"
                value={values.phoneNumber}
                onBlur={handleBlur}
                defaultCountry="US"
                onChange={phoneNumberChange}
                international="false"
                error={
                  values.phoneNumber
                    ? isValidPhoneNumber(values.phoneNumber)
                      ? "correct"
                      : "Invalid phone number"
                    : ""
                }
              />
              <FormHelperText error={true}>
                {touched.phoneNumber && errors.phoneNumber
                  ? errors.phoneNumber
                  : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                User Role
              </Typography>
              <Select
                fullWidth
                value={values.userRole}
                onChange={handleChange}
                name="userRole"
                placeholder="Select Role"
                displayEmpty
                onBlur={handleBlur}
                inputProps={{ "aria-label": "Without label" }}
                disabled={updateflag && updateUserRole == "Admin"}
              >
                <MenuItem value="">Select Role</MenuItem>
                {roleInitial.map((val, index) => {
                  return (
                    <MenuItem key={index} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText id="component-error-text" error={true}>
                {touched.userRole && errors.userRole}
              </FormHelperText>
            </Grid>
            {values.userRole && (
              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Select Role
                </Typography>

                <FormGroup>
                  {exHandlerRole.map((val, index) => {
                    return (
                      <FormControlLabel
                        id={val.exceptionRoleId}
                        name="exceptionRoleIdList"
                        onBlur={handleBlur}
                        onChange={exceptionHandleonchange}
                        control={
                          <Checkbox
                            id={val.exceptionRoleId}
                            checked={values.exceptionRoleIdList.includes(
                              val.exceptionRoleId
                            )}
                          />
                        }
                        label={val.exceptionRole}
                      />
                    );
                  })}
                </FormGroup>
                <FormHelperText error>
                  {errors.exceptionRoleIdList}
                </FormHelperText>
              </Grid>
            )}
            {values.userRole != "Admin" && (
              <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 600, color: "#4E4B66" }}
                >
                  Approval Threshold
                </Typography>
                <OutlinedInput
                  fullWidth
                  type="text"
                  error={touched.approvalAmountTo && errors.approvalAmountTo}
                  // helperText={touched.approvalAmountTo && errors.approvalAmountTo}
                  id="custom-css-outlined-input"
                  value={values.approvalAmountTo}
                  onBlur={handleBlur}
                  placeholder="Maximum Range ($)"
                  name="approvalAmountTo"
                  // onChange={autoApprovalHandle}
                  onChange={(e) => handleApprovalHandle(e)}
                />
                <FormHelperText error={true}>
                  {touched.approvalAmountTo && errors.approvalAmountTo}
                </FormHelperText>
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Position
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                value={values.position}
                onBlur={handleBlur}
                // helperText={touched.position && errors.position}
                error={touched.position && errors.position}
                placeholder="Enter Position"
                name="position"
                onChange={handleChange}
              />
              <FormHelperText error={true}>
                {touched.position && errors.position}
              </FormHelperText>
            </Grid>{" "}
            {/* <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
              <Typography
                gutterBottom
                sx={{ fontWeight: 600, color: "#4E4B66" }}
              >
                Address
              </Typography>
              <CssTextField
                fullWidth
                id="custom-css-outlined-input"
                error={touched.address && errors.address}
                // helperText={touched.address && errors.address}
                value={values.address}
                onBlur={handleBlur}
                placeholder="Enter Address"
                name="address"
                onChange={handleChange}
              />
              <FormHelperText id="component-error-text" error={true}>
                {touched.address && errors.address}
              </FormHelperText>
            </Grid> */}
            {!updateflag && (
              <>
                {" "}
                <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                  <Typography
                    gutterBottom
                    sx={{ fontWeight: 600, color: "#4E4B66" }}
                  >
                    Password
                  </Typography>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    type={passWordFlag ? "text" : "password"}
                    error={errors.password}
                    onBlur={handleBlur}
                    placeholder="Enter Password"
                    // helperText={errors.password}
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={passwordToggle}
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          {!passWordFlag ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  <FormHelperText id="component-error-text" error={true}>
                    {touched.password && errors.password}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ pb: 1 }}>
                  <Typography
                    gutterBottom
                    sx={{ fontWeight: 600, color: "#4E4B66" }}
                  >
                    Confirm Password
                  </Typography>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    type={confirmpassWordFlag ? "text" : "password"}
                    value={values.confirmpassword}
                    error={touched.confirmpassword && errors.confirmpassword}
                    name="confirmpassword"
                    onBlur={handleBlur}
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={confirmpasswordToggle}
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          {confirmpassWordFlag ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                  <FormHelperText id="component-error-text" error={true}>
                    {touched.confirmpassword && errors.confirmpassword}
                  </FormHelperText>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <DialogActions>
              <Button
                onClick={closeModal}
                className={styleclasses.canceldialog}
              >
                Cancel
              </Button>
              <Button
                type="submit "
                onSubmit={handleSubmit}
                className={styleclasses.submitdialog}
              >
                {/* {updateflag ? `Save` : "Add"} */}
                Save
              </Button>
            </DialogActions>
          </DialogActions>
        </Box>
      </BootstrapDialog>
    </>
  );
}
